import React from "react";
import {
  Input,
  Button,
  Modal,
  Form,
} from "antd";
import {  CopyOutlined } from "@ant-design/icons";

const ModalDetailToken = (props) => {
  const { openDetailTokenModal, setOpenDetailTokenModal, formToken, handleCopy } = props;
  return (
    <Modal
      title="Thông tin cookie"
      open={openDetailTokenModal}
      onCancel={() => {
        setOpenDetailTokenModal(false);
        formToken.resetFields();
      }}
      centered={true}
      footer={null}
      fullscreen={false}
    >
      <Form form={formToken} name="formToken" layout="vertical">
        <Form.Item name="name" label="Name">
          <Input readOnly value={formToken.getFieldValue("name")} />
          <Button icon={<CopyOutlined />} onClick={() => handleCopy("name")}>
            Copy
          </Button>
        </Form.Item>

        <Form.Item name="cookie" label="Cookie">
          <Input readOnly value={formToken.getFieldValue("cookie")} />
          <Button icon={<CopyOutlined />} onClick={() => handleCopy("cookie")}>
            Copy
          </Button>
        </Form.Item>

        <Form.Item name="token" label="Token">
          <Input readOnly value={formToken.getFieldValue("token")} />
          <Button icon={<CopyOutlined />} onClick={() => handleCopy("token")}>
            Copy
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalDetailToken;
