import { useState, useEffect } from "react";
import {
  Row,
  Col,
  Pagination,
  Button,
  Popconfirm,
  Table,
  notification,
  Avatar,
  Space,
  message,
  Tooltip,
} from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import ModalManageProxy from "./component/zalo/ModalManageProxy";
import ModalAddZalo from "./component/zalo/ModalAddZalo";
import ModalOpenQR from './component/zalo/ModalOpenQR'
import ModalEdit from "./component/zalo/ModalEdit";
import $axios from "../../services/axios";

const AccountZalo = () => {
  const [loadingZalo, setLoadingZalo] = useState(false);
  const [listCookieZalo, setListCookieZalo] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [pager, setPager] = useState(1);
  const [countTotal, setCountTotal] = useState(0);
  const [openProxyModal, setOpenProxyModal] = useState(false);
  const [openAddZaloModal, setOpenAddZaloModal] = useState(false);
  const [openEditProxyNameModal, setOpenEditProxyNameModal] = useState(false);
  const [editProxyName, setEditProxyName] = useState('');
  const [editProxyId, setEditProxyId] = useState("");
  const [openQRModal, setOpenQRModal] = useState(false);
  const [proxySelection, setProxySelection] = useState("");
  const [proxyValue, setProxyValue] = useState("");
  const [proxyNameValue, setProxyNameValue] = useState("");
  const [loadingAddProxy, setLoadingAddProxy] = useState(false);
  const [loadingAddZalo, setLoadingAddZalo] = useState(false);
  const [loadingTableProxy, setLoadingTableProxy] = useState(false);
  const [listProxy, setListProxy] = useState([]);
  const [totalProxy, setTotalProxy] = useState(0);
  const [pageSizeProxy, setPageSizeProxy] = useState(20);
  const [pagerProxy, setPagerProxy] = useState(1);
  const [valueEdit, setValueEdit] = useState(null);
  const [typeEdit, setTypeEdit] = useState("");
  const [idEdit, setIdEdit] = useState("");
  const [titleModalEdit, setTitleModalEdit] = useState("");
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [pageSize, setPageSize] = useState(20);


  const onShowSizeProxyChange = (current, pageSize) => {
    setPageSizeProxy(pageSize);
  };

  const [imgQR, setImgQr] = useState("");
  const columns = [
    {
      title: "Avatar",
      key: "avatar",
      align: "center",
      dataIndex: "avatar",
      render: (text, record) => <Avatar src={text} />,
    },
    {
      title: "Name",
      key: "name",
      align: "center",
      dataIndex: "name",
    },
    {
      title: "Delay Invite Send",
      key: "delayInviteSend",
      align: "center",
      dataIndex: "delayInviteSend",
      render: (text, record) => {
        return (
          <Button
            onClick={(e) => {
              setTypeEdit("changeDelayInvite");
              setIdEdit(record._id);
              setTitleModalEdit("Change Delay Invite");
              setValueEdit(record.delayInviteSend || 0);
              setTimeout(() => {
                setOpenModalEdit(true);
              }, 500);
            }}
            className="text-12 font-300"
            type="primary"
          >
            {record.delayInviteSend || 0}
          </Button>
        );
      },
    },
    {
      title: "Delay Message Send",
      key: "delayMessageSend",
      align: "center",
      dataIndex: "delayMessageSend",
      render: (text, record) => {
        return (
          <Button
            onClick={(e) => {
              setTypeEdit("changeDelayMessage");
              setIdEdit(record._id);
              setTitleModalEdit("Change Delay Message");
              setValueEdit(record.delayMessageSend || 0);
              setTimeout(() => {
                setOpenModalEdit(true);
              }, 500);
            }}
            className="text-12 font-300"
            type="primary"
          >
            {record.delayMessageSend || 0}
          </Button>
        );
      },
    },
    {
      title: "Limit Invite Send",
      key: "limitInviteSend",
      align: "center",
      dataIndex: "limitInviteSend",
      render: (text, record) => {
        return (
          <Button
            onClick={(e) => {
              setTypeEdit("changeLimitInvite");
              setIdEdit(record._id);
              setTitleModalEdit("Change Limit Invite");
              setValueEdit(record.limitInviteSend || 0);
              setTimeout(() => {
                setOpenModalEdit(true);
              }, 500);
            }}
            className="text-12 font-300"
            type="primary"
          >
            {record.limitInviteSend || 0}
          </Button>
        );
      },
    },
    {
      title: "Limit Invite Send Per Day",
      key: "limitInviteSendPerDay",
      align: "center",
      dataIndex: "limitInviteSendPerDay",
      render: (text, record) => {
        return (
          <Button
            onClick={(e) => {
              setTypeEdit("changeLimitInvitePerDay");
              setIdEdit(record._id);
              setTitleModalEdit("Change LimitInvite Per Day");
              setValueEdit(record.limitInviteSendPerDay || 0);
              setTimeout(() => {
                setOpenModalEdit(true);
              }, 500);
            }}
            className="text-12 font-300"
            type="primary"
          >
            {record.limitInviteSendPerDay || 0}
          </Button>
        );
      },
    },
    {
      title: "Limit Message Send",
      key: "limitMessageSend",
      align: "center",
      dataIndex: "limitMessageSend",
      render: (text, record) => {
        return (
          <Button
            onClick={(e) => {
              setTypeEdit("changeLimitMessage");
              setIdEdit(record._id);
              setTitleModalEdit("Change Limit Message");
              setValueEdit(record.limitMessageSend || 0);
              setTimeout(() => {
                setOpenModalEdit(true);
              }, 500);
            }}
            className="text-12 font-300"
            type="primary"
          >
            {record.limitMessageSend || 0}
          </Button>
        );
      },
    },
    {
      title: "Limit Message Send Per Day",
      key: "limitMessageSendPerDay",
      align: "center",
      dataIndex: "limitMessageSendPerDay",
      render: (text, record) => {
        return (
          <Button
            onClick={(e) => {
              setTypeEdit("changeLimitMessagePerDay");
              setIdEdit(record._id);
              setTitleModalEdit("Change Limit Message Per Day");
              setValueEdit(record.limitMessageSendPerDay || 0);
              setTimeout(() => {
                setOpenModalEdit(true);
              }, 500);
            }}
            className="text-12 font-300"
            type="primary"
          >
            {record.limitMessageSendPerDay || 0}
          </Button>
        );
      },
    },
    {
      title: "Proxy",
      dataIndex: "proxy",
      align: "center",
      render: (text, record) => {
        return (
          <Tooltip
            effect="dark"
            title={`${record.proxy?.user}:${record.proxy?.password}@${record.proxy?.host}:${record.proxy?.port}`}
          >
            <Button
              onClick={(e) => {
                setTypeEdit("changeProxy");
                setIdEdit(record._id);
                setTitleModalEdit("Change Proxy");
                setValueEdit(record.proxy._id || 0);
                setTimeout(() => {
                  setOpenModalEdit(true);
                }, 500);
              }}
              className="text-12 font-300"
              type="primary"
            >
              {record.proxy?.name}
            </Button>
          </Tooltip>
        );
      },
    },
  ];

  useEffect(() => {
    async function fetchData() {
      await getCookieListZALO();
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      await getCookieListZALO();
    }
    fetchData();
  }, [pager, pageSize]);

  const onShowSizeChange = (current, pageSize) => {
    setPageSize(pageSize);
  };

  useEffect(() => {
    handleLoadListProxy();
  }, [pagerProxy, pageSizeProxy]);

  const success = (string) => {
    notification.success({
      message: "Thành công",
      description: string,
    });
  };

  const handleUpdateZalo = async () => {
    switch (typeEdit) {
      case "changeDelayInvite":
        const res1 = await $axios.$patch(
          `/zalo/change-delay-invite/${idEdit}`,
          {
            delay: valueEdit,
          }
        );
        if (res1.statusCode === 200) {
          success("Cập nhật thành công");
        }
        break;

      case "changeDelayMessage":
        const res2 = await $axios.$patch(
          `/zalo/change-delay-message/${idEdit}`,
          {
            delay: valueEdit,
          }
        );
        if (res2.statusCode === 200) {
          success("Cập nhật thành công");
        }
        break;

      case "changeLimitInvite":
        const res3 = await $axios.$patch(
          `/zalo/change-limit-invite/${idEdit}`,
          {
            limit: valueEdit,
          }
        );
        if (res3.statusCode === 200) {
          success("Cập nhật thành công");
        }
        break;

      case "changeLimitMessage":
        const res4 = await $axios.$patch(
          `/zalo/change-limit-message/${idEdit}`,
          {
            limit: valueEdit,
          }
        );
        if (res4.statusCode === 200) {
          success("Cập nhật thành công");
        }
        break;

      case "changeLimitInvitePerDay":
        const res5 = await $axios.$patch(
          `/zalo/change-limit-invite-per-day/${idEdit}`,
          {
            limit: valueEdit,
          }
        );
        if (res5.statusCode === 200) {
          success("Cập nhật thành công");
        }
        break;

      case "changeLimitMessagePerDay":
        const res6 = await $axios.$patch(
          `/zalo/change-limit-message-per-day/${idEdit}`,
          {
            limit: valueEdit,
          }
        );
        if (res6.statusCode === 200) {
          success("Cập nhật thành công");
        }
        break;

      case "changeProxy":
        const res7 = await $axios.$patch(`/zalo/change-proxy/${idEdit}`, {
          proxy: valueEdit,
        });
        if (res7.statusCode === 200) {
          success("Cập nhật thành công");
        }
        break;
      default:
        break;
    }
    setTypeEdit("");
    setValueEdit(null);
    setOpenModalEdit(false);
    await getCookieListZALO();
  };

  const handleRemoveProxy = async (_id) => {
    try {
      await $axios.delete(`/proxy/delete/${_id}`);
      notification.success({
        message: "Xoá thành công",
      });
      setPager(1);
      await handleLoadListProxy();
    } catch (error) {
      notification.error({
        message: "Thất bại",
        description: "Có lỗi xảy ra",
      });
    }
  };

  const handleLoadListProxy = async () => {
    setLoadingTableProxy(true);
    try {
      const res = await $axios.get(
        `/proxy/list?page=${pagerProxy}&perPage=${pageSizeProxy}`
      );
      setListProxy(res.data.data);
      setTotalProxy(res.data._metadata.pagination.total);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTableProxy(false);
    }
  };
  const handleAddProxy = async () => {
    setLoadingAddProxy(true);
      try {
         const res = await $axios.post("/proxy/create", {
           proxy: proxyValue,
           name: proxyNameValue
         });
       } catch (error) {
         notification.error({
           message: "Thất bại",
           description: "Có lỗi xảy ra, vui lòng nhập proxy hợp lệ",
         });
       } finally {
         setProxyValue("");
         setProxyNameValue("");
         handleLoadListProxy();
         setLoadingAddProxy(false);
       }
  };

  const getCookieListZALO = async (page) => {
    setLoadingZalo(true);
    if (page) setPager(page);
    const _page = page ? page : pager;
    try {
      const res = await $axios.$get(
        `/zalo/list?page=${_page}&perPage=${pageSize}`
      );
      setCountTotal(res._metadata.pagination.total);
      setListCookieZalo(res.data.map((e) => ({ ...e, loading_check: true })));
    } catch (error) {
      // Handle error
    }
    setLoadingZalo(false);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const controller = new AbortController();
  const signal = controller.signal;

  const getQR = async (proxySelection) => {
    return await $axios.$get(`/zalo/get-qr?proxy=${proxySelection}`);
  };

  const waitingScan = async (code) => {
    const res = await $axios.$post(`/zalo/waiting-scan`, {
      code: code,
    });

    if (res.statusCode === 201) {
      if (res.data.status === 4) {
        setImgQr(res.data.image);
        return await waitingScan(res.data.code);
      } else {
        return res.data.code;
      }
    } else {
      message.success("Thêm tài khoản thất bại!");
      setOpenQRModal(false);
      setLoadingAddZalo(false);
      setImgQr("");
    }
  };

  const waitingConfirm = async (code) => {
    const res = await $axios.$post(`/zalo/waiting-confirm`, {
      code: code,
    });

    if (res.statusCode === 201) {
      message.success("Thêm tài khoản thành công!");
      setOpenQRModal(false);
      setImgQr("");
      setPager(1);
      setSelectedRowKeys([]);
      await getCookieListZALO(1);
    } else {
      message.success("Thêm tài khoản thất bại!");
      setOpenQRModal(false);
      setLoadingAddZalo(false);
      setImgQr("");
    }
  };

  const handleLoginZalo = async () => {
    if (!proxySelection) {
      message.error("Vui lòng chọn proxy!");
      return;
    }
    setLoadingAddZalo(true);
    try {
      const res = await getQR(proxySelection);
      if (res.statusCode === 200) {
        const code = res.data.code;
        setOpenAddZaloModal(false);
        setLoadingAddZalo(false);
        setImgQr(res.data.image);
        setProxySelection("");
        setOpenQRModal(true);

        const res_scan = await waitingScan(code);
        await waitingConfirm(res_scan);
      }
    } catch (error) {
      message.error("Xảy ra lỗi. Vui lòng thử lại!");
      setLoadingAddZalo(false);
    } finally {
    }
  };

  const removeZalo = async () => {
    for (let id of selectedRowKeys) {
      try {
        const reponse = await $axios.$delete(`/zalo/delete/${id}`);
        if (reponse.code === 200) {
          notification.success({
            description: "Xóa thành công",
          });
        }
      } catch (error) {
        // Handle error
      }
      setPager(1);
      setSelectedRowKeys([]);
      await getCookieListZALO(1);
    }
  };

  const handleEditProxyName = async() => {
     try {
       const reponse = await $axios.$patch(`/proxy/update-name/${editProxyId}`, {
         name: editProxyName
       });
       if (reponse.code === 200) {
         notification.success({
           description: "Sửa thành công",
         });
       }
     } catch (error) {
         notification.error({
           description: "Có lỗi xảy ra",
         });
     }
     finally {
      setOpenEditProxyNameModal(false);
      setEditProxyId("");
      setEditProxyName("");
      handleLoadListProxy();
     }
  }

  return (
    <div>
      <Space>
        <Button type="primary" onClick={() => setOpenProxyModal(true)} className="mb-4">
          Quản lý Proxy
        </Button>

        <Button type="primary" onClick={() => setOpenAddZaloModal(true)} className="mb-4">
          Thêm tài khoản
        </Button>
      </Space>

      <ModalManageProxy
        openProxyModal={openProxyModal}
        setOpenProxyModal={setOpenProxyModal}
        setProxyValue={setProxyValue}
        proxyNameValue={proxyNameValue}
        setProxyNameValue={setProxyNameValue}
        proxyValue={proxyValue}
        handleAddProxy={handleAddProxy}
        loadingAddProxy={loadingAddProxy}
        listProxy={listProxy}
        loadingTableProxy={loadingTableProxy}
        setEditProxyId={setEditProxyId}
        setEditProxyName={setEditProxyName}
        pagerProxy={pagerProxy}
        totalProxy={totalProxy}
        setPagerProxy={setPagerProxy}
        pageSizeProxy={pageSizeProxy}
        onShowSizeProxyChange={onShowSizeProxyChange}
        editProxyName={editProxyName}
        setOpenEditProxyNameModal={setOpenEditProxyNameModal}
        openEditProxyNameModal={openEditProxyNameModal}
        handleEditProxyName={handleEditProxyName}
        handleRemoveProxy={handleRemoveProxy}
      />

      <ModalAddZalo
        openAddZaloModal={openAddZaloModal}
        setOpenAddZaloModal={setOpenAddZaloModal}
        setProxySelection={setProxySelection}
        setImgQr={setImgQr}
        setLoadingAddZalo={setLoadingAddZalo}
        listProxy={listProxy}
        proxySelection={proxySelection}
        handleLoginZalo={handleLoginZalo}
        loadingAddZalo={loadingAddZalo}
      />

      <ModalOpenQR openQRModal={openQRModal} setOpenQRModal={setOpenQRModal} imgQR={imgQR} setImgQr={setImgQr} />
      <br />
      <Row gutter={20}>
        <Col align="left" span={24} offset={0}>
          <Space>
            {selectedRowKeys.length > 0 && (
              <Popconfirm
                title="Bạn có chắc chắn?"
                onConfirm={removeZalo}
                okText="OK"
                cancelText="Không, cảm ơn."
                icon={<DeleteOutlined />}
                disabled={selectedRowKeys.length === 0}
              >
                <Button type="primary" icon={<DeleteOutlined />} disabled={selectedRowKeys.length === 0}>
                  Xóa {selectedRowKeys.length} zalo
                </Button>
              </Popconfirm>
            )}
          </Space>
        </Col>
      </Row>
      <br />
      <Table
        loading={loadingZalo}
        dataSource={listCookieZalo.map((item) => ({ key: item._id, ...item }))}
        rowSelection={rowSelection}
        pagination={false}
        columns={columns}
        scroll={{
          x: "max-content",
          y: `calc(100vh - 510px)`,
        }}
      ></Table>
      <Pagination
        className="mt-3"
        current={pager}
        total={countTotal}
        onChange={(page) => getCookieListZALO(page)}
        showSizeChanger={true}
        onShowSizeChange={onShowSizeChange}
        pageSize={pageSize}
        pageSizeOptions={[20, 50, 100]}
      />
      <ModalEdit
        titleModalEdit={titleModalEdit}
        openModalEdit={openModalEdit}
        setOpenModalEdit={setOpenModalEdit}
        handleUpdateZalo={handleUpdateZalo}
        typeEdit={typeEdit}
        listProxy={listProxy}
        setValueEdit={setValueEdit}
        valueEdit={valueEdit}
      />
    </div>
  );
};

export default AccountZalo;
