import { Layout, Menu } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import chuyendoi from "../../assets/icon/icon_Chuyen_doi_UID_SDT.svg";
import larksuite from "../../assets/icon/icon_Larksuite.svg";
import nhom from "../../assets/icon/icon_Nhom.svg";
import quetcomment from "../../assets/icon/icon_Quet_Comment.svg";
import taikhoan from "../../assets/icon/icon_Tai_Khoan.svg";
import quanly from "../../assets/icon/icon_quan_ly.svg";
import thongkechung from "../../assets/icon/icon_thong_ke_chung.svg";
import Header from "./../Header/Header";
import $axios from "../../services/axios";
import "./layout.scss";
const { Content, Sider } = Layout;

const LayoutClient = ({ children }) => {
  // const userInfo = JSON.parse(localStorage.getItem("user_info"));
  const [userInfo, setUserInfo] = useState(false);
  const location = useLocation();
  
  let menuItem = [
    { path: "/dashboard/thong-ke", key: "1", parent: "" },
    { path: "/dashboard/bai-viet", key: "2-1", parent: "2" },
    { path: "/dashboard/bai-viet/binh-luan", key: "2-2", parent: "2" },
    { path: "/dashboard/auto-zalo", key: "auto-zalo", parent: "" },
    { path: "/dashboard/uid-phone", key: "5", parent: "" },
    { path: "/dashboard/larksuite", key: "6", parent: "" },
    // { path: '/dashboard/zalo/nhan-tin', key: '7-1', parent: '7' },
    // { path: '/dashboard/zalo/ket-ban', key: '7-2', parent: '7' },
    // { path: '/dashboard/zalo/nhom', key: '7-3', parent: '7' },
    { path: "/dashboard/tai-khoan", key: "8", parent: "" },
    { path: "/dashboard/admin/tai-khoan", key: "9-1", parent: "9" },
    { path: "/dashboard/admin/quan-ly-tai-khoan", key: "9-2", parent: "9" },
  ];
  if (!userInfo.autoZalo) {
       menuItem = menuItem.filter((v) => v.key !== "auto-zalo");
  }
  let menuActive = menuItem.find((menu) => menu.path == location.pathname);
  if (!menuActive) {
    menuActive = {
      key: "1",
      parent: "",
    };
  }

  let menu = [
    {
      key: "1",
      icon: <img src={thongkechung} style={{ width: 16 }} alt="" />,
      label: (
        <Link className="font-500" to={"/dashboard/thong-ke"}>
          Thống Kê Chung
        </Link>
      ),
    },
    {
      key: "2",
      icon: <img src={quetcomment} style={{ width: 16 }} alt="" />,
      label: <a>Quét Comment</a>,
      children: [
        {
          key: "2-1",
          label: <Link to={"/dashboard/bai-viet"}>Danh Sách</Link>,
        },
        {
          key: "2-2",
          label: <Link to={"/dashboard/bai-viet/binh-luan"}>Bình Luận</Link>,
        },
      ],
    },
    // {
    //   key: "4",
    //   icon: <img src={nhom} style={{width: 16}} alt="" />,
    //   label: <a>Nhóm</a>,
    //   children: [
    //     {
    //       key: "4-1",
    //       label: <Link to={"/dashboard/nhom/tim-nhom"}>Tìm nhóm</Link>,
    //     },
    //     {
    //       key: "4-2",
    //       label: <Link to={"/dashboard/nhom/thanh-vien"}>Thành viên</Link>,
    //     },
    //   ],
    // },
    userInfo.autoZalo
      ? {
          key: "auto-zalo",
          icon: <img src={nhom} style={{ width: 16 }} alt="" />,
          label: (
            <Link className="font-500" to={"/dashboard/auto-zalo"}>
              Auto Zalo
            </Link>
          ),
        }
      : null,
    {
      key: "5",
      icon: <img src={chuyendoi} style={{ width: 16 }} alt="" />,
      label: (
        <Link className="font-500" to={"/dashboard/uid-phone"}>
          UID - Phone
        </Link>
      ),
    },
    {
      key: "6",
      icon: <img src={larksuite} style={{ width: 16 }} alt="" />,
      label: (
        <Link className="font-500" to={"/dashboard/larksuite"}>
          Liên Kết Larksuite
        </Link>
      ),
    },
    // {
    //   key: "7",
    //   icon: <img src={zalo} style={{ width: 16 }} alt="" />,
    //   label: <a>Zalo</a>,
    //   children: [
    //     {
    //       key: "7-1",
    //       label: <Link to={"/dashboard/zalo/nhan-tin"}>Nhắn tin</Link>,
    //     },
    //     {
    //       key: "7-2",
    //       label: <Link to={"/dashboard/zalo/ket-ban"}>Kết bạn</Link>,
    //     },
    //     {
    //       key: "7-3",
    //       label: <Link to={"/dashboard/zalo/nhom"}>Nhóm</Link>,
    //     },
    //   ],
    // },
    {
      key: "8",
      icon: <img src={taikhoan} style={{ width: 16 }} alt="" />,
      label: (
        <Link className="font-500" to={"/dashboard/tai-khoan"}>
          Tài Khoản
        </Link>
      ),
    },
    userInfo.role == "Admin"
      ? {
          key: "9",
          icon: <img src={quanly} style={{ width: 16 }} alt="" />,
          label: <a>Quản lý</a>,
          children: [
            {
              key: "9-1",
              label: <Link to={"/dashboard/admin/tai-khoan"}>Cookies Clone</Link>,
            },
            {
              key: "9-2",
              label: <Link to={"/dashboard/admin/quan-ly-tai-khoan"}>USERS</Link>,
            },
          ],
        }
      : null,
  ];

  // if (!userInfo.autoZalo) {
  //   menu = menu.filter((v) => v.key !== "auto-zalo");
  //   console.log(menu, "menu");
    
  // }


  // useEffect(() => {
  //   if (!userInfo.autoZalo) {
  //     menuItem = menuItem.filter((v) => v.path !== "/dashboard/auto-zalo");
  //   }
  // }, [userInfo]);


  useEffect(() => {
    getUserInfo();
  }, []);

  const getUserInfo = async () => {
    const userInfo = await $axios.get("user/profile");
    localStorage.setItem("user_info", JSON.stringify(userInfo.data.data));
    setUserInfo(userInfo.data.data);
  };

     return (
       <Layout style={{ backgroundColor: "#ffff" }}>
         <Header />
         <Layout style={{ backgroundColor: "#ffff", marginTop: "60px" }}>
           <Sider
             width={240}
             style={{
               background: "#ffff",
             }}
           >
             <Menu
               className="sidebar-menu px-2"
               mode="inline"
               defaultSelectedKeys={[menuActive.key]}
               defaultOpenKeys={[menuActive.parent]}
               style={{
                 height: "100%",
                 borderRight: 0,
               }}
               items={menu}
             />
           </Sider>
           <Layout
             style={{
               backgroundColor: "#ffff",
             }}
           >
             <Content
               style={{
                 padding: 24,
                 margin: 0,
                 minHeight: 280,
                 background: "#fffff",
               }}
             >
               {children}
             </Content>
           </Layout>
         </Layout>
       </Layout>
     );
};

export default LayoutClient;
