import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  FolderOpenOutlined,
  PlusOutlined,
  ReloadOutlined,
  SearchOutlined,
  StarFilled,
  RiseOutlined,
  FallOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Pagination,
  Popconfirm,
  Row,
  Select,
  Switch,
  Table,
  Tabs,
  Spin,
  Tag,
  Tooltip,
  notification,
  Modal,
} from "antd";
import { forEach } from "lodash";
import React, { useEffect, useState, useRef } from "react";
import $axios from "../../services/axios";
import CommentModal from "./CommentModal";
import useDebounce from "../../hooks/useDebounceValue";
import "./list.scss";

const { Option } = Select;
const { TabPane } = Tabs;

const List = () => {
  const [category, setCategory] = useState([]);

  const [post, setPost] = useState([]);
  const prevPostsRef = useRef(post);
  const [search, setSearch] = useState("");
  const [link, setLink] = useState("");
  const [title, setTitle] = useState("");
  const [cate, setCate] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pager, setPager] = useState(1);
  const [countTotal, setCountTotal] = useState(0);
  const [addLoading, setAddLoading] = useState(false);
  const [removeLoading, setRemoveLoading] = useState(false);
  const [chosenPost, setChosenPost] = useState();
  const [pageSize, setPageSize] = useState(20);
  const [statisticCount, setStatisticCount] = useState({});
  const debouncedSearchValue = useDebounce(search, 500);
  const [loadingStatistic, setLoadingStatistic] = useState({});
  const [openEditCate, setOpenEditCate] = useState(false);
  const [chosenId, setChosenId] = useState("");
  const [nameCategory, setNameCategory] = useState("");
  const debouncedName = useDebounce(nameCategory, 500);

  const editNameCategory = async () => {
    if (!chosenId || !debouncedName) return;
    try {
      let data = await $axios.patch(`category/update-name/${chosenId}`, {
        name: debouncedName,
      });

      notification.success({
        message: "Thành công",
        description: "Chỉnh sửa thành công",
      });
    } catch (error) {
      notification.error({
        message: "Thất bại",
        description: "Có lỗi xảy ra",
      });
    } finally {
      getCategory();
    }
  };

  useEffect(() => {
    editNameCategory();
  }, [debouncedName, chosenId]);

  const RemoveMessage = async (id) => {
    try {
      let data = await $axios.delete(`/category/delete/${id}`);

      notification.success({
        message: "Thành công",
        description: "Xoá thành công",
      });
    } catch (error) {
      notification.error({
        message: "Thất bại",
        description: "Có lỗi xảy ra",
      });
    } finally {
      getCategory();
    }
  };

  useEffect(() => {
    getCategory();
    getPosts();
  }, [debouncedSearchValue, pager, pageSize]);

  const getCategory = async () => {
    setTimeout(async () => {
      const data = await $axios.get("/category/list");
      setCategory(data.data.data);
    }, [200]);
  };

  const getPosts = async () => {
    setLoading(true);
    const posts = await $axios.get("/post/list", {
      params: {
        page: pager,
        perPage: pageSize,
        search: debouncedSearchValue,
        orderBy: "createdAt",
        orderDirection: "desc",
      },
    });
    setCountTotal(posts.data._metadata.pagination.total);
    setPost(posts.data.data);
    setLoading(false);
  };

  const ScanPost = async () => {
    try {
      setAddLoading(true);
      let data = await $axios.post("/post/create", {
        name: title,
        url: link,
        category: cate,
      });
      setLink("");
      setTitle("");
      notification.success({
        message: "Thành công",
        description: "Thêm mới thành công",
      });
    } catch (error) {
      console.log(error)
      if (error.code == "ERR_BAD_REQUEST") {
        notification.error({
          message: "Thất bại",
          description: "Vượt quá giới hạn cho phép, vui lòng nâng cấp gói cước",
        });
      } else {
        notification.error({
          message: "Thất bại",
          description: "Có lỗi xảy ra",
        });
      }
      // notification.error({
      //   message: "Thất bại",
      //   description:
      //     "Có lỗi xảy ra, vui lòng chọn các trường thông tin cho phù hợp",
      // });
    } finally {
      getPosts();
      setAddLoading(false);
    }
  };

  const RemoveCategory = (item) => {
    setRemoveLoading(true);
    selectedRowKeys.forEach(async (row) => {
      try {
        let data = await $axios.delete(`/post/delete/${row}`);
        notification.success({
          message: "Thành công",
          description: "Xoá bài viết thành công",
        });
      } catch (error) {
        notification.error({
          message: "Thất bại",
          description: "Có lỗi xảy ra",
        });
      } finally {
        getPosts();
        setRemoveLoading(false);
      }
    });
  };

  const onSwitchChangeliveComment = async (item) => {
    try {
      let data = await $axios.patch(`/post/change-live-comment/${item._id}`, {
        status: !item.liveComment,
      });
      setPost((prevPosts) =>
        prevPosts.map((p) =>
          p._id === item._id ? { ...p, liveComment: !item.liveComment } : p
        )
      );
      notification.success({
        message: "Thành công",
        description: "Đổi trang thái quét comment thành công",
      });
    } catch (error) {
      if (error.code == "ERR_BAD_REQUEST") {
        notification.error({
          message: "Thất bại",
          description: "Vượt quá giới hạn cho phép, vui lòng nâng cấp gói cước",
        });
      } else {
        notification.error({
          message: "Thất bại",
          description: "Có lỗi xảy ra",
        });
      }
    }
  };

  const onSwitchChangeScanLike = async (item) => {
    try {
      let data = await $axios.patch(`/post/change-live-reaction/${item._id}`, {
        status: !item.liveReaction,
      });

      setPost((prevPosts) =>
        prevPosts.map((p) =>
          p._id === item._id ? { ...p, liveReaction: !item.liveReaction } : p
        )
      );

      notification.success({
        message: "Thành công",
        description: "Đổi trang thái quét like thành công",
      });
    } catch (error) {
      if (error.code == "ERR_BAD_REQUEST") {
        notification.error({
          message: "Thất bại",
          description: "Vượt quá giới hạn cho phép, vui lòng nâng cấp gói cước",
        });
      } else {
        notification.error({
          message: "Thất bại",
          description: "Có lỗi xảy ra",
        });
      }
    }
  };
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  const [openModalComment, setOpenModalComment] = useState(false);

  // const handleDownload = async () => {
  //   try {
  //     // Tên tệp cần tải xuống từ thư mục 'assets'
  //     const fileName = "File mẫu.xlsx"; // Thay 'your_file.pdf' bằng tên thực của tệp

  //     // Tạo một đường dẫn đầy đủ đến tệp trong thư mục 'assets'
  //     const filePath = `../../../assets/`;

  //     // Tải tệp xuống sử dụng thư viện file-saver
  //     await fetch(filePath)
  //       .then((response) => response.blob())
  //       .then((blob) => saveAs(blob, fileName));
  //   } catch (error) {
  //     console.error("Download failed:", error);
  //   }
  // };

  const handleChange = async (event) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      setLoading(true);
      try {
        let data = await $axios.$uploadFile(
          file,

          { "Content-Type": "multipart/form-data" }
        );
        notification.success({
          message: "Thành công",
          description: "Nhập link bằng file thành công",
        });
      } catch (error) {
        notification.error({
          message: "Thất bại",
          description: "Có lỗi xảy ra",
        });
      } finally {
        getPosts();
        setLoading(false);
      }
    }
  };

  const getStatistics = async (postIds = []) => {
    if (postIds.length === 0) {
      postIds = post.map((item) => item._id);
    }
    postIds.forEach(async (itemId) => {
      setLoadingStatistic((prev) => ({ ...prev, [itemId]: true }));

      try {
        const data = await $axios.get(`/post/statistic/${itemId}`);
        setStatisticCount((prev) => ({
          ...prev,
          [itemId]: data.data.data,
        }));
      } catch (error) {
        notification.error({
          message: "Thất bại",
          description: "Có lỗi xảy ra khi tải thống kê.",
        });
      } finally {
        setLoadingStatistic((prev) => ({ ...prev, [itemId]: false }));
      }
    });
  };

  useEffect(() => {
    setLoadingStatistic(
      post.reduce((acc, item) => ({ ...acc, [item._id]: false }), {})
    );

    const changedItems = post.filter((item, index) => {
      const prevItem = prevPostsRef.current[index];
      return (
        prevItem &&
        item._id === prevItem._id &&
        (item.liveComment !== prevItem.liveComment ||
          item.liveReaction !== prevItem.liveReaction)
      );
    });

    if (changedItems.length > 0) {
      getStatistics(changedItems.map((item) => item._id));
    } else {
      getStatistics();
    }

    prevPostsRef.current = post;
  }, [post]);

  const onShowSizeChange = (current, pageSize) => {
    setPageSize(pageSize);
  };
  const handleAdd = async (e) => {
    if (e.key === "Enter") {
      try {
        const add = $axios.post(`/category/create`, { name: e.target.value });
        notification.success({
          message: "Thành công",
          description: "Thêm mới thành công",
        });
      } catch (error) {
        notification.error({
          message: "Thất bại",
          description: "Thêm mới không thành công",
        });
      } finally {
        getCategory();
      }
    }
  };

  return (
    <div className="danhsachbaiviet card">
      <Tabs type="card" className="custome-tab">
        <TabPane
          tab={
            <span>
              <FolderOpenOutlined className="mx-1" />
              Bài viết
            </span>
          }
        >
          <div className="content px-3">
            <div>
              <Row gutter={20}>
                <Col span={24} offset={0}>
                  <Form
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    layout="vertical"
                    size="medium"
                  >
                    <Row gutter={6}>
                      <Col span={10} offset={0} className="d-flex">
                        <div
                          style={{
                            position: "relative",
                            overflow: "hidden",
                            display: "inline",
                          }}
                        >
                          <input
                            onChange={handleChange}
                            type="file"
                            className="cusor-pointer"
                            style={{
                              position: "absolute",
                              opacity: "0",
                              inset: "0",
                            }}
                          />
                          {/* <div
                            className="bg-primary border-primary text-12 text-white d-flex align-items-center justify-content-between"
                            style={{
                              padding: "4px 15px",
                              borderRadius: 6,
                              height: 32,
                            }}
                          >
                            <PlusOutlined style={{ marginRight: 8 }} />
                            Nhập link bằng file
                          </div> */}
                        </div>

                        {/* <Button
                          className="border-blue text-blue bg-white text-12 taifile ms-2"
                          onClick={handleDownload}
                        >
                          Tải file mẫu
                        </Button> */}
                      </Col>
                    </Row>
                    <div>
                      <Divider className="my-3" />
                    </div>
                    <Row gutter={6}>
                      <Col span={10} offset={0}>
                        <p className="m-0">Link bài viết:</p>
                        <Input
                          placeholder="Nhập link bài viết"
                          value={link}
                          allowClear
                          onChange={(e) => setLink(e.target.value)}
                          prefix={<EditOutlined className="icon-prefix" />}
                        />
                      </Col>
                      <Col span={8} offset={0}>
                        <p className="m-0">Tiêu đề:</p>
                        <Input
                          style={{ width: "100%" }}
                          value={title}
                          allowClear
                          showCount
                          maxLength={50}
                          onChange={(e) => setTitle(e.target.value)}
                          placeholder="Tên bài viết ..."
                          prefix={<StarFilled className="icon-prefix" />}
                        />
                      </Col>
                      <Col span={6} offset={0}>
                        <div className="d-flex align-items-center gap-2">
                          <p className="m-0">Danh Mục:</p>
                          <EditOutlined
                            className="cursor-pointer"
                            onClick={() => {
                              setOpenEditCate(true);
                            }}
                          />
                        </div>
                        <Select
                          showSearch
                          style={{ width: "100%" }}
                          // onSearch={(e) => onSearch(e)}
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          onChange={(value) => {
                            setCate(value);
                          }}
                          onInputKeyDown={(e) => handleAdd(e)}
                          allowClear
                          placeholder="Tùy chọn"
                        >
                          {category.map((item, index) => (
                            <Option key={index} value={item._id}>
                              {item.name}
                            </Option>
                          ))}
                        </Select>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
              <Row gutter={20}>
                <Col span={24} offset={0}>
                  <Tooltip title="Thêm bài viết">
                    <Button
                      className="bg-primary border-primary text-12 text-white mt-2"
                      onClick={ScanPost}
                      icon={<PlusOutlined />}
                      loading={addLoading}
                      disabled={false}
                    >
                      Thêm bài viết
                    </Button>
                  </Tooltip>
                </Col>
              </Row>
            </div>
            <div>
              <Divider className="my-2" />
            </div>
            <div>
              <Row gutter={20}>
                <Col span={12} offset={0} className="d-flex">
                  <Tag
                    className="border-primary text-primary text-14 py-1 bg-white h-100"
                    closable={false}
                  >
                    {countTotal} bài viết
                  </Tag>
                  <Input
                    spellCheck={false}
                    style={{ width: 350 }}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    placeholder="Tìm kiếm ID bài viết hoặc tiêu đề bài viết"
                    prefix={<SearchOutlined />}
                    allowClear
                    className="me-2"
                  />
                  {hasSelected && (
                    <Popconfirm
                      title="Bạn có chắc chắn?"
                      onConfirm={RemoveCategory}
                      confirmButtonText="OK"
                      cancelButtonText="Không, cảm ơn."
                      icon={
                        <i
                          className="el-icon-info"
                          style={{ color: "red" }}
                        ></i>
                      }
                    >
                      <Button
                        type="primary"
                        className="text-12"
                        icon={<DeleteOutlined />}
                        loading={removeLoading}
                        danger
                      >
                        Xoá
                      </Button>
                    </Popconfirm>
                  )}
                </Col>
                <Col span={12} offset={0} className="text-end">
                  <Tooltip effect="dark" title="Tất cả bài viết">
                    <Button
                      type="primary"
                      onClick={() => {
                        getPosts();
                      }}
                      icon={<ReloadOutlined />}
                      className="text-12 bg-primary border-primary"
                      size="mini"
                    >
                      Cập nhật dữ liệu
                    </Button>
                  </Tooltip>
                </Col>
              </Row>
            </div>
            <div className="mt-2">
              <Table
                dataSource={post.map((item) => ({ key: item._id, ...item }))}
                bordered
                loading={loading}
                pagination={false}
                scroll={{
                  x: "max-content",
                  y: "calc(100vh - 550px)",
                }}
                rowSelection={{
                  type: "checkbox",
                  ...rowSelection,
                }}
              >
                <Table.Column
                  align="center"
                  title="Danh mục"
                  dataIndex="category"
                  render={(text, record) => (
                    <span>{record.category?.name}</span>
                  )}
                />
                <Table.Column
                  align="center"
                  title="Tiêu đề"
                  dataIndex="name"
                  render={(text, record) => (
                    <Tooltip effect="dark" title={record.url}>
                      <a
                        href={record.url}
                        target="_blank"
                        className="text-primary"
                        style={{ textDecoration: "none" }}
                        rel="noreferrer"
                      >
                        {record.name}
                      </a>
                    </Tooltip>
                  )}
                />
                <Table.Column
                  align="center"
                  title="Tổng cmt"
                  dataIndex="category"
                  render={(text, record, index) => (
                    <>
                      {loadingStatistic[record._id] ? (
                        <Spin />
                      ) : (
                        <span>
                          {statisticCount[record._id]?.totalCommentUids}
                        </span>
                      )}
                    </>
                  )}
                />
                <Table.Column
                  align="center"
                  title="Tổng like"
                  dataIndex="category"
                  render={(text, record, index) => (
                    <>
                      {loadingStatistic[record._id] ? (
                        <Spin />
                      ) : (
                        <span>
                          {statisticCount[record._id]?.totalReactionUids}
                        </span>
                      )}
                    </>
                  )}
                />
                <Table.Column
                  align="center"
                  title="Tổng phone"
                  dataIndex="category"
                  render={(text, record, index) => (
                    <>
                      {loadingStatistic[record._id] ? (
                        <Spin />
                      ) : (
                        <span>
                          {statisticCount[record._id]?.totalCommentPhones +
                            statisticCount[record._id]?.totalReactionPhones}
                        </span>
                      )}
                    </>
                  )}
                />
                <Table.Column
                  align="center"
                  title="Comment 24h"
                  dataIndex="category"
                  render={(text, record, index) => (
                    <>
                      {loadingStatistic[record._id] ? (
                        <Spin />
                      ) : (
                        <span
                          className={`${statisticCount[record._id]?.in24h?.comment
                            ?.isIncrease
                            ? "bg-green border-green"
                            : " bg-danger border-danger"
                            } text-white text-12 d-flex gap-2 align-items-center justify-content-center p-1 rounded `}
                        >
                          {
                            statisticCount[record._id]?.in24h?.comment
                              ?.totalComment24h
                          }
                          {statisticCount[record._id]?.in24h?.comment
                            ?.isIncrease ? (
                            <RiseOutlined className=" text-16" />
                          ) : (
                            <FallOutlined className=" text-16" />
                          )}
                        </span>
                      )}
                    </>
                  )}
                />
                <Table.Column
                  align="center"
                  title="Reaction 24h"
                  dataIndex="category"
                  render={(text, record, index) => (
                    <>
                      {loadingStatistic[record._id] ? (
                        <Spin />
                      ) : (
                        <span
                          className={`${statisticCount[record._id]?.in24h?.reaction
                            ?.isIncrease
                            ? "bg-green border-green"
                            : " bg-danger border-danger"
                            } text-white text-12 d-flex gap-2 align-items-center justify-content-center p-1 rounded `}
                        >
                          {
                            statisticCount[record._id]?.in24h?.reaction
                              ?.totalReaction24h
                          }
                          {statisticCount[record._id]?.in24h?.reaction
                            ?.isIncrease ? (
                            <RiseOutlined className="text-16" />
                          ) : (
                            <FallOutlined className=" text-16" />
                          )}
                        </span>
                      )}
                    </>
                  )}
                />
                <Table.Column
                  align="center"
                  title="Quét like"
                  dataIndex="liveReaction"
                  render={(text, record) => (
                    <Switch
                      value={record.liveReaction}
                      onChange={() => onSwitchChangeScanLike(record)}
                      className="switch"
                    />
                  )}
                />
                <Table.Column
                  align="center"
                  title="Quét comment"
                  dataIndex="liveComment"
                  render={(text, record) => (
                    <Switch
                      value={record.liveComment}
                      onChange={(e) => onSwitchChangeliveComment(record)}
                      className="switch"
                    />
                  )}
                />
                <Table.Column
                  align="center"
                  title="View"
                  dataIndex="like"
                  render={(text, record) => (
                    <Button
                      type="primary"
                      onClick={() => {
                        setChosenPost(record);
                        setOpenModalComment(true);
                      }}
                      icon={<EyeOutlined />}
                      className="text-12 bg-transparent border-0 text-eye"
                      size="mini"
                    ></Button>
                  )}
                />
              </Table>
              <Pagination
                className="mt-3"
                current={pager}
                total={countTotal}
                onChange={(page) => setPager(page)}
                style={{ marginBottom: 16 }}
                pageSize={pageSize}
                showSizeChanger
                onShowSizeChange={onShowSizeChange}
              />

              <CommentModal
                open={openModalComment}
                onCancel={(e) => {
                  setOpenModalComment(false);
                  getPosts();
                }}
                post={chosenPost}
              />
              <Modal
                title="Danh mục"
                centered
                footer={null}
                open={openEditCate}
                onCancel={() => {
                  setOpenEditCate(false);
                }}
                forceRender={false}
              >
                <Table
                  dataSource={category}
                  bordered
                  pagination={false}
                  scroll={{
                    x: "max-content",
                    y: "50vh",
                  }}
                >
                  <Table.Column
                    align="center"
                    title="Danh mục"
                    dataIndex="name"
                    render={(text, record) => (
                      <Input
                        placeholder="Nhập tên danh mục"
                        defaultValue={record.name}
                        onChange={(e) => {
                          setChosenId(record._id);
                          setNameCategory(e.target.value);
                        }}
                      />
                    )}
                  />
                  <Table.Column
                    title=""
                    align="center"
                    render={(value, record) => (
                      <Popconfirm
                        title="Bạn có chắc chắn muốn xóa danh mục này?"
                        onConfirm={() => RemoveMessage(record._id)}
                      >
                        <Tag
                          style={{ margin: 0, cursor: "pointer" }}
                          color="red"
                          icon={<DeleteOutlined />}
                        ></Tag>
                      </Popconfirm>
                    )}
                  />
                </Table>
              </Modal>
            </div>
          </div>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default List;
