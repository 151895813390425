import {
  DeleteOutlined,
  DownloadOutlined,
  PhoneOutlined,
  EditOutlined,
  InfoCircleOutlined,
  StarFilled,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Pagination,
  Popconfirm,
  Radio,
  Row,
  Switch,
  Table,
  Tag,
  Modal,
  Tooltip,
  notification,
} from "antd";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import $axios from "../../services/axios";
import "./comment.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faArrowUp } from "@fortawesome/free-solid-svg-icons";

const CommentModal = ({ post, open, onCancel }) => {
  const [typeTable, setTypeTable] = useState("comment");

  const [detail, setdetail] = useState(post);

  useEffect(() => {
    setdetail(post);
  }, [post]);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [hasPhone, setHasPhone] = useState(false);
  const [countUid, setCountUid] = useState(0);
  const [countPhone, setCountPhone] = useState(0);
  const [countUid2, setCountUid2] = useState(0);
  const [countPhone2, setCountPhone2] = useState(0);
  const [pager, setPager] = useState(1);
  const [countTotal, setCountTotal] = useState(0);
  const [pager2, setPager2] = useState(1);
  const [countTotal2, setCountTotal2] = useState(0);
  const [cmt, setCmt] = useState([]);
  const [like, setLike] = useState([]);
  const [removeLoading, setRemoveLoading] = useState(false);
  const [scan, setScan] = useState(false);
  const [pageSize, setPageSize] = useState(20);
  const [expandedRows, setExpandedRows] = useState([]);

  const toggleRow = (index) => {
    const newExpandedRows = [...expandedRows];
    newExpandedRows[index] = !newExpandedRows[index];
    setExpandedRows(newExpandedRows);
  };

  useEffect(() => {
    if (!detail?._id) return;
    getComments();
    getReactions();
    getCount();
    // ScanOldComment();
  }, [pager, post, typeTable, pager2, hasPhone, pageSize, detail?._id]);

  const getComments = async () => {
    if (!detail?._id) return;
    setLoading(true);
    const cmt = await $axios.get(
      `/post/list/${detail?._id}/comment?page=${pager}&perPage=${pageSize}&onlyPhone=${hasPhone}`
    );
    setCountTotal(cmt.data._metadata.pagination.total);
    setCmt(cmt.data.data);
    setLoading(false);
  };

  // const ScanOldComment = async () => {
  //   try {
  //     const data = await $axios.$get(
  //       `/post/${detail?.id}/scan-old-comment-status`
  //     );
  //     setScan(data?.data);
  //   } catch (error) {
  //     notification.error({
  //       message: "Thất bại",
  //       description: "Có lỗi xảy ra",
  //     });
  //   }
  // };
  const columns = [
    {
      title: "Type",
      align: "center",
      width: '5%',
      render: (text, record) => <Tag color="orange">Comment</Tag>,
    },
    {
      title: "Time",
      dataIndex: "time",
      align: "center",
      width: '5%',
      render: (text) => (
        <Tag color="orange">{moment(text).format("HH:mm:ss DD/MM/yyyy")}</Tag>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      width: '20%',
      render: (text, record) => (
        <Tooltip title={record.uid}>
          <a
            href={`https://facebook.com/${record.uid}`}
            target="_blank"
            className="text-primary"
            style={{ textDecoration: "none" }}
            rel="noreferrer"
          >
            {text}
          </a>
        </Tooltip>
      ),
    },
    {
      title: "Comment",
      dataIndex: "message",
      width: '40%',
      render: (text, record, index) => (
        <div style={{ width: "500px" }}>
          {record.message.length > 40 ? (
            <>
              {expandedRows[index] ? (
                <>
                  <span>{record.message + "  "}</span>
                  <a
                    className="text-blue text-decoration-none"
                    onClick={() => toggleRow(index)}
                  >
                    Ẩn bớt
                  </a>
                </>
              ) : (
                <>
                  {record.message.slice(0, 40) + "..."}
                  <a
                    className="text-blue text-decoration-none"
                    onClick={() => toggleRow(index)}
                  >
                    Xem thêm
                  </a>
                </>
              )}
            </>
          ) : (
            record.message
          )}
        </div>
      ),
    },
    {
      title: "Số điện thoại",
      align: "center",
      width: '30%',
      render: (text, record) => (
        <>
          {(record?.facebook?.zalos || []).map((item) => (
            <Tag
              key={item?.phone}
              className={`${
                item?.name ? "bg-blue border-blue" : " bg-danger border-danger"
              } text-white text-12`}
            >
              {item?.phone || ""}
              <br />
              {item?.name || ""}
            </Tag>
          ))}
        </>
      ),
    },
  ];
  const columns2 = [
    {
      title: "Type",
      align: "center",
      width: 100,
      render: (text, record) => <Tag color="orange">Reaction</Tag>,
    },
    {
      title: "Time",
      dataIndex: "createdAt",
      align: "center",
      render: (text) => (
        <Tag color="orange">{moment(text).format("HH:mm:ss DD/MM/yyyy")}</Tag>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (text, record) => (
        <Tooltip title={record.uid}>
          <a
            href={`https://facebook.com/${record.uid}`}
            target="_blank"
            className="text-primary"
            style={{ textDecoration: "none" }}
            rel="noreferrer"
          >
            {text}
          </a>
        </Tooltip>
      ),
    },
    {
      title: "Số điện thoại",
      align: "center",
      render: (text, record) => (
        <>
          {(record?.facebook?.zalos || []).map((item) => (
            <Tag
              key={item?.phone}
              className={`${
                item?.name ? "bg-blue  border-blue" : " bg-danger border-danger"
              } text-white text-12`}
            >
              {item?.phone || ""}
              <br />
              {item?.name || ""}
            </Tag>
          ))}
        </>
      ),
    },
  ];
  const liveComment = async () => {
    try {
      const data = await $axios.$post(`/post/${detail?.id}/scan-old-comment`);
      notification.success({
        message: "Thành công",
        description: "Đang quét comment cũ",
      });
      // ScanOldComment();
      getComments();
    } catch (error) {
      notification.error({
        message: "Thất bại",
        description: "Có lỗi xảy ra",
      });
    }
  };

  const getCount = async () => {
    if (!detail?._id) return;
    try {
      const data = await $axios.get(`/post/statistic/${detail?._id}`);
      setCountUid(data.data.data.totalCommentUids);
      setCountPhone(data.data.data.totalCommentPhones);
      setCountUid2(data.data.data.totalReactionUids);
      setCountPhone2(data.data.data.totalReactionPhones);
    } catch (error) {
      notification.error({
        message: "Thất bại",
        description: "Có lỗi xảy ra",
      });
    }
  };

  const exportData = async () => {
    if (!detail?._id) return;
    setLoadingExport(true);
    try {
      const response = await $axios.get(
        typeTable == "comment"
          ? `/comment/export?postId=${detail?.id}`
          : `/reaction/export?postId=${detail?.id}`,
        {
          responseType: "arraybuffer", // Specify the response type as arraybuffer
        }
      );

      // Convert array buffer to Blob
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Create a temporary URL for the blob
      const url = window.URL.createObjectURL(blob);

      // Create a link element to trigger the download
      const a = document.createElement("a");
      a.href = url;

      // Set the desired file name with .xlsx extension
      const nameFile = "Export.xlsx";
      a.download = nameFile;

      // Append the link to the body and trigger the download
      document.body.appendChild(a);
      a.click();

      // Remove the link from the body and revoke the URL to release resources
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      notification.error({
        message: "Thất bại",
        description: "Có lỗi xảy ra",
      });
    } finally {
      setLoadingExport(false);
    }
  };

  const getReactions = async () => {
    if (!detail?._id) return;
    setLoading2(true);
    const cmt = await $axios.get(
      `/post/list/${detail?._id}/reaction?page=${pager2}&perPage=${pageSize}&onlyPhone=${hasPhone}`
    );
    setCountTotal2(cmt.data._metadata.pagination.total);
    setLike(cmt.data.data);
    setLoading2(false);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = useMemo(() => {
    return selectedRowKeys.length > 0;
  }, [selectedRowKeys]);

  const onSwitchChangeliveComment = async () => {
    if (!detail?._id) return;
    setLoading(true);

    try {
      let data = await $axios.patch(
        `/post/change-live-comment/${detail?._id}`,
        {
          status: !detail?.liveComment,
        }
      );
      notification.success({
        message: "Thành công",
        description: "Đổi trang thái quét comment thành công",
      });
    } catch (error) {
      if (error.code == "ERR_BAD_REQUEST") {
        notification.error({
          message: "Thất bại",
          description: "Vượt quá giới hạn cho phép, vui lòng nâng cấp gói cước",
        });
      } else {
        notification.error({
          message: "Thất bại",
          description: "Có lỗi xảy ra",
        });
      }
    } finally {
      setdetail((prev) => ({ ...prev, liveComment: !prev.liveComment }));
      getComments();
    }
  };

  const onSwitchChangeScanLike = async () => {
    if (!detail?._id) return;
    setLoading(true);

    try {
      let data = await $axios.patch(
        `/post/change-live-reaction/${detail?._id}`,
        {
          status: !detail?.liveReaction,
        }
      );
      notification.success({
        message: "Thành công",
        description: "Đổi trang thái quét like thành công",
      });
    } catch (error) {
      if (error.code == "ERR_BAD_REQUEST") {
        notification.error({
          message: "Thất bại",
          description: "Vượt quá giới hạn cho phép, vui lòng nâng cấp gói cước",
        });
      } else {
        notification.error({
          message: "Thất bại",
          description: "Có lỗi xảy ra",
        });
      }
    } finally {
      getComments();
      setdetail((prev) => ({
        ...prev,
        liveReaction: !prev.liveReaction,
      }));
    }
  };

  const onShowSizeChange = (current, pageSize) => {
    setPageSize(pageSize);
  };

  const RemoveCategory = (item) => {
    if (!detail?._id) return;
    setRemoveLoading(true);
    selectedRowKeys.forEach(async (row) => {
      try {
        if (typeTable == "comment") {
          let data = await $axios.delete(`/comment/delete/${row}`);
          notification.success({
            message: "Thành công",
            description: "Xoá bài viết thành công",
          });
        } else {
          let data = await $axios.delete(`/reaction/delete/${row}`);
          notification.success({
            message: "Thành công",
            description: "Xoá tương tác thành công",
          });
        }
      } catch (error) {
        notification.error({
          message: "Thất bại",
          description: "Có lỗi xảy ra",
        });
      } finally {
        getComments();
        getReactions();
      }
      setRemoveLoading(false);
    });
  };

  return (
    <Modal
      title="COMMENT"
      centered
      footer={null}
      width="90%"
      style={{ top: 20 }}
      open={open}
      onCancel={() => {
        setSelectedRowKeys([]);
        setdetail(null);
        setTypeTable("comment");
        onCancel();
      }}
      forceRender={false}
    >
      <div className="comment-modal">
        <Form
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          autoComplete="off"
          size="medium"
        >
          <Row gutter={6}>
            <Col span={16} offset={0}>
              Link bài viết:
              <Input
                spellCheck={false}
                style={{ width: "100%" }}
                placeholder="Nhập link bài viết ..."
                disabled
                value={detail?.url}
                prefix={<EditOutlined className="site-form-item-icon" />}
              />
            </Col>
            <Col span={8} offset={0}>
              Tiêu đề:
              <Input
                spellCheck={false}
                style={{ width: "100%" }}
                placeholder="Tên bài viết ..."
                value={detail?.name}
                disabled
                prefix={<StarFilled className="site-form-item-icon" />}
              />
            </Col>
          </Row>
          <Row gutter={0} className="mt-2">
            <Col align="right" span={24} offset={0}>
              <Tooltip effect="dark" title="Tổng tất cả UID">
                <Tag
                  color="#e6a23c"
                  closable={false}
                  effect="dark"
                  className="py-1 px-3"
                >
                  <FontAwesomeIcon
                    icon={faUser}
                    style={{ color: "#fff" }}
                    className="me-1"
                  />
                  {typeTable == "comment" ? countUid || 0 : countUid2 || 0} UID
                </Tag>
              </Tooltip>
              <Tooltip effect="dark" title="Tổng tất cả SĐT tìm thấy">
                <Tag
                  color="#f56c6c"
                  closable={false}
                  effect="dark"
                  className="py-1 px-3"
                >
                  <PhoneOutlined className="me-1" />
                  {typeTable == "comment"
                    ? countPhone || 0
                    : countPhone2 || 0}{" "}
                  SĐT
                </Tag>
              </Tooltip>
            </Col>
          </Row>
          <div>
            <Divider />
          </div>
          <Row gutter={0} className="align-items-center">
            <Col align="left" span={12} offset={0}>
              <Radio.Group
                onChange={(e) => [
                  setTypeTable(e.target.value),
                  setSelectedRowKeys([]),
                ]}
                value={typeTable}
              >
                <Radio.Button value="reaction" className="text-12">
                  REACTION
                </Radio.Button>
                <Radio.Button value="comment" className="text-12">
                  COMMENT
                </Radio.Button>
              </Radio.Group>

              {/* <Button
              className="btn-dowload text-12 rounded-6 ms-1"
              onClick={exportData}
              icon={<DownloadOutlined />}
              loading={loadingExport}
            >
              Export Data
            </Button> */}

              {hasSelected && (
                <Popconfirm
                  title="Bạn có chắc chắn?"
                  confirmButtonText="OK"
                  onConfirm={RemoveCategory}
                  cancelButtonText="Không,cảm ơn."
                  icon={<InfoCircleOutlined style={{ color: "red" }} />}
                >
                  <Button
                    type="primary"
                    className="rounded-6 text-12 text-white ms-1"
                    icon={<DeleteOutlined />}
                    danger
                    loading={removeLoading}
                  >
                    Xóa {selectedRowKeys.length} dòng
                  </Button>
                </Popconfirm>
              )}
            </Col>
            <Col align="right" span={12} offset={0}>
              <Row
                gutter={20}
                className="justify-content-end align-items-center"
              >
                {/* <Col>
                <Button
                  className="bg-primary border-primary text-12 text-white rounded-6 ms-1"
                  disabled={scan}
                  loading={scan}
                  onClick={liveComment}
                >
                  {scan ? "Đang quét comment cũ " : "Quét comment cũ"}
                </Button>
              </Col> */}
                <Col>
                  <Switch
                    checked={detail?.liveComment}
                    onChange={onSwitchChangeliveComment}
                    style={{ marginRight: "10px" }}
                  />
                  <span className="text-14">Live comment</span>
                </Col>
                <Col>
                  <Switch
                    checked={detail?.liveReaction}
                    onChange={onSwitchChangeScanLike}
                    style={{ marginRight: "10px" }}
                  />
                  <span className="text-14">Live reaction</span>
                </Col>
                <Col>
                  <Switch
                    checked={hasPhone}
                    onChange={(value) => setHasPhone(value)}
                    style={{ marginRight: "10px" }}
                  />
                  <span className="text-14">Chỉ hiển thị SĐT</span>
                </Col>
              </Row>
            </Col>
          </Row>
          <div className="mt-4">
            {typeTable == "comment" ? (
              <>
                <Table
                  rowSelection={rowSelection}
                  columns={columns}
                  dataSource={cmt.map((item) => ({ key: item._id, ...item }))}
                  loading={loading}
                  pagination={false}
                  scroll={{
                    x: "max-content",
                    y: "50vh",
                  }}
                />
                <Pagination
                  className="mt-3"
                  current={pager}
                  pageSize={pageSize}
                  total={countTotal}
                  onChange={(page) => setPager(page)}
                  showSizeChanger
                  onShowSizeChange={onShowSizeChange}
                />
              </>
            ) : (
              <>
                <Table
                  rowSelection={rowSelection}
                  columns={columns2}
                  dataSource={like.map((item) => ({ key: item._id, ...item }))}
                  loading={loading2}
                  pagination={false}
                  scroll={{
                    x: "max-content",
                    y: "50vh",
                  }}
                />
                <Pagination
                  className="mt-3"
                  current={pager2}
                  total={countTotal2}
                  onChange={(page) => setPager2(page)}
                  pageSize={pageSize}
                  showSizeChanger
                  onShowSizeChange={onShowSizeChange}
                />
              </>
            )}
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default CommentModal;
