import React, { useState } from "react";
import "./header.scss";
import logo from "../../assets/icon/zenspy-pro.png";
import { Dropdown, Flex, Typography, Modal, Steps, Form, Input, Button, Space, notification, Tag } from "antd";
import { UserOutlined, KeyOutlined, LogoutOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import $axios from "../../services/axios";


const { Text } = Typography;
const Header = () => {
  const navigate = useNavigate();
  const user = localStorage.getItem("user_info") ? JSON.parse(localStorage.getItem("user_info")) : {};
  const items = [
    {
      label: <Link style={{ textDecoration: 'none' }} to={'/dashboard/thong-tin-ca-nhan'}>Profile</Link>,
      key: "1",
      icon: <UserOutlined style={{ color: '#ff7731' }} />,
    },
    {
      label: "Thay đổi mật khẩu",
      key: "2",
      icon: <KeyOutlined style={{ color: '#ff7731' }} />,
    },
    {
      label: <span onClick={() => logout()}>Đăng xuất</span>,
      key: "3",
      icon: <LogoutOutlined style={{ color: '#ff7731' }} />,
    },
  ];

  const logout = () => {
    localStorage.clear();
    navigate('/');
  }

  const onClick = ({ key }) => {
    if (key == 2) {
      showModal();
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = async () => {
    try {
      const update = await $axios.patch('user/change-password', changePassword);
      setChangePassword({oldPassword: '', newPassword: ''});
      notification.success({
        message: 'Thành công',
        description: 'Đổi mật khẩu thành công',
      });
      setIsModalOpen(false);
    } catch (error) {
      notification.error({
        message: 'Thất bại',
        description: error.response.data.error || 'Đổi mật khẩu thất bại',
      });
    }
  };

  const handleCancel = () => {
    // setCurrent(0);
    
    setChangePassword({ oldPassword: "", newPassword: "" });
    setIsModalOpen(false);
  };

  const [current, setCurrent] = useState(0);

  const next = () => {
    setCurrent(current + 1);
  }

  const stepItems = [
    {
      title: '',
      description: '',
    },
    {
      title: 'Bước 2',
      description: '',
    },
    {
      title: 'Hoàn thành',
      description: '',
    },
  ];

  const [changePassword, setChangePassword] = useState({
    oldPassword: "",
    newPassword: "",
  });

  return (
    <header className="header px-4 position-fixed w-100" style={{ height: "60px", zIndex: 2, background: "#fff" }}>
      <Flex gap="middle" align="center" justify="space-between">
        <Space>
          <img src={logo} alt="" style={{ width: "190px" }} />
          <Tag color="#f50">V3</Tag>
        </Space>
        <Dropdown overlayClassName="customDropdown" menu={{ items, onClick }} trigger={["click"]}>
          <Text className="cusor-pointer" style={{ color: "orange" }} onClick={(e) => e.preventDefault()}>
            Xin chào <span style={{ color: "#f74444" }}>{user?.username}</span>
          </Text>
        </Dropdown>
      </Flex>
      <Modal title="Đổi mật khẩu" open={isModalOpen} footer={null} onCancel={handleCancel}>
        <div className="modalchangepassword">
          {/* <Steps className="mt-4" current={current} labelPlacement="vertical" items={stepItems} /> */}
          <div className="mt-3">
            <Form name="validateOnly" layout="vertical" autoComplete="off">
              <Form.Item
                name="password"
                label="Mật khẩu cũ"
                rules={[{ required: true, message: "Mật khẩu cũ không được để trống" }]}
                hasFeedback
              >
                <Input.Password
                  onChange={(e) => setChangePassword({ ...changePassword, oldPassword: e.target.value })}
                  className="mt-2"
                  placeholder="Nhập mật khẩu cũ"
                />
              </Form.Item>
              <Form.Item
                name="newPassword"
                label="Mật khẩu mới"
                rules={[{ required: true, message: "Mật khẩu mới không được để trống" }]}
                hasFeedback
              >
                <Input.Password
                  onChange={(e) => setChangePassword({ ...changePassword, newPassword: e.target.value })}
                  className="mt-2"
                  placeholder="Nhập mật khẩu mới"
                />
              </Form.Item>
              <Form.Item
                name="confirmPassword"
                label="Nhập lại mật khẩu mới"
                dependencies={["newPassword"]}
                rules={[
                  { required: true, message: "Nhập lại mật khẩu mới không được để trống" },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("newPassword") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error("Mật khẩu nhập lại không trùng khớp"));
                    },
                  }),
                ]}
                hasFeedback
              >
                <Input.Password className="mt-2" placeholder="Nhập lại mật khẩu mới" />
              </Form.Item>
              <Form.Item className="text-end">
                <Button onClick={handleCancel}>Cancel</Button>
                <Button className="bg-primary border-primary text-white ms-3" onClick={handleOk}>
                  Confirm
                </Button>
              </Form.Item>
            </Form>
            {/* {
              current == 0 ? (
                <Form name="validateOnly" layout="vertical" autoComplete="off">
                  <Form.Item name="password" label="Mật khẩu cũ" rules={[{ required: true }]}>
                    <Input.Password className="mt-2" placeholder="Nhập mật khẩu cũ" />
                  </Form.Item>
                  <Form.Item className="text-end">
                    <Button className="bg-cancel border-cancel" onClick={handleCancel}>Cancel</Button>
                    <Button className="bg-primary border-primary text-white ms-3" onClick={handleOk}>Confirm</Button>
                  </Form.Item>
                </Form>
              ) : current == 1 ? (
                <Form name="validateOnly" layout="vertical" autoComplete="off">
                  <Form.Item name="newPassword" label="Mật khẩu mới" rules={[{ required: true, message: 'Mật khẩu mới không được để trống' }]} hasFeedback>
                    <Input.Password className="mt-2" placeholder="Nhập mật khẩu mới" />
                  </Form.Item>
                  <Form.Item name="confirmPassword" label="Nhập lại mật khẩu mới" dependencies={['newPassword']} rules={[{ required: true, message: 'Nhập lại mật khẩu mới không được để trống' }, ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('newPassword') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('Mật khẩu nhập lại không trùng khớp'));
                    },
                  })]} hasFeedback>
                    <Input.Password className="mt-2" placeholder="Nhập lại mật khẩu mới" />
                  </Form.Item>
                  <Form.Item className="text-end">
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Button className="bg-primary border-primary text-white ms-3" onClick={handleOk}>Confirm</Button>
                  </Form.Item>
                </Form>
              ) : (
                <Form name="validateOnly" layout="vertical" autoComplete="off">
                  <Form.Item name="name" label="Name" rules={[{ required: true }]}>
                    <Input />
                  </Form.Item>
                  <Form.Item name="age" label="Age" rules={[{ required: true }]}>
                    <Input />
                  </Form.Item>
                  <Form.Item className="text-end">
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Button className="bg-primary border-primary text-white ms-3" onClick={handleOk}>Confirm</Button>
                  </Form.Item>
                </Form>
              )
            } */}
          </div>
        </div>
      </Modal>
    </header>
  );
};

export default Header;
