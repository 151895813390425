import React, { useState, useEffect } from 'react';
import { Row, Col, Input, Button, Tag, Avatar, Table, Tooltip, Pagination, notification } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

import axios from 'axios';

const { Column } = Table;

const list_permission = ['export'];
const GroupSearch = () => {
  const [keyword, setKeyword] = useState('');
  const [cursor, setCursor] = useState(null);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [accessToken, setAccessToken] = useState(null);
  const [result, setResult] = useState({
    list: [],
    count: 0,
    page: 1,
    limit: 8
  });

  const handleKeywordChange = (e) => {
    setKeyword(e.target.value);
  };

  const handleEnterPressed = async () => {
    try {
      setLoadingSearch(true);
      await Promise.all([searchInDatabase(), countSearchInDatabase()]);
      await searchGroup();
      setLoadingSearch(false);
      notification.success({
        message: `Tìm thấy ${result.count} kết quả`,
        duration: 3
      });
    } catch (error) {
      console.error(error);
      setLoadingSearch(false);
    }
  };

  const exportGroup = async () => {
    try {
      let response = await axios.post('/api/group/export', { where: where() }, { responseType: 'blob' });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'group.csv');
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error(error);
    }
  };

  const convertDateTime = (unixtime) => {
    let time = new Date(unixtime);
    let date = time.getDate();
    let month = time.getMonth() + 1;
    let year = time.getFullYear();
    return `${date}/${month}/${year}`;
  };

  const getToken = async () => {
    try {
      let { data } = await axios.post('/api/token/get-token-clone');
      setAccessToken(data.access_token);
    } catch (error) {
      console.error(error);
    }
  };

  const searchInDatabase = async () => {
    try {
      let { data } = await axios.get('/api/groupfacebook', {
        params: {
          where: where(),
          limit: result.limit,
          skip: (result.page - 1) * result.limit
        }
      });
      setResult({ ...result, list: data });
    } catch (error) {
      console.error(error);
    }
  };

  const countSearchInDatabase = async () => {
    try {
      let { data } = await axios.post('/api/group/count', { where: where() });
      setResult({ ...result, count: data.count });
    } catch (error) {
      console.error(error);
    }
  };

  const searchListGroupID = async (keyword, cursor) => {
    try {
      let { data } = await axios.post('/api/group/Search', { keyword, access_token: accessToken, cursor });
      if (data.error) throw data.error;
      setCursor(data.page_info.has_next_page ? data.page_info.end_cursor : 'stop');
      return data.list;
    } catch (error) {
      console.error(error);
    }
  };

  const filterListGroupID = async (list) => {
    try {
      let { data } = await axios.post('/api/group/Filter', { list });
      return data;
    } catch (error) {
      console.error(error);
    }
  };

  const getInfoGroup = async (group_id) => {
    try {
      let { data } = await axios.post('/api/group/GetInfo', { group_id, access_token: accessToken });
      return data;
    } catch (error) {
      console.error(error);
    }
  };

  const searchGroup = async () => {
    try {
      let listUid = await searchListGroupID(keyword, cursor);
      let listUidFetch = await filterListGroupID(listUid);
      for (let group_id of listUidFetch) {
        let info = await getInfoGroup(group_id);
        setResult({ ...result, list: [info, ...result.list], count: result.count + 1 });
        if (result.list.length > result.limit) setResult({ ...result, list: result.list.slice(0, result.limit) });
      }
      if (cursor === 'stop') return;
      await searchGroup();
    } catch (error) {
      console.error(error);
    }
  };

  const where = () => {
    return {
      keysearch: {
        contains: keyword.toLowerCase().replace(/ /g, '').trim()
      }
    };
  };

  useEffect(() => {
    const fetchData = async () => {
      await getToken();
    };
    fetchData();
  }, []);

  return (
    <div>
      <Row gutter={10}>
        <Col span={21} offset={0}>
          <Input
            style={{ width: '100%' }}
            spellCheck="false"
            placeholder="Nhập từ khóa ..."
            size="medium"
            type="text"
            autoComplete="off"
            value={keyword}
            onChange={handleKeywordChange}
            onPressEnter={handleEnterPressed}
          />
        </Col>
        <Col align="left" span={3} offset={0}>
          <Button
            style={{ width: '100%' }}
            type="primary"
            size="medium"
            loading={loadingSearch}
            onClick={handleEnterPressed}
          >
            Tìm kiếm
          </Button>
        </Col>
      </Row>

      <Row style={{ marginTop: 10, marginBottom: 10 }} gutter={20}>
        <Col align="left" span={24} offset={0}>
          <Tag closable={false} effect="plain" className='text-14 py-1 border-primary text-primary bg-white' disableTransitions={false}>
            {result.count} nhóm
          </Tag>
          {list_permission.includes('export') && (
            <Button type="primary" size="mini" onClick={exportGroup} icon={<DownloadOutlined />}  loading={false}>
              Xuất file Excel
            </Button>
          )}
        </Col>
      </Row>

      <Table
        dataSource={result.list}
        bordered
        rowKey="group_id"
        pagination={false}
        style={{ width: '100%' }}
        rowSelection={{
          type: 'checkbox'
        }}
      >
        <Column align="center" title="ID" dataIndex="group_id" key="group_id" width={180} fixed={false} />

        <Column
          title="Tên Nhóm"
          dataIndex="name"
          key="name"
          align="left"
          fixed={false}
          render={(text, record) => (
            <Row gutter={20}>
              <Col align="left" span={2} offset={0}>
                <Avatar icon={<i className="el-icon-user-solid"></i>} shape="square" size="small" src={record.avatar} />
              </Col>
              <Col align="left" span={22} offset={0}>
                <a href={`https://www.facebook.com/groups/${record.group_id}`} target="_blank" rel="noopener noreferrer">
                  {record.name}
                </a>
              </Col>
            </Row>
          )}
        />

        <Column
          title="Số Thành Viên"
          dataIndex="member_count"
          key="member_count"
          align="center"
          width={140}
          fixed={false}
          render={(text, record) => (
            <Tooltip placement="topStart" title={() => convertDateTime(record.createdAt)}>
              <Tag type="primary" closable={false} effect="light" size="small" disableTransitions={false}>
                {record.member_count.toLocaleString()}
              </Tag>
            </Tooltip>
          )}
        />

        <Column
          title="Chế độ"
          dataIndex="privacy"
          key="privacy"
          align="center"
          width={120}
          fixed={false}
          render={(text, record) => (
            <>
              {record.privacy === 'OPEN' && (
                <Tag type="success" closable={false} effect="plain" size="large" disableTransitions={false}>
                  {record.privacy}
                </Tag>
              )}
              {record.privacy === 'CLOSED' && (
                <Tag type="danger" closable={false} effect="plain" size="large" disableTransitions={false}>
                  {record.privacy}
                </Tag>
              )}
            </>
          )}
        />

        <Column
          title="Ngày tạo"
          dataIndex="created_time"
          key="created_time"
          align="center"
          width={150}
          fixed={false}
          render={(text, record) => convertDateTime(record.created_time)}
        />
      </Table>

      <br />

      <Pagination
        total={result.count}
        pageSize={10}
        current={result.page}
        onChange={searchInDatabase}
        showSizeChanger={false}
        showQuickJumper
        showLessItems
        hideOnSinglePage={false}
        size="default"
        className="pagination"
      />
    </div>
  );
};

export default GroupSearch;
