import {
  CopyOutlined,
  DeleteOutlined,
  FolderOpenOutlined,
  OrderedListOutlined,
  ToolOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Form,
  Modal,
  Pagination,
  Popconfirm,
  Row,
  Select,
  Switch,
  Table,
  Tabs,
  Avatar,
  Tooltip,
  notification,
} from "antd";
import React, { useEffect, useState } from "react";
import $axios from "../../services/axios";
import ScriptModal from "./KichBan";
import "./list.scss";
import SettingModal from "./Setting";

const { Option } = Select;
const { TabPane } = Tabs;

const AutoZalo = () => {
  const [invite, setInvite] = useState([]);
  const [message, setMessage] = useState([]);
  const [post, setPost] = useState([]);
  const [zalos, setZalos] = useState([]);
  const [activeTab, setActiveTab] = useState("zalo");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pager, setPager] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [pager2, setPager2] = useState(1);
  const [pageSize2, setPageSize2] = useState(20);
  const [countTotal, setCountTotal] = useState(0);
  const [countTotal2, setCountTotal2] = useState(0);
  const [removeLoading, setRemoveLoading] = useState(false);

  useEffect(() => {
    getInvite();
    getMessage();
  }, []);

  useEffect(() => {
    getZalos();
  }, [pager2, pageSize2]);
  useEffect(() => {
    getPosts();
  }, [pager, pageSize]);

  const getInvite = async () => {
    const data = await $axios.get("/template-invite/list");
    setInvite([{ name: "Xoá lời mời", _id: null }, ...data.data.data]);
  };
  const getMessage = async () => {
    const data = await $axios.get("/template-message/list");
    setMessage([{ name: "Xoá lời nhắn", _id: null }, ...data.data.data]);
  };

  const getPosts = async () => {
    setLoading(true);
    const posts = await $axios.get("/post/list", {
      params: {
        page: pager,
        perPage: pageSize,
        search: "",
        orderDirection: "desc",
        orderBy: "createdAt",
      },
    });
    setCountTotal(posts.data._metadata.pagination.total);
    console.log(posts.data.data);
    setPost(posts.data.data);
    setLoading(false);
  };

  const getZalos = async () => {
    setLoading(true);
    const posts = await $axios.get("/auto-zalo/list", {
      params: {
        page: pager2,
        perPage: pageSize2,
        search: "",
        orderDirection: "desc",
        orderBy: "createdAt",
      },
    });
    setCountTotal2(posts.data._metadata.pagination.total);
    console.log(posts.data.data);
    setZalos(posts.data.data);
    setLoading(false);
  };
  const handleChangeInvite = async (value, item) => {
    setLoading(true);

    try {
      if (value) {
        let data = await $axios.patch(`/post/change-invite/${item._id}`, {
          templateInvite: value,
        });
      } else {
        let data = await $axios.patch(`/post/change-invite/${item._id}`, {
          templateInvite: value,
        });
      }
      notification.success({
        message: "Thành công",
        description: "Cập nhật lời mời thành công",
      });
    } catch (error) {
      notification.error({
        message: "Thất bại",
        description: "Có lỗi xảy ra",
      });
    } finally {
      getPosts();
      getZalos();
    }
  };
  const handleChangeMessage = async (value, item) => {
    setLoading(true);

    try {
      if (value) {
        let data = await $axios.patch(`/post/change-message/${item._id}`, {
          templateMessage: value,
        });
      } else {
        let data = await $axios.patch(`/post/change-message/${item._id}`, {
          templateMessage: value,
        });
      }
      notification.success({
        message: "Thành công",
        description: "Cập nhật lời nhắn thành công",
      });
    } catch (error) {
      notification.error({
        message: "Thất bại",
        description: "Có lỗi xảy ra",
      });
    } finally {
      getZalos();
      getPosts();
    }
  };

  const onSwitchChangeZalo = async (item) => {
    setLoading(true);

    try {
      let data = await $axios.patch(
        `/post/change-auto-zalo-comment/${item._id}`,
        {
          status: !item.autoZaloComment,
        }
      );
      notification.success({
        message: "Thành công",
        description: "Đổi trang thái auto comment thành công",
      });
    } catch (error) {
      if (error.response.data.statusCode == 5706) {
        notification.error({
          message: "Thất bại",
          description: "Vượt quá giới hạn cho phép, vui lòng nâng cấp gói cước",
        });
      } else {
        notification.error({
          message: "Thất bại",
          description: "Có lỗi xảy ra",
        });
      }
    } finally {
      getPosts();
    }
  };

  const onSwitchChangeZaloReaction = async (item) => {
    setLoading(true);

    try {
      let data = await $axios.patch(
        `/post/change-auto-zalo-reaction/${item._id}`,
        {
          status: !item.autoZaloReaction,
        }
      );
      notification.success({
        message: "Thành công",
        description: "Đổi trang thái auto like thành công",
      });
    } catch (error) {
      if (error.response.data.statusCode == 5705) {
        notification.error({
          message: "Thất bại",
          description: "Vượt quá giới hạn cho phép, vui lòng nâng cấp gói cước",
        });
      } else {
        notification.error({
          message: "Thất bại",
          description: "Có lỗi xảy ra",
        });
      }
    } finally {
      getPosts();
    }
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  const [openModalKichban, setOpenModalKichban] = useState(false);
  const [openModalSetting, setOpenModalSetting] = useState(false);

  // const handleDownload = async () => {
  //   try {
  //     // Tên tệp cần tải xuống từ thư mục 'assets'
  //     const fileName = "File mẫu.xlsx"; // Thay 'your_file.pdf' bằng tên thực của tệp

  //     // Tạo một đường dẫn đầy đủ đến tệp trong thư mục 'assets'
  //     const filePath = `../../../assets/`;

  //     // Tải tệp xuống sử dụng thư viện file-saver
  //     await fetch(filePath)
  //       .then((response) => response.blob())
  //       .then((blob) => saveAs(blob, fileName));
  //   } catch (error) {
  //     console.error("Download failed:", error);
  //   }
  // };
  const RemoveCategory = (item) => {
    setRemoveLoading(true);
    selectedRowKeys.forEach(async (row) => {
      try {
        let data = await $axios.delete(`/post/delete/${row}`);
        notification.success({
          message: "Thành công",
          description: "Xoá bài viết thành công",
        });
      } catch (error) {
        notification.error({
          message: "Thất bại",
          description: "Có lỗi xảy ra",
        });
      } finally {
        getPosts();
        setRemoveLoading(false);
      }
    });
  };
  const handleChange = async (event) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      setLoading(true);
      try {
        let data = await $axios.$uploadFile(
          file,

          { "Content-Type": "multipart/form-data" }
        );
        notification.success({
          message: "Thành công",
          description: "Nhập link bằng file thành công",
        });
      } catch (error) {
        notification.error({
          message: "Thất bại",
          description: "Có lỗi xảy ra",
        });
      } finally {
        getPosts();
        getZalos();
        setLoading(false);
      }
    }
  };

  // const onSearch = (e) => {
  //   console.log(e);
  //   if (e.key === 'Enter') {
  //     console.log(1);
  //   }
  // }

  const onShowSizeChange = (current, pageSize) => {
    setPageSize(pageSize);
  };

  return (
    <div className="danhsachbaiviet card">
      <Tabs
        activeKey={activeTab}
        onChange={(key) => setActiveTab(key)}
        type="card"
        className="custome-tab"
      >
        <TabPane
          key={"zalo"}
          tab={
            <span>
              <FolderOpenOutlined className="mx-1" />
              Auto Zalo
            </span>
          }
        >
          <div className="content px-3">
            <div>
              <Row gutter={20}>
                <Col span={24} offset={0}>
                  <Form
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    layout="vertical"
                    size="medium"
                  >
                    <Row gutter={6}>
                      <Col span={12} offset={0}>
                        <Button
                          type="primary"
                          onClick={() => {
                            setOpenModalKichban(true);
                          }}
                          icon={<CopyOutlined />}
                          className="text-12 bg-primary border-primary"
                          size="mini"
                        >
                          Kịch bản
                        </Button>
                        <Button
                          type="primary"
                          onClick={() => {
                            setOpenModalSetting(true);
                          }}
                          icon={<ToolOutlined />}
                          className="text-12 bg-primary border-primary mx-2"
                          size="mini"
                        >
                          Cài đặt
                        </Button>
                      </Col>
                    </Row>
                    <Divider className="my-3" />

                    <div>
                      {hasSelected && (
                        <Popconfirm
                          title="Bạn có chắc chắn?"
                          onConfirm={RemoveCategory}
                          confirmButtonText="OK"
                          cancelButtonText="Không, cảm ơn."
                          icon={
                            <i
                              className="el-icon-info"
                              style={{ color: "red" }}
                            ></i>
                          }
                        >
                          <Button
                            type="primary"
                            className="text-12 mb-2"
                            icon={<DeleteOutlined />}
                            loading={removeLoading}
                            danger
                          >
                            Xoá
                          </Button>
                        </Popconfirm>
                      )}
                    </div>
                    <Table
                      dataSource={post.map((item) => ({
                        key: item._id,
                        ...item,
                      }))}
                      bordered
                      loading={loading}
                      pagination={false}
                      rowSelection={{
                        type: "checkbox",
                        ...rowSelection,
                      }}
                      scroll={{
                        x: "max-content",
                        y: `calc(100vh - 400px)`,
                      }}
                    >
                      <Table.Column
                        align="center"
                        title="Danh mục"
                        dataIndex="category"
                        render={(text, record) => (
                          <span>{record.category?.name}</span>
                        )}
                      />
                      <Table.Column
                        align="center"
                        title="Tiêu đề"
                        dataIndex="name"
                        render={(text, record) => (
                          <Tooltip effect="dark" title={record.url}>
                            <a
                              href={record.url}
                              target="_blank"
                              className="text-primary"
                              style={{ textDecoration: "none" }}
                              rel="noreferrer"
                            >
                              {record.name}
                            </a>
                          </Tooltip>
                        )}
                      />

                      <Table.Column
                        align="center"
                        title="Lời mời"
                        dataIndex="templateInvite"
                        render={(text, record) => (
                          <Select
                            style={{ width: 130 }}
                            className="text-start"
                            defaultValue={
                              !record.templateInvite
                                ? ""
                                : record.templateInvite._id
                            }
                            onChange={(value) =>
                              handleChangeInvite(value, record)
                            }
                            options={invite?.map((item) => ({
                              value: item._id,
                              label: item.name,
                            }))}
                          />
                        )}
                      />
                      <Table.Column
                        align="center"
                        title="Nhắn tin"
                        dataIndex="templateMessage"
                        render={(text, record) => (
                          <Select
                            defaultValue={
                              !record.templateMessage
                                ? ""
                                : record.templateMessage._id
                            }
                            style={{ width: 130 }}
                            className="text-start"
                            onChange={(value) =>
                              handleChangeMessage(value, record)
                            }
                            options={message?.map((item) => ({
                              value: item._id,
                              label: item.name,
                            }))}
                          />
                        )}
                      />
                      <Table.Column
                        align="center"
                        title="Auto Zalo cmt"
                        dataIndex="autoZaloComment"
                        render={(text, record) => (
                          <Switch
                            value={record.autoZaloComment}
                            onChange={(e) => onSwitchChangeZalo(record)}
                            className="switch"
                          />
                        )}
                      />
                      <Table.Column
                        align="center"
                        title="Auto Zalo like"
                        dataIndex="autoZaloReaction"
                        render={(text, record) => (
                          <Switch
                            value={record.autoZaloReaction}
                            onChange={(e) => onSwitchChangeZaloReaction(record)}
                            className="switch"
                          />
                        )}
                      />
                    </Table>
                    <Pagination
                      className="mt-3"
                      current={pager}
                      total={countTotal}
                      onChange={(page) => setPager(page)}
                      style={{ marginBottom: 16 }}
                      pageSize={pageSize}
                      showSizeChanger
                      onShowSizeChange={onShowSizeChange}
                    />
                  </Form>
                </Col>
              </Row>
            </div>
          </div>
        </TabPane>
        <TabPane
          key={"list"}
          tab={
            <span>
              <OrderedListOutlined className="mx-1" />
              Danh sách
            </span>
          }
        >
          <div className="content px-3">
            <div>
              <Row gutter={20}>
                <Col span={24} offset={0}>
                  <Form
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    layout="vertical"
                    size="medium"
                  >
                    <Row gutter={6}>
                      <Col span={12} offset={0}>
                        <Button
                          type="primary"
                          onClick={() => {
                            setOpenModalKichban(true);
                          }}
                          icon={<CopyOutlined />}
                          className="text-12 bg-primary border-primary"
                          size="mini"
                        >
                          Kịch bản
                        </Button>
                        <Button
                          type="primary"
                          onClick={() => {
                            setOpenModalSetting(true);
                          }}
                          icon={<ToolOutlined />}
                          className="text-12 bg-primary border-primary mx-2"
                          size="mini"
                        >
                          Cài đặt
                        </Button>
                      </Col>
                    </Row>

                    <Divider className="my-3" />

                    <div className="mt-2">
                      <Table
                        dataSource={zalos.map((item) => ({
                          key: item._id,
                          ...item,
                        }))}
                        bordered
                        loading={loading}
                        pagination={false}
                        scroll={{
                          x: "max-content",
                          y: `calc(100vh - 400px)`,
                        }}
                      >
                      <Table.Column
                        align="center"
                        title="Avatar"
                        dataIndex="zalo"
                        render={(text, record) => <Avatar src={record.zalo?.avatar || ""} />}
                      />
                        <Table.Column
                          align="center"
                          title="Tài khoản nhắn tin"
                          dataIndex="zalo"
                          render={(text, record) => <span>{record.zalo?.name || ""}</span>}
                        />
                        <Table.Column
                          align="center"
                          title="SDT nhắn tin"
                          dataIndex="zaloProfile"
                          render={(text, record) => <span>{record.zaloProfile.phone || ""}</span>}
                        />
                        <Table.Column
                          align="center"
                          title="Tên khách hàng"
                          dataIndex="zaloProfile"
                          render={(text, record) => <span>{record.zaloProfile.name || ""}</span>}
                        />
                        <Table.Column
                          align="center"
                          title="Tình trạng lời mời"
                          dataIndex="statusInvite"
                        />
                        <Table.Column
                          align="center"
                          title="Lỗi lời mời"
                          dataIndex="inviteError"
                        />
                        <Table.Column
                          align="center"
                          title="Tình trạng nhắn tin"
                          dataIndex="statusMessage"
                        />
                        <Table.Column
                          align="center"
                          title="Lỗi nhắn tin"
                          dataIndex="messageError"
                        />
                      </Table>
                      <Pagination
                        className="mt-3"
                        current={pager2}
                        total={countTotal2}
                        onChange={(page) => setPager2(page)}
                        style={{ marginBottom: 16 }}
                        pageSize={pageSize2}
                        showSizeChanger
                        onShowSizeChange={(current, pageSize) =>
                          setPageSize2(pageSize)
                        }
                      />
                    </div>
                  </Form>
                </Col>
              </Row>
            </div>
            {/* <Divider className="my-2" />
            <Row gutter={20}>
              <Col span={24} offset={0}>
                <h6>Danh sách nhắn tin Auto Zalo</h6>
              </Col>
            </Row> */}
          </div>
        </TabPane>
      </Tabs>
      <Modal
        title="Kịch bản"
        centered
        open={openModalKichban}
        footer={null}
        onCancel={(e) => {
          setOpenModalKichban(false);
          getMessage();
          getInvite();
        }}
        width="40%"
        style={{ top: 20 }}
      >
        <ScriptModal />
      </Modal>
      <Modal
        title="Cài đặt"
        centered
        open={openModalSetting}
        footer={null}
        onCancel={(e) => {
          setOpenModalSetting(false);
        }}
        width="100%"
        style={{ top: 20 }}
      >
        <SettingModal />
      </Modal>
    </div>
  );
};

export default AutoZalo;
