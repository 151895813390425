import React, { useEffect, useState } from 'react';
import { Modal, Form, Row, Col, Checkbox, Select, Button, notification, Upload, Table, Input, Popconfirm } from 'antd';
import { AppstoreOutlined, SendOutlined, DeleteOutlined, EditOutlined, PlusOutlined, PlusCircleOutlined } from '@ant-design/icons';
import $axios from '../../services/axios';
import ScriptModal from "./KichBan";

const { Option } = Select;

const SendMessage = ({ props }) => {
    let {
        sendMessageShow,
        loadingInvite,
        record,
        onHandlePictureCardPreview,
        onClose
    } = props;

    const [listImage, setListImage] = useState([]);
    const [showMauTinNhan, setShowMauTinNhan] = useState(false);
    const [checkInvite, setCheckedInvite] = useState(true);
    const [checkZalo, setCheckedZalo] = useState(true);
    const [listMessage, setListMessage] = useState([]);
    const [listFacebook, setListFacebook] = useState([]);
    const [listZalo, setListZalo] = useState([]);
    const [showEditSampleMessage, setShowEditSampleMessage] = useState(false);
    const [invite, setInvite] = useState([]);
    const [openModalKichban, setOpenModalKichban] = useState(false);
    const [tinnhan, setTinnhan] = useState({
        name: '',
        content: '',
        // images: []
    });
    const [expandedRows, setExpandedRows] = useState([]);
    const [sampleMessage, setSampleMessage] = useState({
        zaloAccountId: '',
        sampleMessageId: '',
        inviteId: ''
    });
    const toggleRow = (index) => {
        const newExpandedRows = [...expandedRows];
        newExpandedRows[index] = !newExpandedRows[index];
        setExpandedRows(newExpandedRows);
    };
    const columns = [
        {
            title: 'Tiêu đề',
            dataIndex: 'name',
            key: 'name',
            align: 'center',
            sorter: false,
            fixed: false,
        },
        {
            title: 'Nội dung',
            dataIndex: 'content',
            key: 'content',
            align: 'center',
            sorter: false,
            fixed: false,
            render: (value, record, index) => (
                <div style={{ maxWidth: "500px" }}>
                    {record.content.length > 40 ? (
                        <>
                            {expandedRows[index] ? (
                                <>
                                    <span>{record.content + "  "}</span>
                                    <a
                                        className="text-blue text-decoration-none"
                                        onClick={() => toggleRow(index)}
                                    >
                                        Ẩn bớt
                                    </a>
                                </>
                            ) : (
                                <>
                                    {record.content.slice(0, 40) + "..."}
                                    <a
                                        className="text-blue text-decoration-none"
                                        onClick={() => toggleRow(index)}
                                    >
                                        Xem thêm
                                    </a>
                                </>
                            )}
                        </>
                    ) : (
                        record.content
                    )}
                </div>
            ),
        },
        {
            title: '',
            dataIndex: '',
            key: 'edit',
            align: 'center',
            sorter: false,
            fixed: false,
            width: '5%',
            render: (text, record) => (
                <Button
                    className='text-12 btn-edit'
                    style={{ background: '#E6A23C', borderColor: '#E6A23C' }}
                    type="primary"
                    loading={false}
                    onClick={() => onEditMessage(record)}
                    size="small"
                    disabled={false}
                >
                    Edit
                </Button>
            ),
        },
        {
            title: '',
            dataIndex: '',
            key: 'delete',
            align: 'center',
            sorter: false,
            fixed: false,
            width: '5%',
            render: (text, record) => (
                <Popconfirm
                    title="Bạn có chắc chắn?"
                    onConfirm={() => onRemoveMessage(record)}
                    okText="OK"
                    cancelText="Không,cảm ơn."
                    icon={<DeleteOutlined style={{ color: 'red' }} />}
                >
                    <Button
                        type="primary"
                        danger
                        icon={<DeleteOutlined />}
                        loading={false}
                        size="small"
                        disabled={false}
                    ></Button>
                </Popconfirm>
            ),
        },
    ];

    const onEditMessage = (record) => {
        setTinnhan({
            name: record.name,
            content: record.content,
            // images: record.images
        });
        // let images = [];
        // for (let img of record.images) {
        //     images.push({
        //         url: img
        //     })
        // }
        // setListImage(images);
        setShowEditSampleMessage(true);
    }

    const handleFileChange = ({ fileList: newFileList }) => setListImage(newFileList);

    const onTitleMessageChange = (value) => {
        setTinnhan({ ...tinnhan, name: value });
    }

    const onNewMessageChange = (value) => {
        setTinnhan({ ...tinnhan, content: value });
    }

    useEffect(() => {
        getAllMessage();
        // getFaceBookAccount();
        getZaloAccount();
        getInvite();
    }, []);

    const getInvite = async () => {
        const data = await $axios.get("template-invite/list");
        setInvite(data.data.data);
    };

    const getAllMessage = async () => {
        try {
            const result = await $axios.get('template-message/list');
            setListMessage(result.data.data);
        } catch (error) {
            notification.error({
                message: 'Lỗi',
                description: 'Không lấy được dữ liệu'
            })
        }
    };

    // const getFaceBookAccount = async () => {
    //     try {
    //         const result = await $axios.get(`facebook`);
    //         setListFacebook(result.data.data.data);
    //     } catch (error) {
    //         notification.error({
    //             message: 'Lỗi',
    //             description: 'Không lấy được dữ liệu'
    //         })
    //     }
    // }

    const getZaloAccount = async () => {
        try {
            const result = await $axios.get(`zalo/list`);
            setListZalo(result.data.data);
        } catch (error) {
            notification.error({
                message: 'Lỗi',
                description: 'Không lấy được dữ liệu'
            })
        }
    }

    const handleThemMau = async () => {
        try {
            listImage.forEach(function (file, index) {
                let reader = new FileReader();
                reader.onload = (e) => {
                    file.base64 = e.target.result;
                };
                reader.readAsDataURL(file.originFileObj);
            });
            setTimeout(async () => {
                try {
                    const body = {
                        ...tinnhan,
                        // images: listImage.map(image => image.base64)
                    };
                    const addNew = await $axios.post('template-message/create', body);
                    notification.success({
                        message: 'Thành công',
                        description: 'Đã thêm mẫu tin nhắn'
                    });
                    setTinnhan({
                        name: '',
                        content: '',
                        // images: []
                    });
                    setListImage([]);
                    getAllMessage();
                } catch (error) {
                    notification.error({
                        message: 'Lỗi',
                        description: 'Thêm không thành công'
                    })
                }
            }, 200)

        } catch (error) {
            notification.error({
                message: 'Lỗi',
                description: 'Thêm không thành công'
            })
        }
    }

    const onRemoveMessage = async (record) => {
        try {
            const deleteAction = await $axios.delete(`template-message/delete/${record._id}`);
            getAllMessage();
        } catch (error) {
            notification.error({
                message: 'Lỗi',
                description: 'Xoá không thành công'
            })
        }
    }

    const onSend = async () => {
        try {
            if (checkInvite) {
                const body = {
                    zalo: sampleMessage.zaloAccountId,
                    templateInvite: sampleMessage.inviteId
                };
                const send = await $axios.post(`comment/send-invite/${record._id}`, body);
            }
            if (checkZalo) {
                const body = {
                    zalo: sampleMessage.zaloAccountId,
                    templateMessage: sampleMessage.sampleMessageId
                };
                const send = await $axios.post(`comment/send-message/${record._id}`, body);
            }
            notification.success({
                message: 'Thành công',
                description: 'Gửi thành công'
            });
            setSampleMessage({
                zaloAccountId: '',
                sampleMessageId: '',
                inviteId: ''
            });
            setCheckedZalo(true);
            setCheckedInvite(true);
            onClose();
        } catch (error) {
            notification.error({
                message: 'Lỗi',
                description: 'Gửi không thành công'
            })
        }
    }

    const handleEdit = async () => {

    }

    const handleCancelEdit = () => {
        setShowEditSampleMessage(false);
        setTinnhan({
            name: '',
            content: '',
            // images: []
        });
        setListImage([]);
    }

    return (
        <>
            <Modal
                title="Gửi tin nhắn"
                open={sendMessageShow}
                centered={false}
                fullscreen={false}
                top="10vh"
                width={500}
                onCancel={(e) => onClose()}
                footer={[
                    <Button
                        key="send"
                        type="primary"
                        className='text-12'
                        onClick={onSend}
                        loading={loadingInvite}
                        disabled={false}
                        icon={<SendOutlined />}
                    >
                        SEND
                    </Button>,
                ]}
            >
                <div>
                    <div className='mt-4'>
                        <div className='d-flex justify-content-between'>
                            <span>Zalo</span>
                            {/* <Button className='text-12 d-flex align-items-center' type="default" onClick={(e) => setShowMauTinNhan(true)}>
                                <AppstoreOutlined />
                                Chọn mẫu
                            </Button> */}
                            <Button className='text-12 d-flex align-items-center' type="default" onClick={(e) => setOpenModalKichban(true)}>
                                <AppstoreOutlined />
                                Chọn mẫu
                            </Button>
                        </div>
                        <div className='mt-2'>
                            <Select
                                className='mt-2'
                                style={{ width: '100%' }}
                                onChange={(e) => setSampleMessage({ ...sampleMessage, zaloAccountId: e })}
                                disabled={!checkZalo}
                                value={sampleMessage.zaloAccountId}
                                placeholder="Chọn"
                            >
                                {listZalo.map((data, index) => (
                                    <Option key={index} value={data._id}>{data.name}</Option>
                                ))}
                            </Select>
                        </div>
                    </div>

                    <Row gutter={20} className='mt-4'>
                        <Col span={12}>
                            <div>
                                <Checkbox
                                    checked={checkZalo}
                                    // disabled={disabled}
                                    onChange={(e) => setCheckedZalo(e.target.checked)}
                                >
                                    Tin nhắn
                                </Checkbox>
                                <Select
                                    style={{ width: '100%' }}
                                    onChange={(e) => setSampleMessage({ ...sampleMessage, sampleMessageId: e })}
                                    value={sampleMessage.sampleMessageId}
                                    placeholder="Chọn"
                                >
                                    {listMessage.map((data, index) => (
                                        <Option key={index} value={data._id}>{data.name}</Option>
                                    ))}
                                </Select>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div>
                                <Checkbox
                                    checked={checkInvite}
                                    // disabled={disabled}
                                    onChange={(e) => setCheckedInvite(e.target.checked)}
                                >
                                    Lời mời
                                </Checkbox>
                                <Select
                                    style={{ width: '100%' }}
                                    onChange={(e) => setSampleMessage({ ...sampleMessage, inviteId: e })}
                                    disabled={!checkInvite}
                                    value={sampleMessage.inviteId}
                                    placeholder="Chọn"
                                >
                                    {invite.map((data, index) => (
                                        <Option key={index} value={data._id}>{data.name}</Option>
                                    ))}
                                </Select>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Modal>
            <Modal
                title="Danh sách tin nhắn"
                open={showMauTinNhan}
                centered={false}
                fullscreen={false}
                style={{ top: 20 }}
                width='40%'
                onCancel={(e) => setShowMauTinNhan(false)}
                footer={null}
            >
                <div className='mt-4'>
                    <Input
                        onKeyUp={(e) => e.key === 'Enter' && onTitleMessageChange(e.target.value)}
                        spellCheck={false}
                        placeholder="Tiêu đề"
                        value={tinnhan.name}
                        size="medium"
                        allowClear
                        type="text"
                        autoComplete="off"
                        onChange={(e) => onTitleMessageChange(e.target.value)}
                    />
                    <br />
                    <br />
                    <Input.TextArea
                        value={tinnhan.content}
                        spellCheck={false}
                        rows={4}
                        placeholder="Nội dung mẫu tin nhắn ..."
                        onChange={(e) => onNewMessageChange(e.target.value)}
                    />
                    <br />
                    <br />
                    {/* <Upload
                        autoUpload={false}
                        name="image"
                        multiple
                        thumbnailMode
                        // action="/api/attachment/upload"
                        beforeUpload={() => false}
                        onChange={handleFileChange}
                        listType="picture-card"
                        fileList={listImage}
                        // onRemove={onRemoveAttachment}
                        onPreview={onHandlePictureCardPreview}
                    >
                        <div>
                            <PlusOutlined />
                            <div style={{ marginTop: 8 }}>Upload</div>
                        </div>
                    </Upload> */}
                    <div className='mt-2 my-3'>
                        {
                            showEditSampleMessage ? (
                                <>
                                    <Button onClick={handleEdit} type='primary' className='text-12'>Chỉnh sửa</Button>
                                    <Button onClick={(e) => handleCancelEdit()} type='primary' danger className='text-12 ms-2'>Huỷ bỏ</Button>
                                </>
                            ) : (
                                <Button onClick={handleThemMau} icon={<PlusCircleOutlined />} type='primary' className='text-12'>Thêm mẫu</Button>
                            )
                        }

                    </div>
                    <Table
                        dataSource={listMessage}
                        scroll={{
                            x: "max-content",
                            y: `calc(100vh - 450px)`,
                        }}
                        bordered
                        rowKey={(record) => record._id}
                        columns={columns}
                        pagination={false}
                    />
                </div>
            </Modal>
            <Modal
                title="Kịch bản"
                centered
                open={openModalKichban}
                footer={null}
                onCancel={(e) => {
                    setOpenModalKichban(false);
                    // getMessage();
                    getInvite();
                }}
                width="40%"
                style={{ top: 20 }}
            >
                <ScriptModal />
            </Modal>
        </>
    );
};

export default SendMessage;
