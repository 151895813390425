import React from "react";
import { Select, Modal, InputNumber } from "antd";

const ModalEdit = (props) => {
  const {
    titleModalEdit,
    openModalEdit,
    setOpenModalEdit,
    handleUpdateZalo,
    typeEdit,
    listProxy,
    setValueEdit,
    valueEdit,
  } = props;
  return (
    <Modal
      title={titleModalEdit}
      open={openModalEdit}
      onCancel={() => {
        setOpenModalEdit(false);
      }}
      onOk={handleUpdateZalo}
      centered={true}
      fullscreen={false}
    >
      {typeEdit === "changeProxy" ? (
        <div className="mt-3">
          <span style={{ display: "block" }}>Chọn proxy</span>
          <Select
            style={{ width: "100%" }}
            className="text-start"
            onChange={(value) => setValueEdit(value)}
            options={listProxy?.map((item) => ({
              value: item._id,
              label: item.name,
            }))}
            value={valueEdit}
          />
        </div>
      ) : (
        <div className="mt-3">
          <span>Nhập số lượng</span>
          <InputNumber
            className="mt-2"
            size="middle"
            min={0}
            style={{
              width: "100%",
            }}
            value={valueEdit}
            onChange={(e) => {
              setValueEdit(e);
            }}
          />
        </div>
      )}
    </Modal>
  );
};

export default ModalEdit;
