import React from "react";
import { Button, Modal, Select } from "antd";

const ModalAddZalo = (props) => {
  const {
    openAddZaloModal,
    setOpenAddZaloModal,
    setProxySelection,
    setImgQr,
    setLoadingAddZalo,
    listProxy,
    proxySelection,
    handleLoginZalo,
    loadingAddZalo,
  } = props;
  return (
    <Modal
      title="Thêm tài khoản"
      open={openAddZaloModal}
      onCancel={() => {
        setOpenAddZaloModal(false);
        setProxySelection("");
        setImgQr("");
        setLoadingAddZalo(false);
      }}
      centered={true}
      footer={null}
      fullscreen={false}
    >
      <div>Chọn proxy</div>
      <Select
        style={{ width: "100%" }}
        className="text-start"
        onChange={(value) => {
          setProxySelection(value);
        }}
        options={listProxy?.map((item) => ({
          value: item._id,
          // label: `${item.user}:${item.password}@${item.host}:${item.port}`,
          label: `${item.name}`,
        }))}
        value={proxySelection}
      />
      <br />
      <div className="d-flex align-item-center justify-content-center mt-4">
        <Button type="primary" size="mini" onClick={handleLoginZalo} style={{ fontSize: 12 }} loading={loadingAddZalo}>
          Đăng nhập
        </Button>
      </div>
    </Modal>
  );
};

export default ModalAddZalo;
