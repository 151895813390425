import React from 'react'
import '../styles/login.css'

const Register = () => {

   return (
      <section>
         <h1>register</h1>
      </section>
   )
}

export default Register