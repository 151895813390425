import React, { useState, useEffect } from "react";
import { DatePicker, notification, Spin } from "antd";
import dayjs from "dayjs";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import moment from "moment";
import "../styles/dashboard.scss";
import $axios from "../services/axios";

const { RangePicker } = DatePicker;

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const DateFormat = "DD-MM-YYYY";

const Dashboard = () => {
  const [rangeTimeComment, setRangeTimeComment] = useState({
    startDay: getDayBeforeNDays(9),
    endDay: getDayBeforeNDays(0),
  });

  function getDayBeforeNDays(n) {
    const day = new Date();
    day.setDate(day.getDate() - n);
    return moment(day).format(DateFormat);
  }
  const [count, setCount] = useState({
    phone: 0,
    comment: 0,
    reaction: 0,
  });
  const [loadingNumber, setLoadingNumber] = useState(false);
  const [dataChart, setDataChart] = useState(null);
  const [loadingChart, setLoadingChart] = useState(false);

  function convertStringToDate(inputString) {
    const momentObj = moment(inputString, "DD-MM-YYYY");
    // Thêm 1 ngày vào đối tượng moment
    const momentObjPlusOneDay = momentObj;

    // Chuyển đổi thành chuỗi ISO
    const formattedString = momentObjPlusOneDay.toISOString();

    return formattedString;
  }

  useEffect(() => {
    LoadData();
  }, [rangeTimeComment]);

  const LoadData = async () => {
    const end = convertStringToDate(rangeTimeComment.endDay).split(":");

    const endDate = end[0] + ":23:59." + end[2].split(".")[1];
    setLoadingNumber(true);
    setLoadingChart(true);
    try {
      const data = await $axios.get(
        `/statistic/get?fromTime=${convertStringToDate(
          rangeTimeComment.startDay
        )}&toTime=${endDate}`
      );

      setDataChart(data.data.data);

      setCount({
        phone: data.data.data.reduce((sum, item) => sum + item.phones, 0),
        comment: data.data.data.reduce((sum, item) => sum + item.comments, 0),
        reaction: data.data.data.reduce((sum, item) => sum + item.reactions, 0),
      });
      setLoadingNumber(false);
      setLoadingChart(false);
    } catch (error) {
      notification.error({
        message: "Thất bại",
        description: "Có lỗi xảy ra",
      });
    } finally {
    }
  };

  function prepareChartData(rawData) {
    const labels = rawData?.map((item) => item.date); // Lấy các nhãn là ngày tháng

    const reactionsData = rawData?.map((item) => item.reactions);
    const commentsData = rawData?.map((item) => item.comments);
    const phonesData = rawData?.map((item) => item.phones);

    const expectData = {
      labels: labels,
      datasets: [
        {
          label: "Reactions",
          data: reactionsData,
          maxBarThickness: 20,
          backgroundColor: getBackgroundColor("Reactions"),
          borderColor: getBorderColor("Reactions"),
          borderWidth: 2,
        },
        {
          label: "Comments",
          data: commentsData,
          maxBarThickness: 20,
          backgroundColor: getBackgroundColor("Comments"),
          borderColor: getBorderColor("Comments"),
          borderWidth: 2,
        },
        {
          label: "Phones",
          data: phonesData,
          maxBarThickness: 20,
          backgroundColor: getBackgroundColor("Phones"),
          borderColor: getBorderColor("Phones"),
          borderWidth: 2,
        },
      ],
    };

    return expectData;
  }

  function getBackgroundColor(label) {
    switch (label) {
      case "Comments":
        return "rgba(20, 255, 0, 0.3)";
      case "Reactions":
        return "rgb(21 155 232)";
      case "Phones":
        return "rgb(255 82 82)";
      default:
        return "";
    }
  }

  function getBorderColor(label) {
    switch (label) {
      case "Comments":
        return "rgba(100, 255, 0, 1)";
      case "Reactions":
        return "rgb(21 155 232)";
      case "Phones":
        return "rgb(255 82 82)";
      default:
        return "";
    }
  }

  function drawChart(data, chartName, labelData) {
    const chartData = prepareChartData(data);
    if (loadingChart) {
      return (<Spin />);
    }
    return (
      <Bar
        data={{
          labels: chartData?.labels,
          datasets: chartData?.datasets,
        }}
        height={80}
        options={{
          responsive: true,
          plugins: {
            legend: {
              position: "bottom",
            },
            title: {
              display: false,
              text: chartName,
            },
          },
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        }}
      />
    );
  }

  return (
    <div className="">
      <div className="d-flex align-items-center">
        <p className="m-0 mx-1">Thống kê từ:</p>
        <RangePicker
          onChange={(dates, dateString) => {
            setRangeTimeComment({
              startDay: dateString[0],
              endDay: dateString[1],
            });
          }}
          format={DateFormat}
          defaultValue={[
            dayjs(rangeTimeComment.startDay, DateFormat),
            dayjs(rangeTimeComment.endDay, DateFormat),
          ]}
          picker="date"
          allowClear={false}
          separator="To"
          placeholder={["Từ ngày", "Đến ngày"]}
        />
      </div>
      <br />
      <div style={{ marginTop: "15px" }}>
        <div className="d-flex justify-content-between align-items-center">
          <div
            className="site-card-border-less-wrapper text-center"
            style={{ backgroundColor: getBackgroundColor("Phones") }}
          >
            <p className="text-white" style={{ fontWeight: "500" }}>
              Tổng số điện thoại
            </p>
            {
              loadingNumber ? (
                <Spin style={{ marginTop: "10px" }} />
              ) : (
                <b
                  className="text-white"
                  style={{
                    fontSize: "25px",
                    marginTop: "10px",
                    display: "block",
                  }}
                >
                  {count.phone}
                </b>
              )
            }
          </div>
          <div
            className="site-card-border-less-wrapper text-center"
            style={{ backgroundColor: getBackgroundColor("Comments") }}
          >
            <p style={{ fontWeight: "500" }}>Tổng số bình luận</p>
            {
              loadingNumber ? (
                <Spin style={{ marginTop: "10px" }} />
              ) : (
                <b
                  style={{
                    fontSize: "25px",
                    marginTop: "10px",
                    display: "block",
                  }}
                >
                  {count.comment}
                </b>
              )
            }
          </div>
          <div
            className="site-card-border-less-wrapper text-center"
            style={{ backgroundColor: getBackgroundColor("Reactions") }}
          >
            <p className="text-white" style={{ fontWeight: "500" }}>
              Tổng số người thích
            </p>

            {
              loadingNumber ? (
                <Spin style={{ marginTop: "10px" }} />
              ) : (
                <b
                  className="text-white"
                  style={{
                    fontSize: "25px",
                    marginTop: "10px",
                    display: "block",
                  }}
                >
                  {count.reaction}
                </b>
              )
            }
          </div>
        </div>
      </div>
      <div className="chart-container">
        <span
          style={{
            marginLeft: "15px",
            display: "block",
            marginBottom: "20px",
            fontSize: "18px",
            fontWeight: "bold",
          }}
        >
          Biểu Đồ Thống Kê
        </span>
        {dataChart ? drawChart(dataChart, "", "") : <></>}
      </div>
    </div>
  );
};

export default Dashboard;
