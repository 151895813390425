import React from "react";
import { Input, Button, Modal, Form, Space } from "antd";

const ModalUpdateUser = (props) => {
  const { updateUserShow, setUpdateUserShow, formUpdateUser, updateProfileUser } = props;
  return (
    <Modal
      title="Cập nhật thông tin tài khoản"
      open={updateUserShow}
      onCancel={() => {
        setUpdateUserShow(false);
        formUpdateUser.resetFields();
      }}
      centered={true}
      footer={null}
      fullscreen={false}
    >
      <Form form={formUpdateUser} name="updateUserForm" onFinish={updateProfileUser} layout="vertical">
        <Form.Item
          name="email"
          label="Email"
          rules={[
            {
              type: "email",
              message: "Vui lòng nhập đúng định dạng Email!",
            },
          ]}
        >
          <Input placeholder="Nhập email" />
        </Form.Item>

        <Form.Item name="fullName" label="Họ và tên">
          <Input placeholder="Nhập họ và tên" />
        </Form.Item>

        <Form.Item label="Mật khẩu" name="password">
          <Input.Password placeholder="Nhập mật khẩu ..." />
        </Form.Item>
        <Form.Item>
          <Space>
            <Button
              htmlType="submit"
              onClick={() => {
                setUpdateUserShow(false);
                formUpdateUser.resetFields();
              }}
            >
              Hủy
            </Button>
            <Button type="primary" htmlType="submit">
              Cập nhật
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalUpdateUser;
