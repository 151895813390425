import React, { useEffect, useState } from "react";
import {
  Tabs,
  Row,
  Col,
  Tag,
  DatePicker,
  Select,
  Button,
  Switch,
  Input,
  Tooltip,
  Table,
  notification,
  Pagination,
} from "antd";
import {
  DownloadOutlined,
  LoadingOutlined,
  SearchOutlined,
  CommentOutlined,
  ClockCircleOutlined,
  FormOutlined,
  UserOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import "./binhluan.scss";
import $axios from "../../services/axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import dayjs from "dayjs";
import SendMessage from "./SendMessage";
import useDebounce from "../../hooks/useDebounceValue";

const { RangePicker } = DatePicker;
const { Option } = Select;
const { TabPane } = Tabs;

const DateFormat = "DD-MM-YYYY";

const Binhluan = () => {
  const [showModalSendMessage, setShowModalSendMessage] = useState(false);
  function getDayBeforeNDays(n) {
    const day = new Date();
    day.setDate(day.getDate() - n);
    return moment(day).format(DateFormat);
  }
  const [filter, setFilter] = useState({
    fromTime: getDayBeforeNDays(10),
    toTime: getDayBeforeNDays(0),
    // search: "",
    offset: 0,
    limit: 20,
    onlyPhone: false,
  });
  const [totalPost, setTotalPost] = useState(0);
  const [recordSelected, setRecordSelected] = useState({
    id: "",
  });
  const [search, setSearch] = useState("");
  const [list_comment, setListComment] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [loadingButton, setLoadingButton] = useState([]);
  const [pager, setPager] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const debouncedSearchValue = useDebounce(search, 500);
  const [expandedRows, setExpandedRows] = useState([]);

  const toggleRow = (index) => {
    const newExpandedRows = [...expandedRows];
    newExpandedRows[index] = !newExpandedRows[index];
    setExpandedRows(newExpandedRows);
  };

  const tableRowClassName = (record, index) => {
    // Implement your logic for row class name
    return "";
  };

  const GetStatusInvite = (row) => {
    // Implement your logic for getting status invite
    return "";
  };

  useEffect(() => {
    getComments();
  }, [filter, pager, pageSize, debouncedSearchValue]);

  const getComments = async () => {
    try {
      setLoading(true);
      const result = await $axios.get(
        `comment/list?fromTime=${convertStringToDate(
          filter.fromTime
        )}&toTime=${convertStringToDate(
          filter.toTime
        )}&search=${debouncedSearchValue}&page=${pager}&perPage=${pageSize}&onlyPhone=${filter.onlyPhone
        }`
      );
      setLoading(false);
      // const result = await $axios.get(`comment`);
      // notification.success({
      //   message: 'Thành công',
      //   description: 'Lấy dữ liệu thành công'
      // });
      let data = result.data.data;
      setTotalPost(result.data._metadata.pagination.total);
      setListComment(data);
    } catch (error) {
      setLoading(false);
      notification.error({
        message: "Thất bại",
        description: "Lấy dữ liệu thất bại",
      });
    }
  };

  function convertStringToDate(inputString) {
    const momentObj = moment(inputString, "DD-MM-YYYY");

    momentObj.add(1, 'day');
    
    const formattedString = momentObj.toISOString();

    return formattedString ?? "";
  }

  const checkPhone = async (record, index) => {
    try {
      const loadingButton2 = [...loadingButton];
      loadingButton2[index] = true;
      setLoadingButton(loadingButton2);
      const result = await $axios.get(`comment/check-phone/${record._id}`);
      const data = result.data.data;
      if ((data || []).length) {
        if (!data[0].name) {
          notification.error({
            message: "Không tìm thấy",
            description: "Zalo không hiển thị",
          });
        } else {
          const newData = list_comment.map((comment) =>
            comment._id === record._id
              ? {
                ...comment,
                facebook: {
                  zalos: data,
                }
              }
              : comment
          );
          setListComment(newData);
        }
      } else {
        notification.error({
          message: "Không tìm thấy",
          description: "Không quét ra được infozalo",
        });
      }
    } catch (error) {
      notification.error({
        message: "Không tìm thấy",
        description: "Vui lòng thêm tài khoản zalo",
      });
    } finally {
      const loadingButton2 = [...loadingButton];
      loadingButton2[index] = false;
      setLoadingButton(loadingButton2);
    }
  };

  const onShowSizeChange = (current, pageSize) => {
    setPageSize(pageSize);
  };

  const columns = [
    {
      title: (
        <div>
          <ClockCircleOutlined />
          <span className="font-500 ms-1">Thời gian</span>
        </div>
      ),
      dataIndex: "time",
      key: "time",
      align: "center",
      fixed: false,
      with: "10%",
      render: (text, record) => (
        <>
          {record.status_friend_id === 10 ? (
            <LoadingOutlined />
          ) : (
            <Tag
              className="cursor-pointer text-primary border-primary bg-white text-14 py-1"
              type={GetStatusInvite(record)}
              closable={false}
              effect="plain"
            >
              {moment(record.time).format("HH:mm:ss DD/MM/YYYY")}
            </Tag>
          )}
        </>
      ),
    },
    {
      title: (
        <div>
          <FormOutlined />
          <span className="font-500 ms-1">Tên bài</span>
        </div>
      ),
      dataIndex: "name",
      key: "name",
      align: "center",
      fixed: false,
      with: "5%",
      render: (text, record) => (
        <>
          <a
            className="text-decoration-none text-label cursor-pointer"
            href={`${record.post.url}`}
            target="_blank"
          // underline={false}
          >
            {record.post?.name || ""}
          </a>
        </>
      ),
    },
    // {
    //   title: (
    //     <div>
    //       <UserOutlined />
    //       <span className="font-500 ms-1">UID</span>
    //     </div>
    //   ),
    //   dataIndex: "uid",
    //   key: "uid",
    //   align: "center",
    //   fixed: false,
    //   with: '5%',
    //   render: (text, record) => (
    //     <>
    //       <span>
    //         {record.uid}
    //       </span>
    //     </>
    //   ),
    // },
    {
      title: "FB Name",
      dataIndex: "fb",
      key: "fb",
      align: "center",
      fixed: false,
      with: "5%",
      render: (text, record) => (
        <>
          <Tooltip title={record.uid}>
            <a
              className="text-decoration-none text-14"
              href={`https://www.facebook.com/${record.uid}`}
              target="_blank"
            >
              <FontAwesomeIcon
                icon={faUser}
                style={{ color: "#409EFF" }}
                className="text-12"
              />
              <span className="ms-1">{record.name}</span>
            </a>
          </Tooltip>
        </>
      ),
    },
    {
      title: (
        <div>
          <PhoneOutlined />
          <span className="font-500 ms-1">SĐT</span>
        </div>
      ),
      dataIndex: "facebook",
      key: "facebook",
      align: "center",
      fixed: false,
      with: "5%",
      render: (text, record, index) => (
        <>
          {/* <Button onClick={(e) => handleShowModal(record)} className="bg-blue text-white border-blue text-12" size="small">0987654321</Button> */}
          {record?.facebook?.zalos?.length ? (
            <>
              {record.facebook.zalos.map((data, index) => {
                return (
                  <>
                    <Button
                      onClick={(e) =>
                        data.name
                          ? handleShowModal(record)
                          : checkPhone(record, index)
                      }
                      className={`${data.name
                        ? "bg-blue text-white border-blue"
                        : "text-white bg-danger border-danger"
                        } text-white text-12`}
                      size="small"
                    >
                      {data.name
                        ? `${data?.phone} (${data?.name})`
                        : data?.phone}
                    </Button>
                    <br />
                  </>
                );
              })}
              {/* <br />
                {
                  record?.phones[0].name ? (
                    <Tag className="text-primary border-primary text-14">{record?.phones[0].name}</Tag>
                  ) : (
                    <Button onClick={(e) => checkPhone(record, index)}
                      className="text-white bg-primary border-primary text-12 mt-1"
                      icon={<SearchOutlined />} size="small"
                      loading={loadingButton[index]}>
                      Zalo
                    </Button>
                  )
                } */}
            </>
          ) : null}
        </>
      ),
    },
    {
      title: (
        <div>
          <CommentOutlined />
          <span className="font-500 ms-1">Bình luận</span>
        </div>
      ),
      dataIndex: "comment",
      key: "comment",
      align: "center",
      fixed: false,
      with: "5%",
      render: (text, record, index) => (
        <>
          {record.message.length > 40 ? (
            <>
              {expandedRows[index] ? (
                <>
                  <span>{record.message + "  "}</span>
                  <a
                    className="text-blue text-decoration-none"
                    onClick={() => toggleRow(index)}
                  >
                    Ẩn bớt
                  </a>
                </>
              ) : (
                <>
                  {record.message.slice(0, 40) + "..."}
                  <a
                    className="text-blue text-decoration-none"
                    onClick={() => toggleRow(index)}
                  >
                    Xem thêm
                  </a>
                </>
              )}
            </>
          ) : (
            record.message
          )}
          {/* {
            record.showAll ? (
              <span>
                {record.message}
              </span>
            ) : (
              <>
                <span>
                  {record.message.slice(0, 10) + '...'}
                </span>
                <a className="text-blue text-decoration-none" onClick={(e) => record.showAll = true}>Xem thêm</a>
              </>
            )
          } */}
        </>
      ),
    },
    // {
    //   title: 'Tên giao hàng',
    //   dataIndex: "comment",
    //   key: "tengiaohang",
    //   align: "center",
    //   fixed: false,
    //   with: '5%',
    //   render: (text, record) => (
    //     <>
    //       {record?.phones?.length ? (
    //         <span>{record?.phones[0].nameShipping ? record?.phones[0].nameShipping : ''}</span>
    //       ) : null}
    //     </>
    //   ),
    // },
    // {
    //   title: 'Tỉ lệ giao',
    //   dataIndex: "comment",
    //   key: "tilegiao",
    //   align: "center",
    //   fixed: false,
    //   with: '5%',
    //   render: (text, record) => (
    //     <>
    //       {record?.phones?.length ? (
    //         <span>{record?.phones[0].percentShipping ? record?.phones[0].percentShipping : ''}</span>
    //       ) : null}
    //     </>
    //   ),
    // }
  ];

  const handleShowModal = (record) => {
    setRecordSelected(record);
    setShowModalSendMessage(true);
  };

  const onCancel = () => {
    setShowModalSendMessage(false);
  };

  const exportData = async () => {
    setLoadingExport(true);
    try {
      const response = await $axios.get(
        `/comment/export?fromTime=${convertStringToDate(
          filter.fromTime
        )}&toTime=${convertStringToDate(filter.toTime)}`,
        {
          responseType: "arraybuffer", // Specify the response type as arraybuffer
        }
      );

      // Convert array buffer to Blob
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Create a temporary URL for the blob
      const url = window.URL.createObjectURL(blob);

      // Create a link element to trigger the download
      const a = document.createElement("a");
      a.href = url;

      // Set the desired file name with .xlsx extension
      const nameFile = "CommentsExport.xlsx";
      a.download = nameFile;

      // Append the link to the body and trigger the download
      document.body.appendChild(a);
      a.click();

      // Remove the link from the body and revoke the URL to release resources
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      notification.error({
        message: "Thất bại",
        description: "Có lỗi xảy ra",
      });
    } finally {
      setLoadingExport(false);
    }
  };

  return (
    <div className="binhluan card">
      <Tabs type="card">
        <TabPane
          tab={
            <span>
              <CommentOutlined />
              Bình luận
            </span>
          }
        >
          <div className="content px-3 pb-3">
            <Row gutter={20}>
              <Col span={14} offset={0} className="d-flex">
                <Tag
                  className="text-14 border-primary bg-white text-primary py-1 h-100"
                  closable={false}
                  effect="plain"
                >
                  {totalPost} bình luận
                </Tag>
                <RangePicker
                  className="h-100"
                  // value={rangeTimeComment}
                  onChange={(dates, dateString) => {
                    setFilter({
                      ...filter,
                      fromTime: dateString[0],
                      toTime: dateString[1],
                    });
                  }}
                  separator="To"
                  format={DateFormat}
                  defaultValue={[
                    dayjs(filter.fromTime, DateFormat),
                    dayjs(filter.toTime, DateFormat),
                  ]}
                  placeholder={["Từ ngày", "Đến ngày"]}
                />
                {/* <Select
                  className="ms-2 rounded-4"
                  style={{ width: 120 }}
                  onChange={(value) => setCategoryId(value)}
                  value={category_id}
                  placeholder="Danh mục"
                >
                  {list_category.map((data, index) => (
                    <Option key={index} label={data.name} value={data.id} />
                  ))}
                </Select> */}
                {/* <Button
                  className="rounded-4 ms-2"
                  type="primary"
                  onClick={(e) => exportData()}
                  icon={<DownloadOutlined />}
                  loading={loadingExport}
                // disabled={!list_permission.includes("export")}
                >
                  Xuất file Excel
                </Button> */}
              </Col>
              {/* <Col span={7} offset={0} style={{ textAlign: "right" }}>
                <Switch
                  onChange={(e) => setFilter({ ...filter, onlyPhone: e })}
                  style={{ marginRight: "10px" }}
                />
                <span className="text-14">Chỉ hiển thị SĐT</span>
              </Col> */}
              <Col span={10} offset={0}>
                <Row className="align-items-center">
                  <Col span={7} offset={5}>
                    <Switch
                      onChange={(e) => setFilter({ ...filter, onlyPhone: e })}
                      style={{ marginRight: "10px" }}
                    />
                    <span className="text-14">Chỉ hiển thị SĐT</span>
                  </Col>
                  <Col span={12}>
                    <Input
                      // onPressEnter={GetComment}
                      onChange={(e) => setSearch(e.target.value)}
                      placeholder="Tìm kiếm từ khóa ..."
                      prefix={<SearchOutlined />}
                    // value={keysearchComment}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <div className="mt-4">
              <Table
                size="small"
                dataSource={list_comment}
                loading={loading}
                scroll={{
                  x: "max-content",
                  y: `calc(100vh - 350px)`,
                }}
                rowClassName={tableRowClassName}
                bordered
                pagination={false}
              >
                {columns.map((column) => (
                  <Table.Column
                    key={column.key}
                    title={column.title}
                    dataIndex={column.dataIndex}
                    align={column.align}
                    width={column.width}
                    fixed={column.fixed}
                    render={column.render}
                    sorter={column.sorter}
                  />
                ))}
              </Table>
              <Pagination
                className="mt-3"
                current={pager}
                total={totalPost}
                onChange={(page) => setPager(page)}
                pageSize={pageSize}
                showSizeChanger
                onShowSizeChange={onShowSizeChange}
              />
              <SendMessage
                props={{
                  sendMessageShow: showModalSendMessage,
                  onClose: onCancel,
                  record: recordSelected,
                }}
              />
            </div>
          </div>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Binhluan;
