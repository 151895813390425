import React from "react";
import { Routes, Route } from "react-router-dom";
import RouteWithLayout from "./router/Routes";
import Login from "./pages/Login";
import "./App.scss";
import { Navigate, useLocation } from "react-router-dom";

const App = () => {
  return (
    <Routes>
      <Route
        path="/*"
        element={
          <ProtectedRoute>
            <RouteWithLayout />
          </ProtectedRoute>
        }
      />
      <Route path="/login" element={<Login />} />
    </Routes>
  );
};

const ProtectedRoute = ({ children }) => {
  const accessToken = localStorage.getItem("accessToken");
  let location = useLocation();
  if (!accessToken) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return children;
};

export default App;
