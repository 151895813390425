import React from "react";
import {  Modal, InputNumber, DatePicker } from "antd";
import dayjs from "dayjs";

const ModalEdit = (props) => {
  const {
    titleModalEdit,
    openModalEdit,
    setOpenModalEdit,
    handleUpdateUser,
    typeEdit,
    valueEdit,
    setValueEdit,
    format,
  } = props;
  return (
    <Modal
      title={titleModalEdit}
      open={openModalEdit}
      onCancel={() => {
        setOpenModalEdit(false);
      }}
      onOk={handleUpdateUser}
      centered={true}
      fullscreen={false}
    >
      {typeEdit === "limitTime" ? (
        <div className="mt-3">
          <span style={{ display: "block" }}>Chọn ngày</span>
          <DatePicker
            value={dayjs(valueEdit)}
            className="w-100"
            onChange={(value, dateString) => {
              setValueEdit(dayjs(value).format(format));
            }}
          />
        </div>
      ) : (
        <div className="mt-3">
          <span>Nhập số lượng</span>
          <InputNumber
            className="mt-2"
            size="middle"
            min={0}
            style={{
              width: "100%",
            }}
            value={valueEdit}
            onChange={(e) => {
              setValueEdit(e);
            }}
          />
        </div>
      )}
    </Modal>
  );
};

export default ModalEdit;
