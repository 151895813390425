import React, { useState, useEffect } from "react";
import {
  Input,
  Button,
  Popconfirm,
  Tag,
  Tabs,
  Table,
  Pagination,
  notification,
  Form,
  Space,
  message,
  Select,
} from "antd";
import {
  DeleteOutlined,
  PlusOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import $axios from "../../services/axios";
import ModalDetailToken from "./component/token/ModalDetailToken";

const { TabPane } = Tabs;

const YourComponent = () => {
  const [cookieToken, setCookieToken] = useState("");
  const [loadingAddToken, setLoadingAddToken] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [loadingReTokenAll, setLoadingReTokenAll] = useState(false);
  const [loadingCheckTokenAll, setLoadingCheckTokenAll] = useState(false);
  const [loadingCheckCookieAll, setLoadingCheckCookieAll] = useState(false);
  const [pager, setPager] = useState(1);
  const [countTotal, setCountTotal] = useState(0);
  const [countToken, setCountToken] = useState(0);
  const [countCookie, setCountCookie] = useState(0);
  const [listToken, setListToken] = useState([]);
  const [openDetailTokenModal, setOpenDetailTokenModal] = useState(false);
  const [pageSize, setPageSize] = useState(20);
  const [useFor, setUseFor] = useState('Comment');
  const [formToken] = Form.useForm();
  useEffect(() => {
    LoadToken();
  }, [pager, pageSize]);

  const LoadToken = async () => {
    setLoadingData(true);
    try {
      const res = await $axios.get(
        `/admin/token/list?page=${pager}&perPage=${pageSize}`
      );
      setListToken(res.data.data.map(i => {
        i.loadingCheckCookie = false;
        i.loadingCheckToken = false;
        i.loadingReToken = false;
        return i;
      }));
      setCountTotal(res.data._metadata.pagination.total);
      const res2 = await $axios.get(`/admin/token/statistic`);
      setCountCookie(res2.data.data.cookie);
      setCountToken(res2.data.data.token);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingData(false);
    }
  };

   const LoadTokenByStatus = async (queryParams) => {
     setLoadingData(true);
     try {
       const res = await $axios.get(`/admin/token/list?page=1&perPage=10&${queryParams}`);
       setListToken(
         res.data.data.map((i) => {
           i.loadingCheckCookie = false;
           i.loadingCheckToken = false;
           i.loadingReToken = false;
           return i;
         })
       );
       setCountTotal(res.data._metadata.pagination.total);
       const res2 = await $axios.get(`/admin/token/statistic`);
       setCountCookie(res2.data.data.cookie);
       setCountToken(res2.data.data.token);
     } catch (error) {
       console.error(error);
     } finally {
       setLoadingData(false);
     }
   };

  const AddToken = async () => {
    setLoadingAddToken(true);
    const cookies = cookieToken.split("\n");

    cookies.forEach(async (cookie) => {
      const token = cookie.split("|");
      try {
        let data = await $axios.post("/admin/token/create", {
          cookie: token[0],
          code2fa: token[1],
          proxy: token[2],
          useFor: useFor
        });
      } catch (error) {
        notification.error({
          message: "Thất bại",
          description: "Có lỗi xảy ra, vui lòng nhập token hợp lệ",
        });
      } finally {
        setCookieToken("");
        LoadToken();
        setLoadingAddToken(false);
      }
    });
  };

  const handleReToken = async (record) => {
    const index = listToken.findIndex((item) => item._id === record._id);
    const updatedListToken = [...listToken];
    updatedListToken[index].loadingReToken = true;
    setListToken(updatedListToken);
    try {
      await $axios.get(`/admin/token/re-token/${record._id}`);
      message.success("Thao tác thành công!");
    } catch (error) {
      message.success("Thao tác thất bại!");
    } finally {
      updatedListToken[index].loadingReToken = false;
      setListToken([...updatedListToken]);
      await LoadToken();
    }
  };

  const handleCheckCookie = async (record) => {
    const index = listToken.findIndex((item) => item._id === record._id);
    const updatedListToken = [...listToken];
    updatedListToken[index].loadingCheckCookie = true;
    setListToken(updatedListToken);
    try {
      await $axios.get(`/admin/token/check-cookie/${record._id}`);
      message.success("Thao tác thành công!");
    } catch (error) {
      message.success("Thao tác thất bại!");
    } finally {
      updatedListToken[index].loadingCheckCookie = false;
      setListToken([...updatedListToken]);
      await LoadToken();
    }
  };

  const handleCheckToken = async (record) => {
    const index = listToken.findIndex((item) => item._id === record._id); 
     const updatedListToken = [...listToken];
     updatedListToken[index].loadingCheckToken = true;
      setListToken(updatedListToken);
      try {
        await $axios.get(`/admin/token/check-token/${record._id}`);
        message.success("Thao tác thành công!");
      } catch (error) {
        message.success("Thao tác thất bại!");
      } finally {
        updatedListToken[index].loadingCheckToken = false;
        setListToken([...updatedListToken]);
        await LoadToken();
      }
  };

  const handleCheckCookieAll = async () => {
    setLoadingCheckCookieAll(true)
    try {
      await $axios.get(`/admin/token/check-cookie-all`);
      message.success("Thao tác thành công!");
    } catch (error) {
      message.success("Thao tác thất bại!");
    } finally {
      setLoadingCheckCookieAll(false);
      await LoadToken();
    }
  };

  const handleCheckTokenAll = async () => {
    setLoadingCheckTokenAll(true)
    try {
      await $axios.get(`/admin/token/check-token-all`);
      message.success("Thao tác thành công!");
    } catch (error) {
      message.success("Thao tác thất bại!");
    } finally {
      setLoadingCheckTokenAll(false);
      await LoadToken();
    }
  };

  const handleRetokenAll = async () => {
    setLoadingReTokenAll(true);
    try {
      await $axios.get(`/admin/token/re-token-all`);
      message.success("Thao tác thành công!");
    } catch (error) {
      message.success("Thao tác thất bại!");
    } finally {
      setLoadingReTokenAll(false);
      await LoadToken();
    }
  };

  const onShowSizeChange = (current, pageSize) => {
    setPageSize(pageSize);
  };
  const RemoveToken = async (_id) => {
    try {
      let data = await $axios.delete(`admin/token/delete/${_id}`);
      await LoadToken();
    } catch (error) {
      notification.error({
        message: "Thất bại",
        description: "Có lỗi xảy ra",
      });
    }
  };

  const handleGetDetailToken = async (record) => {
    try {
      const res = await $axios.get(`admin/token/get/${record._id}`);
      formToken.setFieldsValue({
        name: res.data.data.name,
        cookie: res.data.data.cookie,
        token: res.data.data.token,
      });
      setTimeout(() => {
        setOpenDetailTokenModal(true);
      }, 0);
    } catch (error) {
      notification.error({
        message: "Thất bại",
        description: "Có lỗi xảy ra",
      });
    }
  };

  const filterType = (type, status) => {
    LoadTokenByStatus(`${type}=${status}`);
  };

  const handleCopy = (fieldToCopy) => {
    navigator.clipboard
      .writeText(formToken.getFieldValue(fieldToCopy))
      .then(() => {
        message.success("Text copied to clipboard!");
      })
      .catch(() => {
        message.error("Failed to copy text.");
      });
  };

  return (
    <div className="card">
      <Tabs className="px-3 pb-3" type="card" tabBarExtraContent={{ right: <div /> }}>
        <TabPane
          tab={
            <span>
              <i className="el-icon-user-solid"></i> Cookies
            </span>
          }
          key="b"
        >
          <Input.TextArea
            spellCheck={false}
            rows={5}
            placeholder="Nhập danh sách cookie, token..."
            value={cookieToken}
            onChange={(e) => setCookieToken(e.target.value)}
          />
          <br />
          <div className="d-flex  align-items-center my-2">
            <label htmlFor="" className="mr-4">
              Use For
            </label>
            <Select
              style={{ width: 130 }}
              className="ms-4"
              defaultValue={useFor}
              onChange={(value) => setUseFor(value)}
              options={[
                {
                  value: "Comment",
                  label: "Comment",
                },
                {
                  value: "Reaction",
                  label: "Reaction",
                },
              ]}
            />
          </div>
          <div className="d-flex align-items-center justify-content-between my-2">
            <div>
              <Button
                type="primary"
                size="mini"
                onClick={AddToken}
                icon={<PlusOutlined />}
                style={{ fontSize: 12 }}
                loading={loadingAddToken}
              >
                Confirm
              </Button>
            </div>
            <div>
              <Space className="mb-2">
                <div style={{ width: "80px" }}>Token</div>
                <Tag
                  onClick={() => {
                    filterType("StatusToken", "Live");
                  }}
                  color={"green"}
                  width={70}
                  icon={<CheckOutlined />}
                  style={{ cursor: "pointer", width: "80px" }}
                >
                  {countToken?.live || 0} Live
                </Tag>
                <Tag
                  onClick={() => {
                    filterType("StatusToken", "Die");
                  }}
                  color={"red"}
                  width={70}
                  icon={<CloseOutlined />}
                  style={{ cursor: "pointer", width: "80px" }}
                >
                  {countToken?.die || 0} Die
                </Tag>
              </Space>
              <br />
              <Space>
                <div style={{ width: "80px" }}>Cookie</div>
                <Tag
                  onClick={() => {
                    filterType("StatusCookie", "Live");
                  }}
                  color={"green"}
                  icon={<CheckOutlined />}
                  style={{ cursor: "pointer", width: "80px" }}
                >
                  {countCookie?.live || 0} Live
                </Tag>
                <Tag
                  onClick={() => {
                    filterType("StatusCookie", "Die");
                  }}
                  color={"red"}
                  icon={<CloseOutlined />}
                  style={{ cursor: "pointer", width: "80px" }}
                >
                  {countCookie?.die || 0} Die
                </Tag>
              </Space>
            </div>
          </div>
          <Space className="mb-4">
            <Button type="primary" loading={loadingCheckCookieAll} onClick={() => handleCheckCookieAll()}>
              Check cookie all
            </Button>
            <Button type="primary" loading={loadingCheckTokenAll} onClick={() => handleCheckTokenAll()}>
              Check token all
            </Button>
            <Button type="primary" loading={loadingReTokenAll} onClick={() => handleRetokenAll()}>
              Re token all
            </Button>
          </Space>
          <Table
            dataSource={listToken}
            pagination={false}
            loading={loadingData}
            rowKey="_id"
            scroll={{
              x: "max-content",
              y: `calc(100vh - 600px)`,
            }}
          >
            <Table.Column
              title="UID"
              dataIndex="uid"
              key="uid"
              align="center"
              fixed="left"
              width={100}
              render={(text, record) => (
                <Tag
                  onClick={() => {
                    handleGetDetailToken(record);
                  }}
                  type="primary"
                  closable={false}
                  effect="plain"
                  size="large"
                  style={{ cursor: "pointer" }}
                  color="orange"
                >
                  {text}
                </Tag>
              )}
            />
            <Table.Column title="Name" dataIndex="name" key="name" align="center" width={100} />

            <Table.Column
              title="Status Token"
              dataIndex="statusToken"
              key="statusToken"
              align="center"
              width={100}
              render={(text) => <Tag color={text === "Live" ? "green" : "red"}>{text}</Tag>}
            />

            <Table.Column
              title="Status Cookie"
              dataIndex="statusCookie"
              key="statusCookie"
              align="center"
              width={100}
              render={(text) => <Tag color={text === "Live" ? "green" : "red"}>{text}</Tag>}
            />

            <Table.Column
              title="Use For"
              dataIndex="useFor"
              key="statusCookie"
              align="center"
              width={100}
              render={(text) => <Tag color={text === "Live" ? "green" : "red"}>{text}</Tag>}
            />

            <Table.Column
              title="Action"
              align="center"
              width={200}
              render={(value, record) => (
                <Space>
                  <Popconfirm title="Bạn có chắc chắn muốn xoá Token này?" onConfirm={() => RemoveToken(record._id)}>
                    <Tag style={{ margin: 0, cursor: "pointer" }} color="red" icon={<DeleteOutlined />}></Tag>
                  </Popconfirm>
                  <Button
                    loading={record?.loadingCheckToken || false}
                    type="primary"
                    onClick={() => handleCheckToken(record)}
                  >
                    Check token
                  </Button>
                  <Button
                    loading={record?.loadingCheckCookie || false}
                    type="primary"
                    onClick={() => handleCheckCookie(record)}
                  >
                    Check cookie
                  </Button>
                  <Button
                    loading={record?.loadingReToken || false}
                    type="primary"
                    onClick={() => handleReToken(record)}
                  >
                    Re-Token
                  </Button>
                </Space>
              )}
            />
          </Table>
          <br />
          <Pagination
            current={pager}
            total={countTotal}
            onChange={(page) => setPager(page)}
            pageSize={pageSize}
            pageSizeOptions={[20, 50, 100]}
            showSizeChanger
            onShowSizeChange={onShowSizeChange}
          />
        </TabPane>
      </Tabs>
      <ModalDetailToken
        openDetailTokenModal={openDetailTokenModal}
        setOpenDetailTokenModal={setOpenDetailTokenModal}
        formToken={formToken}
        handleCopy={handleCopy}
      />
    </div>
  );
};

export default YourComponent;
