import React, { useEffect, useState } from "react";
import { Table, Button, Popconfirm, Form, notification, Pagination, Space, Tabs, Switch } from "antd";
import { InfoCircleOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import "./user.scss";
import $axios from "../../services/axios";
import dayjs from "dayjs";
import moment from "moment";
import ModalUpdateUser from "./component/user/ModalUpdateUser";
import ModalCreateUser from "./component/user/ModalCreateUser";
import ModalEdit from "./component/user/ModalEdit";
const { TabPane } = Tabs;

const { Column } = Table;

const User = () => {
  const format = "YYYY-MM-DDTHH:mm:ss.SSS[Z]"; // ISO format
  const [openModalUser, setOpenModalUser] = useState(false);
  const [updateUserShow, setUpdateUserShow] = useState(false);
  const [accountList, setAccountList] = useState([]);
  const [initialUser, setInitialUser] = useState({
    phone: "",
    email: "",
    id: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const [pager, setPager] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [totalPost, setTotalPost] = useState(0);
  const [titleModalEdit, setTitleModalEdit] = useState("");
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [valueEdit, setValueEdit] = useState(null);
  const [typeEdit, setTypeEdit] = useState("");
  const [idEdit, setIdEdit] = useState("");

  const handleUpdateUser = async () => {
    switch (typeEdit) {
      case "limitPost":
        const res1 = await $axios.$patch(`admin/user/update-limit-post/${idEdit}`, {
          limitPost: valueEdit,
        });
        if (res1.statusCode === 200) {
          success("Cập nhật thành công");
        }
        break;

      case "liveReaction":
        const res2 = await $axios.$patch(`admin/user/update-limit-live-reaction/${idEdit}`, {
          limitLiveReaction: valueEdit,
        });
        if (res2.statusCode === 200) {
          success("Cập nhật thành công");
        }
        break;

      case "liveComment":
        const res3 = await $axios.$patch(`admin/user/update-limit-live-comment/${idEdit}`, {
          limitLiveComment: valueEdit,
        });
        if (res3.statusCode === 200) {
          success("Cập nhật thành công");
        }
        break;

      case "limitTime":
        const res4 = await $axios.$patch(`admin/user/update-limit-time/${idEdit}`, {
          limitTime: valueEdit,
        });
        if (res4.statusCode === 200) {
          success("Cập nhật thành công");
        }
        break;

      case "limitAutoZaloReaction":
        const res5 = await $axios.$patch(`admin/user/update-limit-auto-zalo-reaction/${idEdit}`, {
          limitAutoZaloReaction: valueEdit,
        });
        if (res5.statusCode === 200) {
          success("Cập nhật thành công");
        }
        break;

      case "limitAutoZaloComment":
        const res6 = await $axios.$patch(`admin/user/update-limit-auto-zalo-comment/${idEdit}`, {
          limitAutoZaloComment: valueEdit,
        });
        if (res6.statusCode === 200) {
          success("Cập nhật thành công");
        }
        break;
      default:
        break;
    }
    setTypeEdit("");
    setValueEdit(null);
    setOpenModalEdit(false);
    await getUser();
  };

  useEffect(() => {
    getUser();
  }, [pager, pageSize]);

  const handleGetInitialUser = async (_id) => {
    const res = await $axios.get(`admin/user/get/${_id}`);
    formUpdateUser.setFieldsValue({
      // phone: '0942442796', //fix cứng
      fullName: res.data.data.fullName,
      password: "",
      email: res.data.data.email,
      limitPost: res.data.data.limitPost,
      liveComment: res.data.data.liveComment,
      liveReaction: res.data.data.liveReaction,
    });
    setInitialUser(res.data.data);
    setUpdateUserShow(true);
    // setTimeout(() => {
    //   setUpdateUserShow(true);
    // }, 0);
  };

  const getUser = async () => {
    try {
      setLoading(true);
      const result = await $axios.get(`admin/user/list?page=${pager}&perPage=${pageSize}`);
      setAccountList(result.data.data);
      setTotalPost(result.data._metadata.pagination.total);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleRemoveUser = async (user) => {
    const res = await $axios.delete(`/admin/user/delete/${user._id}`);
    if (res && res.data.statusCode === 200) {
      success("Xoá thành công");
      getUser();
      setPager(1);
    }
  };

  const getRowClassName = (record) => {
    return record.role == "Admin" ? "row-disable" : "";
  };

  const success = (string) => {
    notification.success({
      message: "Thành công",
      description: string,
    });
  };
  const [form] = Form.useForm();
  const [formUpdateUser] = Form.useForm();

  const handleCreate = async () => {
    form
      .validateFields()
      .then(async (values) => {
        const body = {
          ...values,
          role: "User",
        };
        body.limitTime = dayjs(values.limitTime).format(format);
        const insert = await $axios.post("/admin/user/create", body);
        getUser();
        success("Tạo user thành công");
        form.resetFields();
        setOpenModalUser(false);
      })
      .catch((errorInfo) => {
        notification.error({
          message: "Thất bại",
          description: errorInfo.errorFields
            ? "Lỗi dữ liệu đầu vào"
            : errorInfo.response.data.message || "Thêm người dùng thất bại, vui lòng kiểm tra lại",
        });
      });
  };

  const onCancel = () => {
    form.resetFields();
    setOpenModalUser(false);
  };

  const renderDay = (time) => {
    const diffDay = moment(time).diff(moment(), "days");
    return diffDay < 0 ? "Hết hạn" : diffDay;
  };

  const updateProfileUser = async (values) => {
    const { fullName, phone, password, email } = values;

    try {
      if (fullName && fullName !== initialUser.fullName) {
        const res = await $axios.$patch(`admin/user/update-name/${initialUser._id}`, {
          fullName: values.fullName,
        });
        if (res.statusCode === 200) {
          success("Cập nhật thành công");
        }
      }

      if (password) {
        const res = await $axios.$patch(`admin/user/reset-password/${initialUser._id}`, {
          password: password,
        });
        if (res.statusCode === 200) {
          success("Cập nhật thành công");
        }
      }

      if (email && email !== initialUser.email) {
        const res = await $axios.$patch(`admin/user/update-email/${initialUser._id}`, {
          email: values.email,
        });
        if (res.statusCode === 200) {
          success("Cập nhật thành công");
        }
      }
    } catch (error) {
      notification.error({
        message: "Thất bại",
        description: "Có lỗi xảy ra",
      });
    } finally {
      setUpdateUserShow(false);
      getUser();
    }
  };

  const onShowSizeChange = (current, pageSize) => {
    setPageSize(pageSize);
  };

  return (
    <div className="card">
      <Tabs className="px-3 pb-3" type="card" tabBarExtraContent={{ right: <div /> }}>
        <TabPane
          tab={
            <span>
              <i className="el-icon-user-solid"></i> Users
            </span>
          }
          key="b"
        >
          <div>
            <div className="text-end">
              <Button
                type="primary"
                className="text-12"
                onClick={(e) => setOpenModalUser(true)}
                loading={false}
                disabled={false}
              >
                Tạo tài khoản
              </Button>
            </div>
            <div className="mt-4">
              <Table
                dataSource={accountList}
                bordered
                loading={loading}
                rowKey="_id"
                rowClassName={getRowClassName}
                pagination={false}
                scroll={{
                  x: "max-content",
                  y: `calc(100vh - 450px)`,
                }}
              >
                <Column title="Email" dataIndex="email" width="20%" key="email" align="center" />
                <Column title="Username" dataIndex="username" width="20%" key="username" align="center" />

                <Column
                  title="Limit Post"
                  width="10%"
                  dataIndex="phone"
                  key="phone"
                  align="center"
                  render={(text, record) => (
                    <Button
                      onClick={(e) => {
                        setTypeEdit("limitPost");
                        setIdEdit(record._id);
                        setTitleModalEdit("Limit Post");
                        setValueEdit(record.limitPost || 0);
                        setTimeout(() => {
                          setOpenModalEdit(true);
                        }, 0);
                      }}
                      className="text-12 font-300"
                      type="primary"
                    >
                      {record.limitPost}
                    </Button>
                  )}
                />

                <Column
                  title="Live Reaction"
                  align="center"
                  render={(text, record) => (
                    <Button
                      onClick={(e) => {
                        setTypeEdit("liveReaction");
                        setIdEdit(record._id);
                        setTitleModalEdit("Live Reaction");
                        setValueEdit(record.limitLiveReaction || 0);
                        setTimeout(() => {
                          setOpenModalEdit(true);
                        }, 500);
                      }}
                      className="text-12 font-300"
                      type="primary"
                    >
                      {record.limitLiveReaction || 0}
                    </Button>
                  )}
                />

                <Column
                  title="Live Comment"
                  align="center"
                  render={(text, record) => (
                    <Button
                      onClick={(e) => {
                        setTypeEdit("liveComment");
                        setIdEdit(record._id);
                        setTitleModalEdit("Live Comment");
                        setValueEdit(record.limitLiveComment || 0);
                        setTimeout(() => {
                          setOpenModalEdit(true);
                        }, 500);
                      }}
                      className="text-12 font-300"
                      type="primary"
                    >
                      {record.limitLiveComment || 0}
                    </Button>
                  )}
                />

                <Column
                  title="Limit time"
                  dataIndex="phone"
                  key="phone"
                  align="center"
                  render={(text, record) => (
                    <Button
                      onClick={(e) => {
                        setTypeEdit("limitTime");
                        setTitleModalEdit("Limit Time");
                        setValueEdit(record.limitTime);
                        setIdEdit(record._id);
                        setTimeout(() => {
                          setOpenModalEdit(true);
                        }, 500);
                      }}
                      className="text-12 font-300"
                      type="primary"
                    >
                      {renderDay(record.limitTime)}
                    </Button>
                  )}
                />

                <Column
                  title="Limit Auto Zalo Comment"
                  align="center"
                  render={(text, record) => (
                    <Button
                      onClick={(e) => {
                        setTypeEdit("limitAutoZaloComment");
                        setIdEdit(record._id);
                        setTitleModalEdit("Limit Auto Zalo Comment");
                        setValueEdit(record.limitAutoZaloComment || 0);
                        setTimeout(() => {
                          setOpenModalEdit(true);
                        }, 500);
                      }}
                      className="text-12 font-300"
                      type="primary"
                    >
                      {record.limitAutoZaloComment || 0}
                    </Button>
                  )}
                />

                <Column
                  title="Limit Auto Zalo Reaction"
                  align="center"
                  render={(text, record) => (
                    <Button
                      onClick={(e) => {
                        setTypeEdit("limitAutoZaloReaction");
                        setIdEdit(record._id);
                        setTitleModalEdit("Limit Auto Zalo Reaction");
                        setValueEdit(record.limitAutoZaloReaction || 0);
                        setTimeout(() => {
                          setOpenModalEdit(true);
                        }, 500);
                      }}
                      className="text-12 font-300"
                      type="primary"
                    >
                      {record.limitAutoZaloReaction || 0}
                    </Button>
                  )}
                />

                <Column
                  title="Auto Zalo"
                  align="center"
                  render={(text, record) => (
                    <Switch
                      className="custom-switch"
                      checked={record.autoZalo}
                      onChange={async (value) => {
                        try {
                          await $axios.$patch(`/admin/user/update-auto-zalo/${record._id}`, { autoZalo: value });
                          notification.success({
                            description: "Đổi trạng thái thành công!",
                          });
                        } catch (error) {
                          notification.error({
                            description: "Đổi trạng thái thất bại!",
                          });
                        } finally {
                          getUser();
                        }
                      }}
                    />
                  )}
                />

                <Column
                  title=""
                  align="center"
                  render={(text, record) => (
                    <Space>
                      <Popconfirm
                        disabled={record.role == "Admin"}
                        title="Bạn có chắc chắn?"
                        className="text-12"
                        onConfirm={() => handleRemoveUser(record)}
                        okText="OK"
                        cancelText="Không, cảm ơn."
                        icon={<InfoCircleOutlined style={{ color: "red" }} />}
                      >
                        <Button type="primary" danger icon={<DeleteOutlined />} />
                      </Popconfirm>
                      <Button
                        type="primary"
                        onClick={() => {
                          handleGetInitialUser(record._id);
                        }}
                        disabled={record.role == "Admin"}
                        style={{ color: "white" }}
                        danger
                        icon={<EditOutlined />}
                      />
                    </Space>
                  )}
                />
              </Table>
              <Pagination
                className="mt-3"
                current={pager}
                total={totalPost}
                onChange={(page) => setPager(page)}
                pageSize={pageSize}
                showSizeChanger
                onShowSizeChange={onShowSizeChange}
                pageSizeOptions={[20, 50, 100]}
              />
            </div>
            <div>
              <ModalUpdateUser
                updateUserShow={updateUserShow}
                setUpdateUserShow={setUpdateUserShow}
                formUpdateUser={formUpdateUser}
                updateProfileUser={updateProfileUser}
              />

              <ModalCreateUser
                openModalUser={openModalUser}
                onCancel={onCancel}
                handleCreate={handleCreate}
                form={form}
              />

              <ModalEdit
                titleModalEdit={titleModalEdit}
                openModalEdit={openModalEdit}
                setOpenModalEdit={setOpenModalEdit}
                handleUpdateUser={handleUpdateUser}
                typeEdit={typeEdit}
                valueEdit={valueEdit}
                setValueEdit={setValueEdit}
                format={format}
              />
            </div>
          </div>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default User;
