import axios from "axios";
// import { notification } from "antd";
import _ from 'lodash'

const axiosExtra = {
  setHeader(name, value, scopes = "common") {
    for (let scope of Array.isArray(scopes) ? scopes : [scopes]) {
      if (!value) {
        delete this.defaults.headers[scope][name];
        return;
      }
      this.defaults.headers[scope][name] = value;
    }
  },
  setToken(token, type, scopes = "common") {
    const value = !token ? null : (type ? type + " " : "") + token;
    this.setHeader("Authorization", value, scopes);
  },
  onRequest(fn) {
    this.interceptors.request.use(
      (config) => fn(config) || config,
      (error) => Promise.reject(error)
    );
  },
  onResponse(fn) {
    this.interceptors.response.use(
      (response) => fn(response) || response,
      (error) => Promise.reject(error)
    );
  },
  onError(fn) {
    this.onRequest((config) => {
      this.onResponse((response) => {
        fn(response);
        return response;
      });
      return config;
    });
  },
  $request() {
    return this.request.apply(this, arguments).then((res) => res && res.data);
  },
  $delete() {
    return this.delete.apply(this, arguments).then((res) => res && res.data);
  },
  $head() {
    return this.head.apply(this, arguments).then((res) => res && res.data);
  },
  $options() {
    return this.options.apply(this, arguments).then((res) => res && res.data);
  },
  $get() {
    return this.get.apply(this, arguments).then((res) => res && res.data);
  },

  $post() {
    return this.post.apply(this, arguments).then((res) => res && res.data);
  },

  $put() {
    return this.put.apply(this, arguments).then((res) => res && res.data);
  },

  $patch() {
    return this.patch.apply(this, arguments).then((res) => res && res.data);
  },

  $uploadFile(file, customHeaders = {}) {
    const formData = new FormData();
    formData.append("sampleFile", file);

    const headers = {
      ...this.defaults.headers.common,
      ...this.defaults.headers.post,
      ...customHeaders,
    };

    return this.post("/post/upload-post", formData, { headers });
  },
};

const extendAxiosInstance = (axios) => {
  for (let key in axiosExtra) {
    axios[key] = axiosExtra[key].bind(axios);
  }

  axios.$uploadFile = axiosExtra.$uploadFile.bind(axios);

  axios.interceptors.request.use(
    (config) => {
      const accessToken = localStorage.getItem("accessToken");

      if (accessToken) {
        config.headers.Authorization = `Bearer ` + _.replace(accessToken, /"/g, "");
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      
      if (error.response.status === 401) {
        localStorage.removeItem('accessToken');
        window.location.href = '/login';
      }

      return Promise.reject(error);
    }
  );
};

const $axios = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL || "http://localhost:8080",
  headers: {
    common: {
      Accept: "application/json, text/plain, */*",
    },
    delete: {},
    get: {},
    head: {},
    post: {},
    put: {},
    patch: {},
  },
});

extendAxiosInstance($axios);

export default $axios;
