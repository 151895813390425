import React, { useState } from "react";
import { Row, Col, Form, Input, Button, Flex, notification } from "antd";
import logo from "../assets/logo.jpg";
import $axios from "../services/axios";
import { useNavigate } from "react-router-dom";
import _ from 'lodash'

import "../styles/login.scss";

const Login = () => {
  const [loginStatus, setLoginStatus] = useState(false);
  const [loginForm, setLoginForm] = useState({ phone: "", password: "" });
  const navigate = useNavigate();

  const onFinish = async (values) => {
    setLoginStatus(true);
    try {
      const res = await $axios.post("user/login", {
        username: values.phone,
        password: values.password,
      });

      if (res.data.statusCode !== 200) {
        setLoginStatus(false);
        notification.error({
          message: "Có lỗi xảy ra",
          description: "Đăng nhập thất bại",
        });
      } else {
        localStorage.setItem("accessToken", JSON.stringify(res.data.data.accessToken));
        const userInfo = await $axios.get("user/profile");
        localStorage.setItem("user_info", JSON.stringify(userInfo.data.data));
        navigate("/dashboard/thong-ke");
      }
    } catch (error) {
      setLoginStatus(false);
      notification.error({
        message: "Có lỗi xảy ra",
        description: "Đăng nhập thất bại",
      });
    }
  };

  const onFinishFailed = (errorInfo) => {
    setLoginStatus(false);
    notification.error({
      message: "Có lỗi xảy ra",
      description: "Đăng nhập thất bại",
    });
  };

  const openLink = function (path) {
    window.open("/" + path, "_self");
  };

  return (
    <section>
      <header className="header px-4" style={{ height: "60px" }}>
        <Flex gap="middle" align="center" justify="space-between">
          <img src={logo} alt="" style={{ width: "190px", height: '59px' }} />
          <Button
            className="text-12 bg-primary border-primary text-white ms-auto"
            onClick={() => openLink("login")}
            loading={false}
            htmlType="submit"
          >
            Đăng nhập
          </Button>
        </Flex>
      </header>
      <Row justify="center" align="middle">
        <Col span={6} style={{ marginTop: "60px" }}>
          <div className="transition-box">
            <Row justify="center">
              <p>ĐĂNG NHẬP</p>
            </Row>
            <Form
              name="login"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              layout="vertical"
            >
              <Form.Item
                label="Số điện thoại"
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Quý khách được yêu cầu nhập vào ô trống.",
                  },
                ]}
              >
                <Input
                  placeholder="Nhập số điện thoại"
                  value={loginForm.phone}
                  onChange={(e) =>
                    setLoginForm({ ...loginForm, phone: e.target.value })
                  }
                />
              </Form.Item>

              <Form.Item
                label="Mật khẩu"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Quý khách được yêu cầu nhập vào ô trống.",
                  },
                ]}
              >
                <Input.Password
                  placeholder="Password"
                  value={loginForm.password}
                  onChange={(e) =>
                    setLoginForm({ ...loginForm, password: e.target.value })
                  }
                />
              </Form.Item>

              <Form.Item style={{ textAlign: "center" }}>
                <Button type="primary" htmlType="submit" loading={loginStatus}>
                  Đăng nhập
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Col>
      </Row>
    </section>
  );
};

export default Login;
