import React from "react";
import {
  Input,
  Pagination,
  Button,
  Tag,
  Popconfirm,
  Table,
  Space,
  Modal,
} from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";

const ModalManageProxy = (props) => {
  const {
    openProxyModal,
    setOpenProxyModal,
    setProxyValue,
    proxyNameValue,
    setProxyNameValue,
    proxyValue,
    handleAddProxy,
    loadingAddProxy,
    listProxy,
    loadingTableProxy,
    setEditProxyId,
    setEditProxyName,
    pagerProxy,
    totalProxy,
    setPagerProxy,
    pageSizeProxy,
    onShowSizeProxyChange,
    editProxyName,
    setOpenEditProxyNameModal,
    openEditProxyNameModal,
    handleEditProxyName,
    handleRemoveProxy,
  } = props;
  return (
    <Modal
      title="Quản lý Proxy"
      open={openProxyModal}
      onCancel={() => {
        setOpenProxyModal(false);
        setProxyValue("");
      }}
      width={1200}
      centered={true}
      footer={null}
      fullscreen={false}
    >
      <Input
        spellCheck={false}
        placeholder="Nhập tên proxy"
        value={proxyNameValue}
        onChange={(e) => setProxyNameValue(e.target.value)}
        className="mb-2"
      />
      <Input
        spellCheck={false}
        placeholder="Nhập proxy"
        value={proxyValue}
        onChange={(e) => setProxyValue(e.target.value)}
      />
      <div className="d-flex align-items-center justify-content-between my-4">
        <div>
          <Button
            type="primary"
            size="mini"
            onClick={handleAddProxy}
            icon={<PlusOutlined />}
            style={{ fontSize: 12 }}
            loading={loadingAddProxy}
          >
            Tạo
          </Button>
        </div>
      </div>
      <br />
      <Table
        dataSource={listProxy}
        pagination={false}
        loading={loadingTableProxy}
        rowKey="_id"
        scroll={{
          x: "max-content",
          y: "40vh",
        }}
      >
        <Table.Column
          title="Name"
          dataIndex="name"
          key="name"
          align="center"
          fixed="left"
          render={(text, record) => (
            <Tag
              type="primary"
              closable={false}
              effect="plain"
              size="large"
              style={{ cursor: "pointer" }}
              color="orange"
              onClick={(e) => {
                setEditProxyId(record._id);
                setEditProxyName(text);
                setTimeout(() => {
                  setOpenEditProxyNameModal(true);
                }, 500);
              }}
            >
              {text || ""}
            </Tag>
          )}
        />
        <Table.Column title="Username" dataIndex="username" key="username" align="center" />
        <Table.Column title="User" dataIndex="user" key="user" align="center" />

        <Table.Column title="Port" dataIndex="port" key="port" align="center" />

        <Table.Column title="Host" dataIndex="host" key="host" align="center" />

        <Table.Column title="Password" dataIndex="password" key="password" align="center" />

        <Table.Column
          title="Action"
          align="center"
          render={(value, record) => (
            <Space>
              <Popconfirm title="Bạn có chắc chắn muốn xoá Proxy này?" onConfirm={() => handleRemoveProxy(record._id)}>
                <Tag style={{ margin: 0, cursor: "pointer" }} color="red" icon={<DeleteOutlined />}></Tag>
              </Popconfirm>
            </Space>
          )}
        />
      </Table>
      <Modal
        title="Sửa tên Proxy"
        open={openEditProxyNameModal}
        onCancel={() => {
          setOpenEditProxyNameModal(false);
          setEditProxyName("");
        }}
        centered={true}
        footer={null}
        fullscreen={false}
      >
        <div>Tên proxy</div>
        <Input
          spellCheck={false}
          placeholder="Nhập tên proxy"
          value={editProxyName}
          onChange={(e) => setEditProxyName(e.target.value)}
          className="mb-2"
        />
        <div className="d-flex align-item-center justify-content-center mt-4">
          <Button type="primary" size="mini" onClick={handleEditProxyName} style={{ fontSize: 12 }}>
            Sửa
          </Button>
        </div>
      </Modal>
      <Pagination
        current={pagerProxy}
        total={totalProxy}
        onChange={(page) => {
          setPagerProxy(page);
        }}
        pageSize={pageSizeProxy}
        showSizeChanger
        pageSizeOptions={[20, 50, 100]}
        onShowSizeChange={onShowSizeProxyChange}
        className="mt-4"
      />
    </Modal>
  );
};

export default ModalManageProxy;
