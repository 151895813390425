import React, { useState, useEffect } from "react";
import { Input, Tag, Table, notification } from "antd";
import $axios from "../../services/axios";
import useDebounce from "../../hooks/useDebounceValue";
const SettingModal = () => {
  const [zalo, setZalo] = useState([]);
  const [loading, setLoading] = useState(false);
  const [limitMessage, setLimitMessage] = useState(0);
  const [delayMessage, setDelayMessage] = useState(0);
  const [limitMessageDay, setLimitMessageDay] = useState(0);
  const [limitInvite, setLimitInvite] = useState(0);
  const [delayInvite, setDelayInvite] = useState(0);
  const [limitInviteDay, setLimitInviteDay] = useState(0);
  const [chosenId, setChosenId] = useState("");

  const debouceLimitMessage = useDebounce(limitMessage, 500);
  const debouceDelayMessage = useDebounce(delayMessage, 500);
  const debouceLimitMessageDay = useDebounce(limitMessageDay, 500);
  const debouceLimitInvite = useDebounce(limitInvite, 500);
  const debouceDelayInvite = useDebounce(delayInvite, 500);
  const debouceLimitInviteDay = useDebounce(limitInviteDay, 500);

  useEffect(() => {
    getZalo();
  }, []);

  const getZalo = async () => {
    setLoading(true);
    const data = await $axios.get("/zalo/list");
    setZalo(data.data.data);
    setLoading(false);
  };

  const editZaloLimit = async (id, value) => {
    if (!value) return;
    try {
      setLoading(true);
      let data = await $axios.patch(`zalo/change-limit-message/${id}`, {
        limit: +value,
      });

      notification.success({
        message: "Thành công",
        description: "Chỉnh sửa thành công",
      });
    } catch (error) {
      notification.error({
        message: "Thất bại",
        description: "Có lỗi xảy ra",
      });
    } finally {
      getZalo();
    }
  };

  const editLimitMessageDay = async (id, value) => {
    if (!value) return;
    try {
      setLoading(true);
      let data = await $axios.patch(`zalo/change-limit-message-per-day/${id}`, {
        limit: +value,
      });

      notification.success({
        message: "Thành công",
        description: "Chỉnh sửa thành công",
      });
    } catch (error) {
      notification.error({
        message: "Thất bại",
        description: "Có lỗi xảy ra",
      });
    } finally {
      getZalo();
    }
  };

  const editZaloDelay = async (id, value) => {
    if (!value) return;
    try {
      setLoading(true);
      let data = await $axios.patch(`zalo/change-delay-message/${id}`, {
        delay: +value,
      });

      notification.success({
        message: "Thành công",
        description: "Chỉnh sửa thành công",
      });
    } catch (error) {
      notification.error({
        message: "Thất bại",
        description: "Có lỗi xảy ra",
      });
    } finally {
      getZalo();
    }
  };

  const editInviteLimit = async (id, value) => {
    if (!value) return;
    try {
      setLoading(true);
      let data = await $axios.patch(`zalo/change-limit-invite/${id}`, {
        limit: +value,
      });

      notification.success({
        message: "Thành công",
        description: "Chỉnh sửa thành công",
      });
    } catch (error) {
      notification.error({
        message: "Thất bại",
        description: "Có lỗi xảy ra",
      });
    } finally {
      getZalo();
    }
  };
  const editLimitInviteDay = async (id, value) => {
    if (!value) return;
    try {
      setLoading(true);
      let data = await $axios.patch(`zalo/change-limit-invite-per-day/${id}`, {
        limit: +value,
      });

      notification.success({
        message: "Thành công",
        description: "Chỉnh sửa thành công",
      });
    } catch (error) {
      notification.error({
        message: "Thất bại",
        description: "Có lỗi xảy ra",
      });
    } finally {
      getZalo();
    }
  };

  const editInviteDelay = async (id, value) => {
    if (!value) return;
    try {
      setLoading(true);
      let data = await $axios.patch(`zalo/change-delay-invite/${id}`, {
        delay: +value,
      });

      notification.success({
        message: "Thành công",
        description: "Chỉnh sửa thành công",
      });
    } catch (error) {
      notification.error({
        message: "Thất bại",
        description: "Có lỗi xảy ra",
      });
    } finally {
      getZalo();
    }
  };

  useEffect(() => {
    editZaloLimit(chosenId, debouceLimitMessage);
  }, [debouceLimitMessage, chosenId]);

  useEffect(() => {
    editZaloDelay(chosenId, debouceDelayMessage);
  }, [debouceDelayMessage, chosenId]);

  useEffect(() => {
    editLimitMessageDay(chosenId, debouceLimitMessageDay);
  }, [debouceLimitMessageDay, chosenId]);

  useEffect(() => {
    editInviteLimit(chosenId, debouceLimitInvite);
  }, [debouceLimitInvite, chosenId]);

  useEffect(() => {
    editLimitInviteDay(chosenId, debouceLimitInviteDay);
  }, [debouceLimitInviteDay, chosenId]);

  useEffect(() => {
    editInviteDelay(chosenId, debouceDelayInvite);
  }, [debouceDelayInvite, chosenId]);

  return (
    <Table
      dataSource={zalo}
      pagination={false}
      scroll={{
        // x: "max-content",
        y: "50vh",
      }}
      loading={loading}
      bordered
      columns={[
        { title: "Tên Zalo", dataIndex: "name", align: "center" },
        {
          title: "Limit tin nhắn",
          dataIndex: "limitMessageSend",
          align: "center",
          width: 200,
          render: (value, record) => (
            <Input
              placeholder="Nhập giới hạn tin nhắn"
              type="number"
              defaultValue={record.limitMessageSend}
              onChange={(e) => {
                setChosenId(record._id);
                setLimitMessage(e.target.value);
              }}
            />
          ),
        },
        {
          title: "Delay tin nhắn",
          dataIndex: "delayMessageSend",
          align: "center",
          width: 200,
          render: (value, record) => (
            <Input
              placeholder="Nhập delay tin nhắn"
              type="number"
              defaultValue={record.delayMessageSend}
              onChange={(e) => {
                setChosenId(record._id);
                setDelayMessage(e.target.value);
              }}
            />
          ),
        },
        {
          title: "Limit tin nhắn mỗi ngày",
          dataIndex: "limitMessageSendPerDay",
          align: "center",
          width: 200,
          render: (value, record) => (
            <Input
              placeholder="Nhập giới hạn tin nhắn ngày"
              type="number"
              defaultValue={record.limitMessageSendPerDay}
              onChange={(e) => {
                setChosenId(record._id);
                setLimitMessageDay(e.target.value);
              }}
            />
          ),
        },
        {
          title: "Limit lời mời",
          dataIndex: "limitInviteSend",
          align: "center",
          width: 200,
          render: (value, record) => (
            <Input
              placeholder="Nhập delay lời mời"
              type="number"
              defaultValue={record.limitInviteSend}
              onChange={(e) => {
                setChosenId(record._id);
                setLimitInvite(e.target.value);
              }}
            />
          ),
        },
        {
          title: "Delay lời mời",
          dataIndex: "delayInviteSend",
          align: "center",
          width: 200,
          render: (value, record) => (
            <Input
              placeholder="Nhập delay lời mời"
              type="number"
              defaultValue={record.delayInviteSend}
              onChange={(e) => {
                setChosenId(record._id);
                setDelayInvite(e.target.value);
              }}
            />
          ),
        },
        {
          title: "Limit lời mời mỗi ngày",
          dataIndex: "limitInviteSendPerDay",
          align: "center",
          width: 200,
          render: (value, record) => (
            <Input
              placeholder="Nhập giới hạn lời mời ngày"
              type="number"
              defaultValue={record.limitInviteSendPerDay}
              onChange={(e) => {
                setChosenId(record._id);
                setLimitInviteDay(e.target.value);
              }}
            />
          ),
        },

        // {
        //   title: "Số tin nhắn ngày",
        //   align: "center",
        //   width: 200,
        //   render: () => (
        //     <Tag style={{ margin: 0 }} color="orange">
        //       0
        //     </Tag>
        //   ),
        // },
      ]}
    />
  );
};

export default SettingModal;
