import React from "react";
import { Input, Button, Modal, Form, InputNumber, DatePicker, Switch } from "antd";
import dayjs from "dayjs";

const ModalCreateUser = (props) => {
  const { openModalUser, onCancel, handleCreate, form} = props;
  return (
    <Modal
      title="Tạo tài khoản"
      style={{ top: 20 }}
      onCancel={() => onCancel()}
      open={openModalUser}
      footer={[
        <Button key="back" onClick={() => onCancel()}>
          Hủy
        </Button>,
        <Button key="submit" type="primary" onClick={handleCreate}>
          Tạo
        </Button>,
      ]}
    >
      <Form
        layout="vertical"
        form={form}
        className="formCreateUser"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        onFinish={handleCreate}
        initialValues={{
          limitPost: 10,
          limitLiveComment: 1,
          limitLiveReaction: 1,
          limitTime: dayjs().add(1, "day"), // Set default to today + 1 day
          limitAutoZaloComment: 0,
          limitAutoZaloReaction: 0,
          autoZalo: false,
        }}
      >
        <Form.Item
          label="Username"
          name="username"
          rules={[{ required: true, message: "Username không được để trống" }]}
        >
          <Input placeholder="Nhập tên đăng nhập ..." />
        </Form.Item>
        <Form.Item label="Email" name="email" rules={[{ required: true, message: "Email không được để trống" }]}>
          <Input placeholder="Nhập email ..." />
        </Form.Item>
        <Form.Item
          label="Họ và tên"
          name="fullName"
          rules={[{ required: true, message: "Họ và tên không được để trống" }]}
        >
          <Input placeholder="Nhập họ và tên ..." />
        </Form.Item>
        <Form.Item
          label="Mật khẩu"
          name="password"
          rules={[
            { required: true, message: "Mật khẩu không được để trống" },
            {
              min: 8,
              message: "Mật khẩu tối thiểu 8 kí tự",
            },
          ]}
        >
          <Input.Password placeholder="Nhập mật khẩu ..." />
        </Form.Item>
        <Form.Item
          label="Limit Post"
          name="limitPost"
          rules={[{ required: true, message: "Limit Post không được để trống" }]}
        >
          <InputNumber placeholder="Nhập Limit Post ..." min={0} style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          label="Limit Live Comment"
          name="limitLiveComment"
          rules={[
            {
              required: true,
              message: "Limit Live Comment không được để trống",
            },
          ]}
        >
          <InputNumber placeholder="Nhập Limit Live Comment ..." min={0} style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          label="Limit LiveReaction"
          name="limitLiveReaction"
          rules={[
            {
              required: true,
              message: "Limit Live Reaction không được để trống",
            },
          ]}
        >
          <InputNumber placeholder="Nhập Limit Live Reaction ..." min={0} style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item label="Limit Time" name="limitTime">
          <DatePicker className="w-100" />
        </Form.Item>
        <Form.Item label="Auto Zalo" name="autoZalo" valuePropName="autoZalo">
          <Switch />
        </Form.Item>
        <Form.Item
          label="Limit Auto Zalo Comment"
          name="limitAutoZaloComment"
          rules={[
            {
              required: true,
              message: "Limit Auto Zalo Comment không được để trống",
            },
          ]}
        >
          <InputNumber placeholder="Nhập Limit Auto Zalo Comment" min={0} style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          label="Limit Auto Zalo Reaction"
          name="limitAutoZaloReaction"
          rules={[
            {
              required: true,
              message: "Limit Auto Zalo Reaction không được để trống",
            },
          ]}
        >
          <InputNumber placeholder="Nhập Limit Auto Zalo Reaction" min={0} style={{ width: "100%" }} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalCreateUser;
