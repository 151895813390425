import React, { useState, useEffect } from "react";
import { Input, Button, Tag, Avatar, Table, Pagination, Space } from "antd";
import { Link } from "react-router-dom";
import $axios from "../../services/axios";

const { Column } = Table;

const GroupMember = () => {
  const [group, setGroup] = useState({
    list: [],
    total: 0,
    limit: 10,
    pager: 1,
  });

  const [groupID, setGroupID] = useState(null);
  const [cursor, setCursor] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [scan, setScan] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);

  const handleGroupIDChange = (e) => {
    setGroupID(e.target.value);
  };

  const handleEnterPressed = async () => {
    try {
      if (!groupID) throw "Bạn chưa nhập group ID";
      setScan(true);
      await getMembersInDatabase();
      await getLastCursor();
      await getMembers();
    } catch (error) {
      // Xử lý khi xảy ra lỗi
      console.error(error);
      setScan(false);
    }
  };

  const getMembersInDatabase = async () => {
    try {
      const { data } = await $axios.$get("/api/members", {
        params: {
          where: { groupID },
          sort: "createdAt DESC",
          limit: group.limit,
          skip: (group.pager - 1) * group.limit,
        },
      });
      setGroup({ ...group, list: data });
    } catch (error) {
      console.error(error);
    }
  };

  const getCountMembers = async () => {
    try {
      const { data } = await $axios.$post("/api/group/members-count", {
        groupID,
      });
      setGroup({ ...group, total: data.count });
    } catch (error) {
      console.error(error);
    }
  };

  const getLastCursor = async () => {
    try {
      const { data } = await $axios.$get("/api/members", {
        params: {
          sort: "createdAt DESC",
          limit: 1,
        },
      });
      const cursor = data.length > 0 ? data[0].cursor : null;
      setCursor(cursor);
    } catch (error) {
      console.error(error);
    }
  };

  const getMembers = async () => {
    try {
      const { data } = await $axios.$post("/api/group/GetMembers", {
        groupID,
        access_token: accessToken,
        cursor,
      });
      await Promise.all([getCountMembers(), getMembersInDatabase()]);
      if (!data.has_next_page || !scan) return;
      setCursor(data.end_cursor);
      await getMembers();
    } catch (error) {
      console.error(error);
    }
  };

  const exportExcel = async () => {
    setExportLoading(true);
    try {
      const response = await $axios.$post(
        "/api/group/export-members",
        { where: { groupID } },
        { responseType: "blob" }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "members.csv");
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error(error);
    } finally {
      setExportLoading(false);
    }
  };

  useEffect(() => {
    const getTokenClone = async () => {
      try {
        const {
          data: { access_token },
        } = await $axios.$post("/api/token/get-token-clone");
        setAccessToken(access_token);
      } catch (error) {
        console.error(error);
      }
    };

    getTokenClone();
  }, []);

  return (
    <div>
      <div
        style={{ marginBottom: 10 }}
        className="d-flex justify-content-between"
      >
        <Input
          style={{ width: "76%" }}
          spellCheck="false"
          placeholder="Nhập ID nhóm ..."
          type="text"
          value={groupID}
          onChange={handleGroupIDChange}
          onPressEnter={handleEnterPressed}
        />
        <Button
          style={{ width: "20%" }}
          type={!scan ? "primary" : "warning"}
          loading={exportLoading}
          className="ml-4"
          onClick={handleEnterPressed}
        >
          {!scan ? "Lấy thành viên" : "Dừng lại"}
        </Button>
      </div>
      <div style={{ marginBottom: "10px" }}>
        <Tag color="orange" effect="plain" className="py-1 text-14">
          {group.total} thành viên
        </Tag>
        <Button
          type="primary"
          onClick={exportExcel}
          loading={exportLoading}
        >
          Xuất ra Excel
        </Button>
      </div>
      <Table dataSource={group.list} bordered rowKey="uid" pagination={false}>
        <Column
          align="center"
          fixed="left"
          render={(text, record) => (
            <Avatar
              shape="square"
              size="small"
              src={record.avatar}
              icon="user"
            />
          )}
        />
        <Column
          title="UID"
          dataIndex="uid"
          align="center"
          fixed="left"
          render={(text) => (
            <Tag type="primary" effect="plain" size="large">
              {text}
            </Tag>
          )}
        />
        <Column
          title="Tên"
          dataIndex="name"
          align="center"
          render={(text, record) => (
            <Link
              to={`https://www.facebook.com/${record.uid}`}
              target="_blank"
              underline={false}
            >
              {text}
            </Link>
          )}
        />
        <Column
          title="Số điện thoại"
          dataIndex="phone"
          align="center"
          render={(text) => (
            <Tag type="success" effect="plain" size="large">
              {text}
            </Tag>
          )}
        />
      </Table>
      <Pagination
        style={{ marginTop: "10px" }}
        total={group.total}
        pageSize={group.limit}
        current={group.pager}
        onChange={(page) => setGroup({ ...group, pager: page })}
        showSizeChanger={false}
        hideOnSinglePage={false}
        pageSizeOptions={["10"]}
      />
    </div>
  );
};

export default GroupMember;
