import React, { useState } from "react";
import AccountZalo  from '../pages/account/zalo'
import { Tabs } from "antd";
import Facebook from "./account/facebook";
const { TabPane } = Tabs;

const Account = () => {
  const [activeTab, setActiveTab] = useState("zalo");
  const [activeTabFacebook, setActiveTabFacebook] = useState("account");

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  const handleTabFacebookChange = (key) => {
    setActiveTabFacebook(key);
  };

  return (
    <div className="card">
      <Tabs type="card" activeKey={activeTab} onChange={handleTabChange}>
        {/* <TabPane className="px-3 pb-3" tab="Facebook" key="facebook">
          <Facebook />
        </TabPane> */}
        <TabPane className="px-3 pb-3" tab="Zalo" key="zalo">
          {/* <CookieZalo /> */}
          <AccountZalo />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Account;
