import React from "react";
import { Image, Modal } from "antd";

const ModalOpenQR = (props) => {
  const {
    openQRModal,
    setOpenQRModal,
    imgQR,
    setImgQr,
  } = props;
  return (
    <Modal
      title=""
      maskClosable={false}
      open={openQRModal}
      onCancel={() => {
        setOpenQRModal(false);
        setImgQr("");
      }}
      centered={true}
      footer={null}
      fullscreen={false}
      width={400}
    >
      {imgQR && (
        <>
          <div>Quét QR sau để thêm tài khoản</div>
          <Image preview={false} src={imgQR} />
        </>
      )}
    </Modal>
  );
};

export default ModalOpenQR;
