import React, { useState } from 'react';
import { Form, Input, Row, Col, Button, Tag, notification } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import './profile.scss';
import moment from 'moment';
import $axios from '../../services/axios';

const Profile = () => {
    const userInfo = JSON.parse(localStorage.getItem("user_info"));
    const [email, setEmail] = useState(userInfo.email);
    const [phone, setPhone] = useState(userInfo.phone);

    const renderDay = (time) => {
        const diffDay = moment(time).diff(moment(), 'days');
        return diffDay < 0 ? 'Hết hạn' : diffDay;
    }

    const UpdateProfile = async () => {
        try {
            const update = await $axios.patch(`admin/change-user-email?userId=${userInfo._id}&email=${email}`);
            notification.success({
                type: 'Thành công',
                description: 'Cập nhật thành công'
            });
            const user = await $axios.get('user/me');
            localStorage.setItem("user_info", JSON.stringify(user.data.data));
        } catch (error) {
            notification.error({
                type: 'Thất bại',
                description: 'Cập nhật thất bại'
            })
        }
        // Add your logic for updating the profile
    };

    return (
      <Form
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        layout="horizontal"
        size="middle"
        initialValues={{ ["email"]: email }}
      >
        <h1
          className="text-20 d-flex align-items-center gap-2"
          style={{ color: "#638552", marginTop: 0, marginBottom: 22, fontWeight: "inherit" }}
        >
          <FontAwesomeIcon icon={faUser} style={{ color: "#e13333" }} className="text-16" /> Thông tin tài khoản
          <Tag
            className="text-primary border-primary bg-white px-2 py-1 text-uppercase"
            closable={false}
            effect="plain"
            size="large"
          >
            {userInfo.role}
          </Tag>
        </h1>
        <Row gutter={20}>
          <Col span={12} offset={0}>
            <Form.Item className="text-label" label="Email" name="email" labelAlign="left">
              <Input
                placeholder=""
                disabled={true}
                // value={email}
                name="email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={12} offset={0}>
            <Form.Item className="text-label" label="Phone" name="phone" labelAlign="left">
              <Input value={phone} disabled />
            </Form.Item>
          </Col>
        </Row>
        {/* <Row gutter={20}>
                <Col span={12} offset={0}>
                    <Form.Item className='text-label' label="Limit Phone" labelAlign="left">
                        <Tag className='text-primary border-primary bg-white px-2 py-1' closable={false} effect="plain" size="large">
                            {limitPhone}
                        </Tag>
                    </Form.Item>
                </Col>
            </Row> */}
        <Row gutter={20}>
          <Col span={12} offset={0}>
            <Form.Item className="text-label" label="Limit Post" labelAlign="left">
              <Tag
                className="text-primary border-primary bg-white px-2 py-1"
                closable={false}
                effect="plain"
                size="large"
              >
                {userInfo.limitPost}
              </Tag>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={12} offset={0}>
            <Form.Item className="text-label" label="Limit LiveReaction" labelAlign="left">
              <Tag
                className="text-primary border-primary bg-white px-2 py-1"
                closable={false}
                effect="plain"
                size="large"
              >
                {userInfo.limitLiveReaction}
              </Tag>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={12} offset={0}>
            <Form.Item className="text-label" label="Limit Time" labelAlign="left">
              <Tag
                className="text-primary border-primary bg-white px-2 py-1"
                closable={false}
                effect="plain"
                size="large"
              >
                {renderDay(userInfo.limitTime)}
              </Tag>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={12} offset={0}>
            <Form.Item className="text-label" label="Limit Live Comment" labelAlign="left">
              <Tag
                className="text-primary border-primary bg-white px-2 py-1"
                closable={false}
                effect="plain"
                size="large"
              >
                {userInfo.limitLiveComment}
              </Tag>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={12} offset={0}>
            <Form.Item className="text-label" label="Auto Zalo" labelAlign="left">
              <Tag
                className="text-primary border-primary bg-white px-2 py-1"
                closable={false}
                effect="plain"
                size="large"
              >
                {userInfo.autoZalo ? "Có" : "Không"}
              </Tag>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={12} offset={0}>
            <Form.Item className="text-label" label="Limit Auto Zalo Comment" labelAlign="left">
              <Tag
                className="text-primary border-primary bg-white px-2 py-1"
                closable={false}
                effect="plain"
                size="large"
              >
                {userInfo.limitAutoZaloComment}
              </Tag>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={12} offset={0}>
            <Form.Item className="text-label" label="Limit Auto Zalo Reaction" labelAlign="left">
              <Tag
                className="text-primary border-primary bg-white px-2 py-1"
                closable={false}
                effect="plain"
                size="large"
              >
                {userInfo.limitAutoZaloReaction}
              </Tag>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
};

export default Profile;
