import React, { useState, useEffect } from "react";
import {
  Tabs,
  Row,
  Col,
  Input,
  Button,
  Tag,
  Table,
  notification,
  Popconfirm,
  Modal,
} from "antd";
import { PlusOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import $axios from "../../services/axios";

const { TabPane } = Tabs;

const ScriptModal = () => {
  const [activeTab, setActiveTab] = useState("1");
  const [nameMess, setNameMess] = useState("");
  const [contentMess, setContentMess] = useState("");
  const [invite, setInvite] = useState([]);
  const [message, setMessage] = useState([]);
  const [nameInvite, setNameInvite] = useState("");
  const [contentInvite, setContentInvite] = useState("");
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [expandedRows, setExpandedRows] = useState([]);
  const [openEdit, setOpenEdit] = useState(false);
  const [detail, setDetail] = useState({});
  const [name, setName] = useState("");
  const [content, setContent] = useState("");

  const toggleRow = (index) => {
    const newExpandedRows = [...expandedRows];
    newExpandedRows[index] = !newExpandedRows[index];
    setExpandedRows(newExpandedRows);
  };

  useEffect(() => {
    setName(detail?.name);
    setContent(detail?.content);
  }, [detail?._id]);

  const handleEdit = async () => {
    if (!name || !content)
      return notification.error({
        message: "Thất bại",
        description: "Vui lòng nhập đủ các trường",
      });
    try {
      if (activeTab == '1') {
        let data = await $axios.patch(
          `/template-message/change-name/${detail?._id}`,
          {
            name: name,
          }
        );
        let data2 = await $axios.patch(
          `template-message/change-content/${detail?._id}`,
          {
            content: content,
          }
        );
      } else {
        let data = await $axios.patch(
          `/template-invite/change-name/${detail?._id}`,
          {
            name: name,
          }
        );
        let data2 = await $axios.patch(
          `template-invite/change-content/${detail?._id}`,
          {
            content: content,
          }
        );
      }
      notification.success({
        message: "Thành công",
        description: "Chỉnh sửa thành công",
      });
    } catch (error) {
      notification.error({
        message: "Thất bại",
        description: "Có lỗi xảy ra",
      });
    } finally {
      getMessage();
      getInvite();
      setOpenEdit(false);
    }
  };

  useEffect(() => {
    getInvite();
    getMessage();
  }, [activeTab]);

  const getInvite = async () => {
    setLoading2(true);
    const data = await $axios.get("/template-invite/list");
    setInvite(data.data.data);
    setLoading2(false);
  };
  const getMessage = async () => {
    setLoading(true);
    const data = await $axios.get("/template-message/list");
    setMessage(data.data.data);
    setLoading(false);
  };

  const RemoveInvite = async (id) => {
    try {
      let data = await $axios.delete(`/template-invite/delete/${id}`);
      await getInvite();
      notification.success({
        message: "Thành công",
        description: "Xoá thành công",
      });
    } catch (error) {
      notification.error({
        message: "Thất bại",
        description: "Có lỗi xảy ra",
      });
    }
  };
  const RemoveMessage = async (id) => {
    try {
      let data = await $axios.delete(`/template-message/delete/${id}`);
      await getMessage();
      notification.success({
        message: "Thành công",
        description: "Xoá thành công",
      });
    } catch (error) {
      notification.error({
        message: "Thất bại",
        description: "Có lỗi xảy ra",
      });
    }
  };

  const addMessage = async () => {
    try {
      setLoading(true);
      let data = await $axios.post("/template-message/create", {
        name: nameMess,
        content: contentMess,
      });
      setNameMess("");
      setContentMess("");
      notification.success({
        message: "Thành công",
        description: "Thêm mới thành công",
      });
    } catch (error) {
      notification.error({
        message: "Thất bại",
        description: "Có lỗi xảy ra, vui lòng nhập đầy đủ các trường thông tin",
      });
    } finally {
      getMessage();
    }
  };

  const addInvite = async () => {
    try {
      setLoading2(true);
      let data = await $axios.post("/template-invite/create", {
        name: nameInvite,
        content: contentInvite,
      });
      setNameInvite("");
      setContentInvite("");
      notification.success({
        message: "Thành công",
        description: "Thêm mới thành công",
      });
    } catch (error) {
      notification.error({
        message: "Thất bại",
        description: "Có lỗi xảy ra, vui lòng nhập đầy đủ các trường thông tin",
      });
    } finally {
      getInvite();
    }
  };

  return (
    <div className="card">
      <Tabs
        activeKey={activeTab}
        onChange={(key) => setActiveTab(key)}
        type="card"
      >
        <TabPane className="px-3 pb-3" tab="Tin nhắn mẫu" key="1">
          <Row gutter={20} className="my-2">
            <Col span={24} offset={0} align="left">
              <Input
                placeholder="Nhập tiêu đề"
                showCount
                maxLength={50}
                value={nameMess}
                onChange={(e) => setNameMess(e.target.value)}
              />
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={24} offset={0} align="left">
              <Input.TextArea
                rows={6}
                showCount
                maxLength={500}
                placeholder="Nhập nội dung mẫu tin nhắn..."
                value={contentMess}
                onChange={(e) => setContentMess(e.target.value)}
              />
            </Col>
          </Row>
          <br />
          <Row gutter={20}>
            <Col span={24} offset={0} align="left">
              <Button
                type="primary"
                className="button button--primary"
                icon={<PlusOutlined />}
                loading={loading}
                style={{ fontSize: "12px" }}
                onClick={addMessage}
              >
                Thêm mẫu
              </Button>
            </Col>
          </Row>
          <br />

          <br />
          <Table
            dataSource={message}
            pagination={false}
            bordered
            scroll={{
              x: "max-content",
              y: `calc(100vh - 600px)`,
            }}
            columns={[
              { title: "Tiêu đề", dataIndex: "name", align: "center", width: '15%' },
              {
                title: "Nội dung",
                dataIndex: "content",
                align: "center",
                render: (value, record, index) => (
                  <div style={{ maxWidth: "400px", margin: 'auto' }}>
                    {record.content.length > 40 ? (
                      <>
                        {expandedRows[index] ? (
                          <>
                            <span>{record.content + "  "}</span>
                            <a
                              className="text-blue text-decoration-none"
                              onClick={() => toggleRow(index)}
                            >
                              Ẩn bớt
                            </a>
                          </>
                        ) : (
                          <>
                            {record.content.slice(0, 40) + "..."}
                            <a
                              className="text-blue text-decoration-none"
                              onClick={() => toggleRow(index)}
                            >
                              Xem thêm
                            </a>
                          </>
                        )}
                      </>
                    ) : (
                      record.content
                    )}
                  </div>
                ),
              },
              {
                title: "",
                align: "center",
                width: '5%',
                render: (value, record) => (
                  <div className="d-flex items-center gap-2">
                    <Tag
                      style={{ margin: 0, cursor: "pointer" }}
                      color="blue"
                      icon={<EditOutlined />}
                      onClick={() => {
                        setDetail(record);
                        setOpenEdit(true);
                      }}
                    ></Tag>
                    <Popconfirm
                      title="Bạn có chắc chắn muốn xoá tin nhắn mẫu này?"
                      onConfirm={() => RemoveMessage(record._id)}
                    >
                      <Tag
                        style={{ margin: 0, cursor: "pointer" }}
                        color="red"
                        icon={<DeleteOutlined />}
                      ></Tag>
                    </Popconfirm>
                  </div>
                ),
              },
            ]}
          />
        </TabPane>
        <TabPane className="px-3 pb-3" tab="Lời mời" key="2">
          <Row gutter={20} className="my-2">
            <Col span={24} offset={0} align="left">
              <Input
                placeholder="Nhập tiêu đề"
                value={nameInvite}
                showCount
                maxLength={50}
                onChange={(e) => setNameInvite(e.target.value)}
              />
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={24} offset={0} align="left">
              <Input.TextArea
                rows={6}
                showCount
                maxLength={100}
                placeholder="Nhập nội dung lời mời..."
                value={contentInvite}
                onChange={(e) => setContentInvite(e.target.value)}
              />
            </Col>
          </Row>
          <br />
          <Row gutter={20}>
            <Col span={24} offset={0} align="left">
              <Button
                type="primary"
                className="button button--primary"
                icon={<PlusOutlined />}
                style={{ fontSize: "12px" }}
                loading={loading2}
                onClick={addInvite}
              >
                Thêm lời mời
              </Button>
            </Col>
          </Row>
          <br />

          <br />
          <Table
            columns={[
              { title: "Tiêu đề", dataIndex: "name", align: "center", width: '15%' },
              {
                title: "Nội dung",
                dataIndex: "content",
                align: "center",
                render: (value, record, index) => (
                  <div style={{ maxWidth: "400px", margin: 'auto' }}>
                    {record.content.length > 40 ? (
                      <>
                        {expandedRows[index] ? (
                          <>
                            <span>{record.content + "  "}</span>
                            <a
                              className="text-blue text-decoration-none"
                              onClick={() => toggleRow(index)}
                            >
                              Ẩn bớt
                            </a>
                          </>
                        ) : (
                          <>
                            {record.content.slice(0, 40) + "..."}
                            <a
                              className="text-blue text-decoration-none"
                              onClick={() => toggleRow(index)}
                            >
                              Xem thêm
                            </a>
                          </>
                        )}
                      </>
                    ) : (
                      record.content
                    )}
                  </div>
                ),
              },
              {
                title: "",
                align: "center",
                width: '5%',
                render: (value, record) => (
                  <div className="d-flex items-center gap-2">
                    <Tag
                      style={{ margin: 0, cursor: "pointer" }}
                      color="blue"
                      icon={<EditOutlined />}
                      onClick={() => {
                        setDetail(record);
                        setOpenEdit(true);
                      }}
                    ></Tag>
                    <Popconfirm
                      title="Bạn có chắc chắn muốn xoá lời mời này?"
                      onConfirm={() => RemoveInvite(record._id)}
                    >
                      <Tag
                        style={{ margin: 0, cursor: "pointer" }}
                        color="red"
                        icon={<DeleteOutlined />}
                      ></Tag>
                    </Popconfirm>
                  </div>
                ),
              },
            ]}
            pagination={false}
            loading={loading2}
            bordered
            dataSource={invite}
            scroll={{
              x: "max-content",
              y: `calc(100vh - 600px)`,
            }}
          />
        </TabPane>
      </Tabs>
      <Modal
        title="Chỉnh sửa"
        centered
        footer={null}
        open={openEdit}
        onCancel={() => {
          setOpenEdit(false);
        }}
        forceRender={false}
      >
        <div className="d-flex flex-column justify-content-center align-items-center gap-4  ">
          <Input value={name} onChange={(e) => setName(e.target.value)} showCount
            maxLength={50} />
          <Input.TextArea
            value={content}
            showCount
            maxLength={activeTab === "1" ? 500 : 100}
            onChange={(e) => setContent(e.target.value)}
            rows={6}
          />
          <Button
            type="primary"
            className="text-white p-2"
            onClick={() => handleEdit()}
          >
            Chỉnh sửa
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default ScriptModal;
