import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Home from "./../pages/Home";
import Register from "./../pages/Register";
import Dashboard from "./../pages/Dashboard";
import Account from "./../pages/Account";
import UID from "./../pages/UID";
import GroupMember from "./../pages/group/GroupMember";
import GroupSearch from "./../pages/group/GroupSearch";
import Larksuite from "../pages/larksuite";
import LayoutClient from "../components/Layout/Layout";
import Profile from "../pages/thongtincanhan/Profile";
import List from "../pages/bai-viet/List";
import Token from "../pages/admin/TokenClone";
import Binhluan from "../pages/bai-viet/Binhluan";
import ZaloMessage from "../pages/zalo/message";
import ZaloFriend from "../pages/zalo/friend";
import ZaloGroup from "../pages/zalo/group";
import User from "../pages/admin/User";
import AutoZalo from "../pages/bai-viet/AutoZalo";

const RouteWithLayout = () => {
  return (
    <LayoutClient>
      <Routes>
        <Route exact path="/" element={<Navigate to="/dashboard/thong-ke" />} />
        <Route path="/home" element={<Home />} />
        <Route path="/register" element={<Register />} />
        <Route path="/dashboard/thong-ke" element={<Dashboard />} />
        <Route path="/dashboard/nhom/tim-nhom" element={<GroupSearch />} />
        <Route path="/dashboard/nhom/thanh-vien" element={<GroupMember />} />
        <Route path="/dashboard/uid-phone" element={<UID />} />
        <Route path="/dashboard/larksuite" element={<Larksuite />} />
        <Route path="/dashboard/thong-tin-ca-nhan" element={<Profile />} />
        <Route path="/dashboard/bai-viet" element={<List />} />
        <Route path="/dashboard/auto-zalo" element={<AutoZalo />} />
        <Route path="/dashboard/tai-khoan" element={<Account />} />
        <Route path="/dashboard/admin/tai-khoan" element={<Token />} />
        <Route path="/dashboard/bai-viet/binh-luan" element={<Binhluan />} />
        {/* <Route path="/dashboard/zalo/nhan-tin" element={<ZaloMessage />} />
        <Route path="/dashboard/zalo/ket-ban" element={<ZaloFriend />} />
        <Route path="/dashboard/zalo/nhom" element={<ZaloGroup />} /> */}
        <Route path="/dashboard/admin/quan-ly-tai-khoan" element={<User />} />
        {/* Add more client-specific routes */}
      </Routes>
    </LayoutClient>
  );
};

export default RouteWithLayout;
