import React, { useEffect } from 'react'
import $axios  from '../services/axios';
import '../styles/home.css'

const Home = () => {

   useEffect(() => { 
      const testApi = async () => {
         try {
         const data = await $axios.$get("/category?offset=0&limit=20");
         } catch (error) {
         }
       };
       testApi();
   }, [])
   return <>
      <h1>home</h1>
   </>
}

export default Home