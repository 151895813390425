import React, { useState, useEffect } from "react";
import { Row, Col, Input, Button, Divider, Select, Table, Popconfirm, notification, Modal } from "antd";
import $axios from "../services/axios";
import { DeleteOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import _ from "lodash";

const { Column } = Table;
const { Option } = Select;
const { confirm } = Modal;

const Larksuite = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get("code");
  const state = queryParams.get("state");
  const urlMain = process.env.REACT_APP_LARKSUITE_URL || "http://localhost:8080";
  const urlAuthentication = urlMain + "/dashboard/larksuite";
  const [loadingTable, setLoadingTable] = useState(false);
  const [loadingSaveUrl, setLoadingSaveUrl] = useState(false);
  const [urlBase, setUrlBase] = useState("");
  const [larkAccount, setLarkAccount] = useState(null);
  const [tableLarksuiteIds, setTableLarksuiteIds] = useState([]);
  const [tableLarksuite, setTableLarksuite] = useState([
    {
      label: "Người like",
      name: "nguoi-like",
      table_id: null,
      list_field_name: ["Giờ", "Ngày", "Tên Bài Viết", "UID", "Tên Facebook", "Số Điện Thoại"],
    },
    {
      label: "Bình luận ( Quét comment )",
      name: "binh-luan",
      table_id: null,
      list_field_name: ["Giờ", "Ngày", "Tên Bài Viết", "UID", "Tên Facebook", "Bình Luận", "Số Điện Thoại"],
    },
    {
      label: "Auto Zalo Comment",
      name: "auto-zalo-comment",
      table_id: null,
    },
    {
      label: "Auto Zalo Reaction",
      name: "auto-zalo-reaction",
      table_id: null,
    },
  ]);
  const [listTable, setListTable] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      if (code && state) {
        try {
          setLoadingTable(true);
          await importLarkSuiteAccount(code, state);
          window.location.href = "/dashboard/larksuite";
        } catch (error) {
        } finally {
          setLoadingTable(false);
        }
      } else {
        const account = await getLarkSuiteAccount();
        if (account && account._id) {
          setLarkAccount(account);
          if (account._id && account.path) {
            setUrlBase(account.path);
            await getListTable(account);
            await getListTableLarksuite(account);
          }
        }
      }
    };
    fetchData();
  }, []);

  const updateTableLarksuite = async (column, table_id) => {
    confirm({
      title: "Lưu ý",
      content: 'Dữ liệu cũ của bảng "' + column.label + '" (nếu có) trong Larksuite sẽ bị xóa.',
      async onOk() {
        if (column.name === "nguoi-like") {
          try {
            const res = await $axios.$patch(`/larksuite/set-table-reaction?table=${table_id}`, {});
            if (res.statusCode === 200) {
              const account = await getLarkSuiteAccount();
              setLarkAccount(account);
              await getListTableLarksuite(account);
              notification.success({
                message: "Thành công",
                description: "Cập nhật thành công",
              });
            }
          } catch (error) {
            notification.error({
              message: "Thất bại",
              description: "Có lỗi xảy ra",
            });
            console.error(error);
          }
        } else if (column.name === "binh-luan") {
          try {
            const res = await $axios.$patch(`/larksuite/set-table-comment?table=${table_id}`, {});
            if (res.statusCode === 200) {
              const account = await getLarkSuiteAccount();
              setLarkAccount(account);
              await getListTableLarksuite(account);
              notification.success({
                message: "Thành công",
                description: "Cập nhật thành công",
              });
            }
          } catch (error) {
            notification.error({
              message: "Thất bại",
              description: "Có lỗi xảy ra",
            });
            console.error(error);
          }
        } else if (column.name === "auto-zalo-comment") {
          try {
            const res = await $axios.$patch(`/larksuite/set-table-auto-zalo-comment?table=${table_id}`, {});
            if (res.statusCode === 200) {
              const account = await getLarkSuiteAccount();
              setLarkAccount(account);
              await getListTableLarksuite(account);
              notification.success({
                message: "Thành công",
                description: "Cập nhật thành công",
              });
            }
          } catch (error) {
            notification.error({
              message: "Thất bại",
              description: "Có lỗi xảy ra",
            });
            console.error(error);
          }
        }
        else if (column.name === "auto-zalo-reaction") {
          try {
            const res = await $axios.$patch(`/larksuite/set-table-auto-zalo-reaction?table=${table_id}`, {});
            if (res.statusCode === 200) {
              const account = await getLarkSuiteAccount();
              setLarkAccount(account);
              await getListTableLarksuite(account);
              notification.success({
                message: "Thành công",
                description: "Cập nhật thành công",
              });
            }
          } catch (error) {
            notification.error({
              message: "Thất bại",
              description: "Có lỗi xảy ra",
            });
            console.error(error);
          }
        }
      },
      onCancel() {},
    });
  };

  const getListTableLarksuite = async (account) => {
    const { tableIdComment, tableIdReaction, tableIdAutoZaloComment, tableIdAutoZaloReaction } = account;

    try {
      const updatedTableLarksuite = tableLarksuite.map((e) => {
        if (e.name === "nguoi-like") {
          e.table_id = tableIdReaction ? tableIdReaction : "";
        }
        if (e.name === "binh-luan") {
          e.table_id = tableIdComment ? tableIdComment : "";
        }
        if (e.name === "auto-zalo-comment") {
           e.table_id = tableIdAutoZaloComment ? tableIdAutoZaloComment : "";
        }
         if (e.name === "auto-zalo-reaction") {
           e.table_id = tableIdAutoZaloReaction ? tableIdAutoZaloReaction : "";
         }
        return e;
      });
      setTableLarksuite(updatedTableLarksuite);
      setTableLarksuiteIds(_.map(updatedTableLarksuite, (t) => t.table_id));
    } catch (error) {
      // notification.error({
      //   message: "Thất bại",
      //   description: "Có lỗi xảy ra",
      // });
      console.error(error);
    }
  };

  const saveURL = async () => {
    setLoadingSaveUrl(true);
    try {
      const res = await $axios.patch(`/larksuite/update-path`, {
        path: urlBase,
      });
      if (res.status === 200) {
        notification.success({
          title: "Success",
          message: "Cập nhật url thành công",
        });
      }
    } catch (error) {
      notification.error({
        message: "Thất bại",
        description: "Có lỗi xảy ra",
      });
      console.error(error);
    } finally {
      setLoadingSaveUrl(false);
    }
  };

  const confirmURL = async () => {
    try {
      await saveURL();
      const account = await getLarkSuiteAccount();
      setLarkAccount(account);
      await getListTable(account);
      await getListTableLarksuite(account);
    } catch (error) {
      notification.error({
        message: "Thất bại",
        description: "Có lỗi xảy ra",
      });
    }
  };

  const removeLarkSuiteAccount = async () => {
    try {
      await $axios.delete(`/larksuite/delete`);
      setLarkAccount(null);
      window.location.href = "/dashboard/larksuite";
    } catch (error) {
      notification.error({
        message: "Thất bại",
        description: "Có lỗi xảy ra",
      });
      console.error(error);
    }
  };

  const getLarkSuiteAccount = async () => {
    try {
      const res = await $axios.get("/larksuite/get");
      return res.data.data;
    } catch (error) {
      notification.error({
        message: "Thất bại",
        description: "Có lỗi xảy ra",
      });
      console.error(error);
    }
  };

  const importLarkSuiteAccount = async (code, state) => {
    try {
      await $axios.$post(`/larksuite/create`, {
        code: code,
        state: state,
      });
      notification.success({
        message: "Thành công",
      });
    } catch (error) {
      notification.error({
        message: "Thất bại",
        description: "Có lỗi xảy ra",
      });
      console.error(error);
    }
  };

  const getListTable = async (account) => {
    if (account && account._id) {
      try {
        const res = await $axios.$get(`/larksuite/list-tables`, {});
        if (!_.isEmpty(res.data)) {
          res.data.push({
            name: "Hủy chọn bảng",
            table_id: "",
          });
          setListTable(res.data);
        }
      } catch (error) {
        notification.error({
          message: "Thất bại",
          description: "Có lỗi xảy ra",
        });
        console.error(error);
      }
    }
  };

  const handleLogin = async () => {
    try {
      const res = await $axios.get(`/larksuite/link-auth?redirect_uri=${encodeURIComponent(urlAuthentication)}`);
      if (res.status === 200) {
        window.location.href = res.data.data.uri;
      }
    } catch (error) {
      notification.error({
        message: "Thất bại",
        description: "Có lỗi xảy ra",
      });
      console.error(error);
    }
  };

  return (
    <Row gutter={10}>
      <Col align="center" span={24} offset={0}>
        {larkAccount && larkAccount.path && (
          <>
            {/* <UserOutlined /> {larkAccount.email}{" "} */}
            <Popconfirm
              title="Bạn có chắc chắn?"
              onConfirm={removeLarkSuiteAccount}
              okText="OK"
              cancelText="Không"
              icon={<InfoCircleOutlined />}
              iconColor="red"
            >
              <Button type="primary" className="text-white" icon={<DeleteOutlined />}>
                Đăng xuất
              </Button>
            </Popconfirm>
          </>
        )}
        {/* : (
          <div target="_self" rel="noreferrer" style={{ textDecoration: "none" }} onClick={handleLogin}>
            <Button type="primary" onClick={() => {}}>
              Đăng nhập Larksuite
            </Button>
          </div>
        )} */}
        <Divider direction="horizontal" />
        <Row gutter={10}>
          <Col align="left" span={22} offset={0}>
            <Input
              spellCheck={false}
              placeholder="Nhập link base của larksuite"
              value={urlBase}
              onChange={(e) => setUrlBase(e.target.value)}
              size="medium"
              clearable
              type="text"
              autoComplete="off"
            />
          </Col>
          <Col align="left" span={2} offset={0}>
            <Button
              type="primary"
              onClick={() => confirmURL()}
              style={{ width: "100%" }}
              loading={loadingSaveUrl}
              autoFocus={false}
              size="medium"
            >
              Lưu
            </Button>
          </Col>
        </Row>
        <br />
        <Table
          dataSource={tableLarksuite}
          loading={loadingTable}
          bordered={true}
          rowKey="label"
          pagination={false}
          scroll={{
            x: "max-content",
          }}
        >
          <Column title="Bảng dữ liệu" dataIndex="label" align="center" />
          <Column
            title=""
            align="center"
            render={(text, record) => {
              return (
                <Select
                  value={record.table_id}
                  style={{ width: "200px" }}
                  onChange={(value) => updateTableLarksuite(record, value)}
                  size="medium"
                  placeholder="Chọn bảng"
                >
                  {listTable.map((data, index) => (
                    <Option
                      key={index}
                      value={data.table_id}
                      disabled={_.indexOf(tableLarksuiteIds, data.table_id) > -1 && data.table_id !== ''}
                    >
                      {data.name}
                    </Option>
                  ))}
                </Select>
              );
            }}
          />
        </Table>
      </Col>
    </Row>
  );
};

export default Larksuite;
